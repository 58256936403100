exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1U89n{-ms-flex-pack:justify;justify-content:space-between}._1U89n,._3mz_r{display:-ms-flexbox;display:flex}._3mz_r{margin:50px auto}._3mz_r ._1w6VX,._3mz_r ._3opDY{font-size:125px;text-transform:uppercase;line-height:1;letter-spacing:-3px;margin-bottom:50px;opacity:.1;color:#4f4f6f;text-align:right!important}._3mz_r .AZYIw{display:-ms-flexbox;display:flex}._3mz_r .AZYIw ._2BTPj{width:100%;text-align:center}._3mz_r .AZYIw ._2BTPj img{max-height:45px}._3mz_r .AZYIw ._2BTPj ._2Mbc8{font-size:36px;display:block}._3mz_r .AZYIw ._2BTPj p{font-size:18px;color:#7d7d7d;text-align:center}@media (max-width:399px){._3mz_r .AZYIw{display:block}._3mz_r .AZYIw ._2BTPj{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;margin-bottom:15px}._3mz_r .AZYIw img,._3mz_r .AZYIw p,._3mz_r .AZYIw span._2Mbc8{width:100%}._3mz_r .AZYIw img{max-width:45px}}._3mz_r p{font-size:18px;line-height:26px;color:#7d7d7d}@media (max-width:1100px){._3mz_r{display:block}._3mz_r ._3opDY{display:none;font-size:10vw}._3mz_r ._1w6VX{font-size:10vw}}@media (min-width:1100px){._3mz_r ._1w6VX{display:none}}@media (max-width:1100px){._2K8-m h2{font-size:5vw}._2K8-m h3{font-size:3vw}}._2K8-m h2,._2K8-m h3,._2K8-m h5{text-align:center!important}._2K8-m h2{color:#4f4f6f}._2K8-m h5{color:#bbb}._2K8-m h2 a{color:transparent;background:-webkit-gradient(linear,left top,right top,from(#4648d0),color-stop(#6d2ad6),color-stop(#ab2dec),to(#e834ca));background:-webkit-linear-gradient(left,#4648d0,#6d2ad6,#ab2dec,#e834ca);background:-o-linear-gradient(left,#4648d0,#6d2ad6,#ab2dec,#e834ca);background:linear-gradient(90deg,#4648d0,#6d2ad6,#ab2dec,#e834ca);background-clip:text;-webkit-background-clip:text}._2K8-m h5{margin-bottom:50px}._2K8-m h3{margin:30px 0}._22Pzm{font-size:36px;text-align:center!important;color:#4f4f6f}", ""]);

// exports
exports.locals = {
	"titleBlockFlex": "_1U89n",
	"aboutUsBlock": "_3mz_r",
	"titleBlock": "_3opDY",
	"titleBlock2": "_1w6VX",
	"flaxBlock": "AZYIw",
	"flaxList": "_2BTPj",
	"count": "_2Mbc8",
	"infoBlock": "_2K8-m",
	"titleCenter": "_22Pzm"
};