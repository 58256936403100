export const modalZ = () =>{
  var serviceModal1 = document.getElementById( 'serviceModal1' ),
      serviceModal2 = document.getElementById( 'serviceModal2' ),
      serviceModal3 = document.getElementById( 'serviceModal3' ),
      serviceModal4 = document.getElementById( 'serviceModal4' ),
      modalDialog1 = document.getElementById( 'modalDialog1' ),
      modalDialog2 = document.getElementById( 'modalDialog2' ),
      modalDialog3 = document.getElementById( 'modalDialog3' ),
      modalDialog4 = document.getElementById( 'modalDialog4' ),
      modalDialog1Page = new DialogFx( modalDialog1 ),
      modalDialog2Page = new DialogFx( modalDialog2 ),
      modalDialog3Page = new DialogFx( modalDialog3 ),
      modalDialog4Page = new DialogFx( modalDialog4 );




  function serviceModal() {

      // landing page

      serviceModal1.addEventListener( 'click', modalDialog1Page.toggle.bind(modalDialog1Page) );
      // corporate website

      serviceModal2.addEventListener( 'click', modalDialog2Page.toggle.bind(modalDialog2Page) );
      // online shop

      serviceModal3.addEventListener( 'click', modalDialog3Page.toggle.bind(modalDialog3Page) );
      // individual project

      serviceModal4.addEventListener( 'click', modalDialog4Page.toggle.bind(modalDialog4Page) );

  }
  serviceModal();
    $("#services .menu__item").click(function () {
        $("#services").css({zIndex: "999"});
        $(".homePage").css({transform: "none"});
        if ($(window).width() <= 1100) {
            $(".menu--adsila h2").css({display: "none"});
        }
    });
    $("#projects .grid__item, #projects .slick-slide").click(function () {
        $("#projects").css({zIndex: "999"});
        $(".homePage").css({transform: "none"});
    });
    $("button.action").click(function () {
        $("#services, #projects").css({zIndex: "10"});
        $(".homePage").css({transform: "translateZ(0)"});

    });

        if ($(window).width() >= 1100) {
            $('#developmentTab, #marcetingTab, #designTab, #supportTab').click(function () {
                setTimeout(function() {
                    serviceModal();
                    // modalZ();
                    $('#serviceModal1,#serviceModal2,#serviceModal3,#serviceModal4').click(function () {
                        $(".homePage").css({transform: "none"});
                        console.log('yaaa')
                    });
                }, 1500);
            });
        }
        $(document).keydown(function(e) {
            if( e.keyCode === 27 ) {
                $("#services, #projects").css({zIndex: "10"});
                $(".homePage").css({transform: "translateZ(0)"});
                if ($(window).width() <= 1100) {
                    $(".menu--adsila h2").css({display: "block"});
                }
                return false;
            }
        });
};
