import React, { Component } from 'react';
import  Animate  from './animate.js';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import anim from './animate.css';
import WebsiteDevelopment from './WebsiteDevelopment';
import WebSupport from './WebSupport';
import InternetMarketing from './InternetMarketing';
import WebDesign from './WebDesign';


const messages = defineMessages({
    developmentTitleSvg: {
        id: 'services.developmentTitleSvg',
        defaultMessage: 'Website development',
    },
    desingTitleSvg: {
        id: 'services.desingTitleSvg',
        defaultMessage: 'Website desing',
    },
    supportTitleSvg: {
        id: 'services.supportTitleSvg',
        defaultMessage: 'Website desing',
    },
    marketingTitleSvg: {
        id: 'services.marketingTitleSvg',
        defaultMessage: 'Website support',
    }
});


const svgBlock = {
                    svg : [
                        {name:    <WebsiteDevelopment />,
                         message: <div className={anim['span-svg-style-developer']}>
                                    <FormattedMessage {...messages.developmentTitleSvg} />
                                  </div>,
                         id :     1},
                        {name:    <WebDesign />,
                         message: <div className={anim['span-svg-style-desing']}>
                                    <FormattedMessage {...messages.desingTitleSvg} />
                                  </div>,
                         id :     2},
                        {name:    <InternetMarketing />,
                         message: <div className={anim['span-svg-style-marketing']}>
                                    <FormattedMessage {...messages.marketingTitleSvg} />
                                  </div>,
                            id : 3},
                        {name:    <WebSupport />,
                         message: <div className={anim['span-svg-style-support']}>
                                    <FormattedMessage {...messages.supportTitleSvg} />
                                  </div>,
                            id : 4},
                         ]
                 };

class SvgAnimate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,
        }
    }

    componentDidMount() {
        Animate();
    }

    doClick(index, event) {
        this.props.click(index);
    }

    render() {
        const svg = svgBlock.svg.map((item, index) => {
            return [
                <div>
                    <div
                        className="blockItem"
                        key={item.message}
                        onClick={this.doClick.bind(this, index)}>
                            {item.name}
                    </div>
                    <div key={item.id}>{item.message}</div>
                </div>
            ];
        });

        return (
            <div className={anim['main-position-servise-svg']}>
                <div className={anim['position-servise-svg']}>
                    {svg}
                </div>
            </div>

        );
    }
}

export default injectIntl(compose(withStyles(anim))(SvgAnimate));