import React, { Component } from 'react';
import anim from './animate.css';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

class InternetMarketing extends React.Component {
    render() {
        return (
            <div className={anim["svg-width"]}>
                <svg className={anim["icon-eComm"]} viewBox="0 0 446.1 365.5">
                    <linearGradient id="XMLID_2_n" gradientUnits="userSpaceOnUse" x1="222.6759" y1="400.9323" x2="222.6759" y2="162.0416" gradientTransform="matrix(1 0 0 -1 0 368)">
                        <stop offset="0.1902" style={{stopColor: '#594CFE'}} />
                        <stop offset={1} style={{stopColor: '#AA75FF'}} />
                    </linearGradient>
                    <path id="XMLID_210_" style={{fill: 'url(#XMLID_2_n)'}} d="M6.8,188.1C-6.6,157,11.5,102.8,60.4,53.7C114.1-0.2,185.8-5.9,257,12.1
                c18.2,4.6,36.5,12.5,55.5,14c14.5,1.1,25.9-6.3,37.2-6.5c25.2-0.3,45.3,16,50,39c4.4,21.7-9.7,45.8-8.4,68
                c1.6,27.4,17.3,48.3,31.1,71c64.2,106-30.2,204.4-181,145.8c-75.1-29.2-149.8,20.2-167.2-21.6c-11.4-27.5-1.6-48.1-7.9-74.8
                C60.5,222.8,25.4,231.3,6.8,188.1z" />
                    <path className={anim["star"] + " " + anim["star-1"] + " " + "origin-center"} style={{fill: '#E5D0E6'}} d="M104.6,307.2v2.7H102c-0.9,0-1.7,0.7-1.7,1.7v0.1c0,0.9,0.7,1.7,1.7,1.7h2.7v2.7
                c0,0.9,0.7,1.7,1.7,1.7h0.1c0.9,0,1.7-0.7,1.7-1.7v-2.7h2.7c0.9,0,1.7-0.7,1.7-1.7v-0.1c0-0.9-0.7-1.7-1.7-1.7H108v-2.7
                c0-0.9-0.7-1.7-1.7-1.7h-0.1C105.4,305.5,104.6,306.3,104.6,307.2z" />
                    <path className={anim["star"] + " " + anim["star-2"] + " " + "origin-center"} style={{fill: '#EFDFED'}} d="M389.9,195v3.9H386c-1.3,0-2.4,1.1-2.4,2.4v0.1c0,1.3,1.1,2.4,2.4,2.4h3.9v3.9
                c0,1.4,1.1,2.4,2.4,2.4h0.1c1.4,0,2.4-1.1,2.4-2.4v-3.9h3.9c1.3,0,2.4-1.1,2.4-2.4v-0.1c0-1.4-1.1-2.4-2.4-2.4h-3.9V195
                c0-1.4-1.1-2.4-2.4-2.4h-0.1C391,192.5,389.9,193.6,389.9,195z" />
                    <circle id="XMLID_207_" style={{fill: '#EFDFED'}} cx={243} cy="43.8" r="4.5" />
                    <path id="XMLID_206_" style={{opacity: '0.4', fill: '#F1909A', enableBackground: 'new'}} d="M225.5,223.3c0,37-30,66.9-66.9,66.9
                c-37,0-66.9-30-66.9-66.9c0-37,30-66.9,66.9-66.9C195.5,156.4,225.5,186.4,225.5,223.3z" />
                    <linearGradient id="XMLID_3_n" gradientUnits="userSpaceOnUse" x1="125.4" y1="191.55" x2={337} y2="191.55" gradientTransform="matrix(1 0 0 -1 0 368)">
                        <stop offset="6.016400e-02" style={{stopColor: '#DB6B86'}} />
                        <stop offset="8.596202e-02" style={{stopColor: '#DC6D87'}} />
                        <stop offset={1} style={{stopColor: '#F7A6A5'}} />
                    </linearGradient>
                    <path id="XMLID_205_" style={{fill: 'url(#XMLID_3_n)'}} d="M337,176.5c0,58.4-47.3,105.7-105.8,105.7c-58.4,0-105.8-47.3-105.8-105.7
                S172.7,70.7,231.2,70.7C289.6,70.8,337,118.1,337,176.5z" />
                    <ellipse id="XMLID_204_" style={{opacity: '0.3', fill: '#ADBADF', enableBackground: 'new'}} cx={293} cy="300.8" rx="38.9" ry={4} />
                    <circle id="XMLID_197_" style={{opacity: '0.3', fill: '#FFFFFF', enableBackground: 'new'}} cx="197.9" cy="146.7" r="8.7" />
                    <g className={anim["phone"]}>
                        <ellipse id="phone-shadow" style={{opacity: '0.3', fill: '#DBA6AC', enableBackground: 'new'}} cx="317.1" cy="316.1" rx="21.3" ry="2.4" />
                        <path id="XMLID_196_" style={{fill: '#582768'}} d="M369.6,287.2c0,3-1,3.8-5.4,5.4s-6.8,2.5-6.8,2.5c-3,0-5.4-2.4-5.4-5.4V57.5
            c0-3,2.4-5.5,5.4-5.5c0,0,2.1-0.2,5.8,0.8c3.7,1.1,5.9,3,5.9,6L369.6,287.2z" />
                        <path id="XMLID_192_" style={{opacity: '0.2', fill: '#FFFFFF', enableBackground: 'new'}} d="M279.5,72l1.6,139.3l74.2-162
            c-0.1-0.3-0.1-0.6-0.2-0.9l-71.4,18.7C281.4,67.1,279.5,69.3,279.5,72z" />
                        <path id="XMLID_191_" style={{fill: '#FFFFFF'}} d="M351.4,252.9c0,2-1.7,3.6-3.8,3.6l-54.3-13.3c-2.1,0-3.8-1.6-3.8-3.6l-3-148.1
            c0-2,1.7-3.6,3.8-3.6l57.4-13.7c2.1,0,3.8,1.6,3.8,3.6v175.1H351.4z" />
                        <path id="XMLID_195_" style={{fill: '#582768'}} d="M360.7,290.4c0,2.7-1.9,4.8-4.3,4.8l-70-24.2c-2.3,0-4.3-2.2-4.3-4.8L280,75.8
            c0-2.7,1.9-4.8,4.3-4.8l72.2-18.9c2.3,0,4.3,2.2,4.3,4.8L360.7,290.4L360.7,290.4z" />
                        <path id="XMLID_194_" style={{fill: '#582768'}} d="M369,283.3c0,3-1,3.8-5.4,5.4s-6.8,2.5-6.8,2.5c-3,0-5.4-2.4-5.4-5.4V53.7
            c0-3,2.4-5.4,5.4-5.4c0,0,2.1-0.2,5.8,0.8c3.7,1.1,5.9,3,5.9,6L369,283.3z" />
                        <linearGradient id="XMLID_19_n" gradientUnits="userSpaceOnUse" x1="318.7369" y1="168.6587" x2="327.4222" y2="318.9295" gradientTransform="matrix(1 0 0 -1 0 368)">
                            <stop offset="9.016399e-02" style={{stopColor: '#A62257'}} />
                            <stop offset="0.4541" style={{stopColor: '#86245E'}} />
                            <stop offset={1} style={{stopColor: '#582768'}} />
                        </linearGradient>
                        <path id="XMLID_193_" style={{fill: 'url(#XMLID_19_n)'}} d="M360.2,286.6c0,2.7-1.9,4.8-4.3,4.8l-70-24.2c-2.3,0-4.3-2.2-4.3-4.8
            L279.5,72c0-2.7,1.9-4.8,4.3-4.8L356,48.3c2.3,0,4.3,2.2,4.3,4.8L360.2,286.6L360.2,286.6z" />
                        <linearGradient id="XMLID_20_n" gradientUnits="userSpaceOnUse" x1="330.042" y1="172.376" x2="308.8882" y2="254.2337" gradientTransform="matrix(1 0 0 -1 0 368)">
                            <stop offset="0.1902" style={{stopColor: '#EFDFED'}} />
                            <stop offset={1} style={{stopColor: '#FDF0F6'}} />
                        </linearGradient>
                        <path id="XMLID_190_" style={{fill: 'url(#XMLID_20_n)'}} d="M351.4,252.9c0,2-1.7,3.6-3.8,3.6l-54.3-13.3c-2.1,0-3.8-1.6-3.8-3.6
            l-3-148.1c0-2,1.7-3.6,3.8-3.6l57.4-13.7c2.1,0,3.8,1.6,3.8,3.6v175.1H351.4z" />
                    </g>
                    <g className={anim["bag-bb"] + " " + anim["hover"]}>
                        <polygon id="XMLID_177_" style={{fill: '#461E56'}} points="265,202.4 277.3,305 264.3,313.3 " />
                        <polygon id="XMLID_174_" style={{fill: '#F0929A'}} points="264.3,313.3 174.2,315.8 172.5,206.3 265,202.4 " />
                    </g>
                    <path id="XMLID_173_" style={{fill: '#E4ECF0'}} d="M244.5,217.2c0,1.2-1,2.1-2.3,2.2c-1.3,0-2.3-0.9-2.4-2c0-1.2,1-2.1,2.3-2.2
                C243.4,215.1,244.5,216.1,244.5,217.2z" />
                    <path id="XMLID_172_" style={{fill: '#E4ECF0'}} d="M194.4,218.8c0,1.2-1,2.1-2.2,2.2c-1.3,0-2.3-0.9-2.3-2c0-1.2,1-2.1,2.2-2.2
                C193.3,216.7,194.4,217.6,194.4,218.8z" />
                    <g className={anim["bag-b"] + " " + anim["hover"]}>
                        <polygon id="XMLID_165_" style={{fill: '#461E56'}} points="219.7,323.2 228.5,315.7 234.7,319.9 	" />
                        <path id="XMLID_170_" style={{fill: '#0D1438'}} d="M243,217.2c0-0.1,0-0.2,0-0.4c-0.1-5.1-1.9-39.3-26.1-39.3c-5,0-9.3,1.5-12.9,4.5
            c-11.7,9.6-12.4,31.5-12.4,35.8c0,0.1-0.1,0.5-0.1,0.6c0,0.3,0.3,0.5,0.7,0.5c0.3,0,0.6-0.2,0.6-0.5h0.1c0,0,0-0.2,0-0.4
            c0-3.4,0.6-25.9,12.1-35.3c3.4-2.8,7.4-4.1,12-4.1c23,0,24.8,34.2,24.9,38.4c0,0.1,0,0.4,0,0.4c0.1,0.3,0.3,0.5,0.7,0.4
            c0.3,0,0.5-0.2,0.6-0.4C243,217.3,243,217.2,243,217.2z" />
                        <polygon id="XMLID_169_" style={{fill: '#461E56'}} points="259.2,219.5 234.7,319.9 219.7,323.2 	" />
                        <polygon id="XMLID_168_" style={{fill: '#582768'}} points="219.7,323.2 134.4,293.8 171.3,190.7 259.2,219.5 	" />
                        <path id="XMLID_167_" style={{fill: '#E4ECF0'}} d="M234.8,226.2c-0.4,1.1-1.7,1.7-2.9,1.2c-1.2-0.4-1.9-1.6-1.5-2.7
            c0.4-1.1,1.7-1.7,2.9-1.2C234.5,223.9,235.2,225.1,234.8,226.2z" />
                        <path id="XMLID_166_" style={{fill: '#E4ECF0'}} d="M187.3,210.1c-0.4,1.1-1.6,1.7-2.8,1.3s-1.8-1.6-1.5-2.7c0.4-1.1,1.6-1.7,2.8-1.3
            C187.1,207.8,187.7,209,187.3,210.1z" />
                        <path id="XMLID_164_" style={{fill: '#0D1438'}} d="M233.5,225.7c0-0.1,0.1-0.2,0.1-0.4c1.7-4.8,12-37.4-10.7-46
            c-4.6-1.8-9.2-1.9-13.7-0.4c-14.3,4.9-22.7,25.2-24.2,29.1c0,0.1-0.3,0.5-0.3,0.5c-0.1,0.3,0.1,0.6,0.4,0.7c0.3,0.1,0.6,0,0.7-0.2
            h0.1c0,0,0.1-0.2,0.2-0.4c1.2-3.2,9.7-24,23.7-28.8c4.1-1.4,8.4-1.3,12.7,0.3c21.5,8.1,11.2,40.7,9.8,44.7c0,0.1-0.1,0.3-0.1,0.4
            c0,0.3,0.2,0.5,0.5,0.6s0.6,0,0.7-0.2C233.4,225.8,233.5,225.7,233.5,225.7z" />
                    </g>
                    <path id="XMLID_143_" style={{fill: '#2E3154'}} d="M249.6,204.3c-0.4-0.6-1.3-2.3-1.6-2.9c0,0.7,0.1,1.2-0.1,1.3
                c0.4,0.8,0.9,1.5,1.2,1.9c0.4,0.6,0.9,1.2,1.4,1.8c0.2-0.1,0.3-0.3,0.4-0.5C250.5,205.5,250,204.9,249.6,204.3z" />
                    <g className={anim["bag-f"] + " " + anim["hover"]}>
                        <linearGradient id="XMLID_22_n" gradientUnits="userSpaceOnUse" x1="223.6153" y1="62.2222" x2="242.7253" y2="202.0702" gradientTransform="matrix(1 0 0 -1 0 368)">
                            <stop offset="9.016399e-02" style={{stopColor: '#A62257'}} />
                            <stop offset="0.4541" style={{stopColor: '#86245E'}} />
                            <stop offset={1} style={{stopColor: '#582768'}} />
                        </linearGradient>
                        <polygon id="XMLID_160_" style={{fill: 'url(#XMLID_22_n)'}} points="282.4,301.3 193.2,313.8 179.2,205.1 270.7,191 	" />
                        <polygon id="XMLID_161_" style={{fill: '#582768'}} points="270.7,191 294.4,291.6 282.4,301.3 " />
                        <polygon id="XMLID_155_" style={{fill: '#582768'}} points="282.4,301.3 286.9,290.6 294.4,291.6 " />
                        <ellipse id="XMLID_158_" transform="matrix(0.9904 -0.1383 0.1383 0.9904 -27.8799 29.7581)" style={{fill: '#E4ECF0'}} cx="200.2" cy="215.5" rx="2.3" ry="2.1" />
                        <circle id="XMLID_111_" style={{opacity: '0.3', fill: '#EF8F99', enableBackground: 'new'}} cx="231.4" cy="254.9" r="22.6" />
                        <path id="XMLID_159_" style={{fill: '#E4ECF0'}} d="M252,208c0.2,1.2-0.7,2.2-2,2.4s-2.4-0.6-2.6-1.8c-0.2-1.2,0.7-2.2,2-2.4
            C250.7,206.1,251.9,206.9,252,208z" />
                        <path id="XMLID_144_" style={{fill: '#0D1438'}} d="M250.6,208.1c0-0.1,0-0.2-0.1-0.4c-0.7-5.1-6.2-38.8-30.4-36.2
            c-4.9,0.5-9.1,2.5-12.3,5.9c-10.6,10.8-8.8,32.7-8.4,36.9c0,0.1,0,0.5,0,0.6c0,0.3,0.4,0.5,0.7,0.4c0.3,0,0.6-0.3,0.6-0.5h0.1
            c0,0,0-0.2,0-0.4c-0.4-3.4-2.3-25.8,8.1-36.4c3-3.1,6.9-4.9,11.4-5.5c22.9-2.5,28.4,31.2,29,35.4c0,0.1,0,0.4,0.1,0.4
            c0.1,0.3,0.4,0.4,0.7,0.4s0.5-0.2,0.6-0.5C250.6,208.3,250.6,208.1,250.6,208.1z" />
                    </g>
                    <g className={anim["bag-f--tag"] + " " + anim["hover"]}>
                        <path id="XMLID_152_" style={{fill: '#C3CFCE'}} d="M257.6,205.6l-5.9,6.2l0.2,6.3l16,15.4l6.7-7l-1.3-12l-9.3-9L257.6,205.6z
                M258,210.5c-0.5,0.5-1.3,0.5-1.8,0s-0.5-1.3,0-1.8s1.3-0.5,1.8,0C258.4,209.2,258.4,210,258,210.5z" />
                        <path id="XMLID_149_" style={{fill: '#FFFFFF'}} d="M257.8,204.6l-5.9,6.2l0.2,6.3l16,15.4l12.1-12.6l-16-15.4L257.8,204.6z
                M257.5,210.2c-0.3,0.3-0.8,0.3-1.1,0c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0C257.8,209.4,257.8,209.9,257.5,210.2z" />
                        <path id="XMLID_146_" style={{fill: '#3E3A43'}} d="M255.8,208.6c-0.6,0.6-0.6,1.6,0,2.3c0.6,0.6,1.6,0.6,2.3,0c0.6-0.6,0.6-1.6,0-2.3
                C257.4,207.9,256.4,207.9,255.8,208.6z M257.5,210.2c-0.3,0.3-0.8,0.3-1.1,0c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0
                C257.8,209.4,257.8,209.9,257.5,210.2z" />
                        <path id="XMLID_103_" style={{fill: '#C3CFCE'}} d="M256.1,208.9c-0.4,0.5-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0s0.4-1.2,0-1.6
                C257.2,208.4,256.5,208.5,256.1,208.9z M257.4,210.6c-0.3,0.3-0.8,0.3-1.1,0c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0
                S257.7,210.3,257.4,210.6z" />
                        <path id="XMLID_145_" style={{fill: '#2E3154'}} d="M247.5,199.1l-0.3-0.1l-0.1,0.3c-0.6,1.4,1.2,4.2,2,5.4c1.1,1.6,2.6,3.5,4.2,4.7
                l0.5-0.6c-1.3-1-2.7-2.5-4.1-4.5c-1.3-1.9-2-3.5-2-4.4c1.8,0.9,9.8,5.4,9,9.2c-0.1,0.3-0.3,0.7-0.4,0.8c0,0.1,0,0.1,0.1,0.2
                c0.1,0.2,0.2,0.3,0.3,0.2c0.3-0.2,0.5-0.5,0.6-1.1C258.2,204.5,247.9,199.3,247.5,199.1z" />
                    </g>
                    <g className={anim["hand"] + " " + anim["hover"]}>
                        <path id="XMLID_181_" style={{fill: '#C6966D'}} d="M212.7,192.7c4.2-1.1,8.5-8.2,13.9-9.9c0,0-1.2-1.9-4.6-1.4
            c-3.4,0.4-4.7,2.5-6.2,3.6L212.7,192.7z" />
                        <path id="XMLID_178_" style={{fill: '#C6966D'}} d="M205.6,189.5c4.2-1.1,8.6-8.2,13.9-9.9c0,0-1.2-1.9-4.6-1.4
            c-3.4,0.4-4.7,2.5-6.2,3.6L205.6,189.5z" />
                        <path id="XMLID_187_" style={{fill: '#EA9A7E'}} d="M232.3,193.8c3.7-2,4-7,8.7-9.9c0,0-0.9-1.3-1.3-2c-3,1.3-4.2,3-5.5,4.4
            L232.3,193.8z" />
                        <path id="XMLID_184_" style={{fill: '#EA9A7E'}} d="M220.9,194.7c4.3-1.1,8.9-8.4,14.4-10.2c0,0-1.2-1.9-4.7-1.4
            c-3.4,0.5-4.9,2.6-6.4,3.7L220.9,194.7z" />
                        <path id="XMLID_140_" style={{fill: '#F4B29C'}} d="M327.6,130.5l-92.9,13.7c-7.3,2-15.4,14.5-17.2,17.8l-14,20.8c0,0-2.8,4.8,0.9,6.5
            c2.8,1.2,4.9-3.9,7-6.6c2.1-2.8,7-10.8,7-10.8s-5.5,11.2-6.8,13.8c-1.4,2.6-2.3,5.5,0.2,6.7c1.6,1,3.7-0.8,5.6-3.3
            c1.9-2.6,9.2-15.6,9.2-15.6s-5.6,11.4-6.3,13.9s-2,5.5-0.1,6.9s4.9-2.3,6-4.1s8-13.8,8-13.8s-2.7,8.1-3.8,11.1s-0.6,6.4,1.2,6.6
            c2.7,0.2,3.5-2.2,3.5-2.2c2.8-6.2,2.1-6.8,7.3-21.7c1.1-3.1,2.3-10.8,8.2-10.9c12.8-1.5,63.2,2.7,75.9,1.6v-0.1
            c0.1,0,0.2,0.1,0.4,0.1c2.6,0,4.7-6.9,4.7-15.3C331.6,137.9,329.9,131.6,327.6,130.5z" />
                        <g id="XMLID_127_">
                            <g id="XMLID_229_">
                                <g id="XMLID_232_">
                                    <path id="XMLID_233_" style={{fill: '#EA9A7E'}} d="M218.2,172.5c-0.1,0.2-0.3,0.6-0.4,0.9L218.2,172.5
                    C218.3,172.5,218.3,172.5,218.2,172.5z" />
                                </g>
                                <g id="XMLID_230_">
                                    <path id="XMLID_231_" style={{fill: '#EA9A7E'}} d="M215,175.5c-1.4,2.4-6.7,10.5-9,13.9c2.1-0.7,4.7-3.8,6.3-6.2
                    c2.1-3,4.7-8.9,5.9-10.7C217.7,172.5,216.4,172.9,215,175.5z" />
                                </g>
                            </g>
                        </g>
                        <path id="XMLID_124_" style={{fill: '#EA9A7E'}} d="M222.5,177.6c-1.5,2.6-6.2,12.3-9.7,15.1c1.4,0,3.1-0.6,4.8-2.9
            c1.7-2.2,2.8-4.4,3.5-5.5l3.8-7.7c0.5-0.9,1-1.7,1.3-2.2C225.9,174.3,224.3,174.4,222.5,177.6z" />
                        <path id="XMLID_121_" style={{fill: '#EA9A7E'}} d="M225.5,191l8.4-14.3c0,0-1.7-0.2-3.7,3.3c-1.4,2.5-6,12.9-9.5,14.6
            C222.3,194.9,224.4,192.7,225.5,191z" />
                        <path id="XMLID_120_" style={{fill: '#FBE0DC'}} d="M216.7,178.4c2.7-5.4,1.8-5.9,1.6-5.9c-1.1,2.3-6,10.9-7.1,13.1
            c-1.1,2.1-1.4,4.6-0.3,6C210.7,189.5,213.8,184.1,216.7,178.4z" />
                        <path id="XMLID_119_" style={{fill: '#FBE0DC'}} d="M220,194.1c-0.1-2.3,1.9-7.5,4.3-13.2c2.5-5.8,2.1-6.4,1.9-6.4
            c-2.1,3.5-5.6,10.3-6.2,12.4C219.2,189.3,218.4,192.6,220,194.1z" />
                        <path id="XMLID_113_" style={{fill: '#FBE0DC'}} d="M230.7,193.6c-0.4-2,0.3-5.9,2-10.2c2.3-6,1.6-6.5,1.3-6.6
            c-0.6,1.7-2.7,8.1-3.6,10.7C229.5,189.8,229.6,192.6,230.7,193.6z" />
                    </g>
                    <g className={anim["credit-card"]}>
                        <linearGradient id="XMLID_24_n" gradientUnits="userSpaceOnUse" x1="93.3055" y1="243.95" x2="174.2098" y2="243.95" gradientTransform="matrix(1 0 0 -1 0 368)">
                            <stop offset="9.016399e-02" style={{stopColor: '#A62257'}} />
                            <stop offset="0.4541" style={{stopColor: '#86245E'}} />
                            <stop offset={1} style={{stopColor: '#582768'}} />
                        </linearGradient>
                        <path id="XMLID_101_" style={{fill: 'url(#XMLID_24_n)'}} d="M174.2,143c0,3-2.4,5.5-5.5,5.5h-70c-3,0-5.5-2.4-5.5-5.5v-37.9
            c0-3,2.5-5.5,5.5-5.5h70c3,0,5.5,2.4,5.5,5.5V143z" />
                        <rect id="XMLID_98_" x="99.5" y="126.5" style={{fill: '#FFFFFF'}} width={64} height="5.6" />
                        <rect id="XMLID_95_" x="120.8" y="136.5" style={{fill: '#FFFFFF'}} width="42.6" height="2.5" />
                        <rect id="XMLID_92_" x="99.5" y="136.5" style={{fill: '#FFFFFF'}} width="13.7" height="2.5" />
                        <rect id="XMLID_89_" x="101.1" y="110.3" style={{fill: '#FFFFFF'}} width="14.2" height="11.5" />
                        <linearGradient id="XMLID_25_n" gradientUnits="userSpaceOnUse" x1="144.8729" y1="253.8" x2="158.5399" y2="253.8" gradientTransform="matrix(1 0 0 -1 0 368)">
                            <stop offset="6.016400e-02" style={{stopColor: '#DB6B86'}} />
                            <stop offset="8.596202e-02" style={{stopColor: '#DC6D87'}} />
                            <stop offset={1} style={{stopColor: '#F7A6A5'}} />
                        </linearGradient>
                        <circle id="XMLID_88_" style={{fill: 'url(#XMLID_25_n)'}} cx="151.7" cy="114.2" r="6.8" />
                        <circle id="XMLID_85_" style={{fill: '#FFFFFF'}} cx={161} cy="114.2" r="6.8" />
                    </g>
                    <g className={anim["bubble-b"] + " " + anim["hover"] + " " + "origin-center"}>
                        <circle id="XMLID_199_" style={{fill: '#7D2360'}} cx="221.7" cy="112.3" r="25.6" />
                        <g id="XMLID_51_">
                            <g id="XMLID_72_">
                                <g id="XMLID_83_">
                                    <path id="XMLID_84_" style={{fill: '#FFFFFF'}} d="M220.5,127.7c-8.3,0-15.1-6.8-15.1-15.1s6.8-15.1,15.1-15.1
                    c4.1,0,7.8,1.5,10.7,4.3s4.4,6.5,4.4,10.6c0,0.3-0.2,0.5-0.6,0.5c-0.3,0-0.6-0.2-0.6-0.5c0-7.7-6.2-13.8-14-13.8
                    c-7.7,0-14,6.3-14,14s6.3,14,14,14c2.3,0,4.5-0.6,6.5-1.6c0.3-0.1,0.6,0,0.7,0.2c0.1,0.3,0,0.6-0.2,0.7
                    C225.3,127.1,222.9,127.7,220.5,127.7z" />
                                </g>
                                <g id="XMLID_81_">
                                    <path id="XMLID_82_" style={{fill: '#FFFFFF'}} d="M220.5,127.6c-4.5,0-8-6.6-8-15s3.5-15,8-15s8,6.5,8,14.8c0,0.3-0.2,0.6-0.5,0.6
                    s-0.5-0.2-0.5-0.6c0-7.5-3.1-13.7-6.9-13.7c-3.7,0-6.9,6.4-6.9,13.9s3.1,13.9,6.9,13.9c1.2,0,2.1-0.4,3-1.5
                    c0.2-0.2,0.6-0.2,0.8,0s0.2,0.5,0,0.8C223.1,127.1,222,127.6,220.5,127.6z" />
                                </g>
                                <g id="XMLID_78_">
                                    <g id="XMLID_79_">
                                        <path id="XMLID_80_" style={{fill: '#FFFFFF'}} d="M233.6,107.2h-26.4c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.5,0.6-0.5h26.4
                    c0.3,0,0.5,0.2,0.5,0.5C234.2,107,234,107.2,233.6,107.2z" />
                                    </g>
                                </g>
                                <g id="XMLID_75_">
                                    <g id="XMLID_76_">
                                        <path id="XMLID_77_" style={{fill: '#FFFFFF'}} d="M223.6,119.1h-16.4c-0.3,0-0.5-0.2-0.5-0.6c0-0.3,0.2-0.5,0.5-0.5h16.4
                    c0.3,0,0.5,0.2,0.5,0.5C224.2,118.9,223.9,119.1,223.6,119.1z" />
                                    </g>
                                </g>
                                <g id="XMLID_73_">
                                    <path id="XMLID_74_" style={{fill: '#FFFFFF'}} d="M220.5,127.5c-0.3,0-0.5-0.2-0.5-0.5V98.3c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5
                    V127C221,127.3,220.8,127.5,220.5,127.5z" />
                                </g>
                            </g>
                            <g id="XMLID_52_">
                                <g id="XMLID_68_">
                                    <path id="XMLID_69_" style={{fill: '#FFFFFF'}} d="M242.2,125.9h-17.9c-0.7,0-1.2-0.6-1.2-1.2v-11.5c0-0.7,0.6-1.2,1.2-1.2h17.9
                    c0.7,0,1.2,0.6,1.2,1.2v11.5C243.5,125.4,242.9,125.9,242.2,125.9z M224.3,113.1c-0.1,0-0.1,0.1-0.1,0.1v11.5
                    c0,0.1,0.1,0.1,0.1,0.1h17.9c0.1,0,0.1-0.1,0.1-0.1v-11.5c0-0.1-0.1-0.1-0.1-0.1H224.3z" />
                                </g>
                                <g id="XMLID_64_">
                                    <path id="XMLID_65_" style={{fill: '#FFFFFF'}} d="M229.4,121.4h-3.3c-0.7,0-1.2-0.6-1.2-1.2v-2.4c0-0.7,0.6-1.2,1.2-1.2h3.3
                    c0.7,0,1.2,0.6,1.2,1.2v2.4C230.7,120.9,230.1,121.4,229.4,121.4z M226.1,117.6c-0.1,0-0.1,0.1-0.1,0.1v2.4
                    c0,0.1,0.1,0.1,0.1,0.1h3.3c0.1,0,0.1-0.1,0.1-0.1v-2.4c0-0.1-0.1-0.1-0.1-0.1H226.1z" />
                                </g>
                                <g id="XMLID_55_">
                                    <g id="XMLID_62_">
                                        <path id="XMLID_63_" style={{fill: '#FFFFFF'}} d="M228.1,123.7h-2.6c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.5,0.6-0.5h2.6
                    c0.3,0,0.6,0.2,0.6,0.5C228.7,123.4,228.4,123.7,228.1,123.7z" />
                                    </g>
                                    <g id="XMLID_60_">
                                        <path id="XMLID_61_" style={{fill: '#FFFFFF'}} d="M232.5,123.7h-2.6c-0.3,0-0.5-0.2-0.5-0.6c0-0.3,0.2-0.5,0.5-0.5h2.6
                    c0.3,0,0.6,0.2,0.6,0.5S232.8,123.7,232.5,123.7z" />
                                    </g>
                                    <g id="XMLID_58_">
                                        <path id="XMLID_59_" style={{fill: '#FFFFFF'}} d="M236.9,123.7h-2.6c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.5,0.6-0.5h2.6
                    c0.3,0,0.6,0.2,0.6,0.5S237.2,123.7,236.9,123.7z" />
                                    </g>
                                    <g id="XMLID_56_">
                                        <path id="XMLID_57_" style={{fill: '#FFFFFF'}} d="M241.4,123.7h-2.6c-0.3,0-0.5-0.2-0.5-0.6c0-0.3,0.2-0.5,0.5-0.5h2.6
                    c0.3,0,0.6,0.2,0.6,0.5C241.9,123.4,241.7,123.7,241.4,123.7z" />
                                    </g>
                                </g>
                                <g id="XMLID_53_">
                                    <path id="XMLID_54_" style={{fill: '#FFFFFF'}} d="M240.3,115.5h-6.8c-0.3,0-0.5-0.2-0.5-0.6c0-0.3,0.2-0.5,0.5-0.5h6.8
                    c0.3,0,0.5,0.2,0.5,0.5C240.8,115.3,240.6,115.5,240.3,115.5z" />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g className={anim["bubble-m "] + " " + anim["hover"] + " " + "origin-center"}>
                        <linearGradient id="XMLID_27_n" gradientUnits="userSpaceOnUse" x1="169.1953" y1="278.6562" x2="199.5977" y2="325.5615" gradientTransform="matrix(1 0 0 -1 0 368)">
                            <stop offset="9.016399e-02" style={{stopColor: '#A62257'}} />
                            <stop offset="0.4541" style={{stopColor: '#86245E'}} />
                            <stop offset={1} style={{stopColor: '#582768'}} />
                        </linearGradient>
                        <path id="XMLID_198_" style={{fill: 'url(#XMLID_27_n)'}} d="M203.5,68.2c0,11.4-9.2,20.6-20.6,20.6c-11.4,0-20.6-9.2-20.6-20.6
            s9.2-20.6,20.6-20.6S203.5,56.8,203.5,68.2z" />
                        <g id="XMLID_4_">
                            <g id="XMLID_26_">
                                <g id="XMLID_31_">
                                    <g id="XMLID_32_">
                                        <g id="XMLID_40_">
                                            <path id="XMLID_41_" style={{fill: '#FFFFFF'}} d="M185,81.6h-10.7c-1.4,0-2.5-1.1-2.5-2.5V58.4c0-1.4,1.1-2.5,2.5-2.5H185
                        c1.4,0,2.5,1.1,2.5,2.5v6.1c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-6.1c0-0.9-0.7-1.6-1.6-1.6h-10.7c-0.9,0-1.6,0.7-1.6,1.6
                        v20.7c0,0.9,0.7,1.6,1.6,1.6H185c0.9,0,1.6-0.7,1.6-1.6v-4.3c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v4.3
                        C187.5,80.5,186.3,81.6,185,81.6z" />
                                        </g>
                                        <g id="XMLID_38_">
                                            <path id="XMLID_39_" style={{fill: '#FFFFFF'}} d="M186.9,60h-14.6c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h14.6
                        c0.2,0,0.4,0.2,0.4,0.4C187.3,59.8,187.1,60,186.9,60z" />
                                        </g>
                                        <g id="XMLID_35_">
                                            <path id="XMLID_36_" style={{fill: '#FFFFFF'}} d="M186.9,78.5h-14.6c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h14.6
                        c0.2,0,0.4,0.2,0.4,0.4C187.3,78.3,187.1,78.5,186.9,78.5z" />
                                        </g>
                                        <g id="XMLID_33_">
                                            <path id="XMLID_34_" style={{fill: '#FFFFFF'}} d="M181.2,79.8h-3.3c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h3.3
                        c0.2,0,0.4,0.2,0.4,0.4C181.7,79.6,181.5,79.8,181.2,79.8z" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="XMLID_5_">
                                <g id="XMLID_21_">
                                    <path id="XMLID_23_" style={{fill: '#FFFFFF'}} d="M195,74.9h-13.5c-0.5,0-1-0.4-1-1v-8.7c0-0.5,0.4-1,1-1H195c0.5,0,1,0.4,1,1v8.7
                    C196,74.5,195.6,74.9,195,74.9z M181.5,65.1C181.4,65.1,181.4,65.2,181.5,65.1l-0.1,8.8c0,0.1,0,0.1,0.1,0.1H195
                    c0.1,0,0.1,0,0.1-0.1v-8.7c0-0.1,0-0.1-0.1-0.1C195,65.1,181.5,65.1,181.5,65.1z" />
                                </g>
                                <g id="XMLID_17_">
                                    <path id="XMLID_18_" style={{fill: '#FFFFFF'}} d="M185.3,71.5h-2.5c-0.5,0-1-0.4-1-1v-1.8c0-0.5,0.4-1,1-1h2.5c0.5,0,1,0.4,1,1v1.8
                    C186.3,71,185.9,71.5,185.3,71.5z M182.8,68.6C182.8,68.6,182.7,68.6,182.8,68.6l-0.1,1.9c0,0.1,0,0.1,0.1,0.1h2.5
                    c0.1,0,0.1,0,0.1-0.1v-1.8c0-0.1,0-0.1-0.1-0.1H182.8z" />
                                </g>
                                <g id="XMLID_8_">
                                    <g id="XMLID_15_">
                                        <path id="XMLID_16_" style={{fill: '#FFFFFF'}} d="M184.3,73.2h-2c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h2
                    c0.2,0,0.4,0.2,0.4,0.4C184.8,73,184.6,73.2,184.3,73.2z" />
                                    </g>
                                    <g id="XMLID_13_">
                                        <path id="XMLID_14_" style={{fill: '#FFFFFF'}} d="M187.7,73.2h-2c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h2
                    c0.2,0,0.4,0.2,0.4,0.4C188.1,73,187.9,73.2,187.7,73.2z" />
                                    </g>
                                    <g id="XMLID_11_">
                                        <path id="XMLID_12_" style={{fill: '#FFFFFF'}} d="M191,73.2h-2c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h2
                    c0.2,0,0.4,0.2,0.4,0.4C191.5,73,191.3,73.2,191,73.2z" />
                                    </g>
                                    <g id="XMLID_9_">
                                        <path id="XMLID_10_" style={{fill: '#FFFFFF'}} d="M194.4,73.2h-2c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h2
                    c0.2,0,0.4,0.2,0.4,0.4C194.8,73,194.6,73.2,194.4,73.2z" />
                                    </g>
                                </g>
                                <g id="XMLID_6_">
                                    <path id="XMLID_7_" style={{fill: '#FFFFFF'}} d="M193.6,67h-5.1c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h5.1
                    c0.2,0,0.4,0.2,0.4,0.4S193.8,67,193.6,67z" />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g className={anim["bubble-t"] + " " + anim["hover"] + " " + "origin-center"}>
                        <linearGradient id="XMLID_28_n" gradientUnits="userSpaceOnUse" x1="106.4" y1="304.8" x2="152.8658" y2="304.8" gradientTransform="matrix(1 0 0 -1 0 368)">
                            <stop offset="6.016400e-02" style={{stopColor: '#DB6B86'}} />
                            <stop offset="8.596202e-02" style={{stopColor: '#DC6D87'}} />
                            <stop offset={1} style={{stopColor: '#F7A6A5'}} />
                        </linearGradient>
                        <path id="XMLID_200_" style={{fill: 'url(#XMLID_28_n)'}} d="M152.8,63.2c0,12.8-10.4,23.2-23.2,23.2S106.4,76,106.4,63.2
            S116.8,40,129.6,40S152.8,50.4,152.8,63.2z" />
                        <g id="XMLID_42_">
                            <g id="XMLID_48_">
                                <path id="XMLID_49_" style={{fill: '#FFFFFF'}} d="M130.7,79.3l-0.3-3.8c-2.3,0.1-4.7-0.3-6.2-1.2l0.7-2.6c1.5,0.8,3.6,1.3,5.8,1.2
                c2.8-0.2,4.6-1.9,4.4-4.1c-0.2-2.1-1.8-3.2-4.9-4.1c-4.2-1.2-6.8-2.7-7.1-6c-0.3-3.1,1.9-5.7,5.4-6.6l-0.3-3.8l2.5-0.2l0.3,3.7
                c2.4-0.1,4.1,0.4,5.3,0.9l-0.8,2.6c-0.9-0.4-2.6-1.1-5.1-0.9c-3,0.2-4,2.1-3.9,3.6c0.2,2,1.7,2.8,5.3,3.9
                c4.2,1.3,6.5,3.1,6.8,6.4c0.3,3-1.7,6-5.7,6.9l0.4,3.9L130.7,79.3z" />
                            </g>
                        </g>
                    </g>
                    <g className={anim["overlay"]}>
                        <linearGradient id="overlay_1_" gradientUnits="userSpaceOnUse" x1="265.0511" y1="828.6807" x2="245.3274" y2="752.3569" gradientTransform="matrix(1 0 0 1 0 -424)">
                            <stop offset="0.1902" style={{stopColor: '#EFDFED'}} />
                            <stop offset={1} style={{stopColor: '#FDF0F6'}} />
                        </linearGradient>
                        <path id="overlay" style={{fill: 'url(#overlay_1_)'}} d="M267.2,462.2c0,1.9-0.6,3.4-1.2,3.4l-17.8-12.5c-0.7,0-1.2-1.5-1.2-3.4
                l-1.3-74.4c5.5-7.4,5.2-19.3,0-30.2l0.3-34.4c0-1.9,0.6-3.4,1.2-3.4l18.9-12.9c0.7,0,1.2,1.5,1.2,3.4L267.2,462.2L267.2,462.2z" />
                    </g>
                </svg>
            </div>
        );
    }
}

export default injectIntl(compose(withStyles(anim))(InternetMarketing));