exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3CB8T{padding:20px 0;width:100%}._JbWy{font-size:36px;text-align:center!important;color:#fff;padding-top:30px}._30SwZ{-webkit-transform:scale(1);-moz-transform:scale(1)}._30SwZ,._30SwZ:hover{-webkit-transition-timing-function:ease-out;-webkit-transition-duration:.55s;-moz-transition-timing-function:ease-out;-moz-transition-duration:.55s}._30SwZ:hover{-webkit-transform:scale(1.1);-moz-transform:scale(1.1)}", ""]);

// exports
exports.locals = {
	"container": "_3CB8T",
	"titleCenter": "_JbWy",
	"trust": "_30SwZ"
};