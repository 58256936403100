import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from "../../../../style/page.css";
import services from "./../Services.css";
import f from '../../../../style/foundation.min.css';
import SvgAnimate from './SvgAnimate/index.js'

const messages = defineMessages({
    developmentTabImgUrl: {
        id: 'services.developmentTabImgUrl',
        defaultMessage: '/img/tabs-icon/development-services-en.svg',
    },
    designTabImgUrl: {
        id: 'services.designTabImgUrl',
        defaultMessage: '/img/tabs-icon/design-services-en.svg'
    },
    marcetingTabImgUrl: {
        id: 'services.marcetingTabImgUrl',
        defaultMessage: '/img/tabs-icon/marceting-services-en.svg'
    },
    supportTabImgUrl: {
        id: 'services.supportTabImgUrl',
        defaultMessage: '/img/tabs-icon/support-services-en.svg'
    }
});


class TabHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,
        }
    }

    doClick(index, event) {
        this.props.click(index);
    }

    render() {
        let activeClass = this.props.activeId;

        // let tabs = this.props.data.map((item, index) => {
        //             const divStyle = {
        //                         backgroundImage: 'url(' + [this.props.intl.formatMessage({...messages[item.tabImgUrl]})] + ')',
        //                     };
        //             return (
        //                 <div style={divStyle} id={item.class} key={index} className={f['large-6'] + " " + f['columns'] + " " + services[item.class] + " " + (activeClass === index ? [s['active']] : '')} onClick={this.doClick.bind(this, index)}></div>
        //                 )
        //             });
        // let tabs = messages;
        // console.log(tabs);
        // console.log('this.props.click', this.props.click);
        return (
            <div>
                <div className={['edgtf-parallax-holder']} data-parallax="{&quot;y&quot;: -100, &quot;smoothness&quot;: 20}">
                    <div className="edgtf-elements-holder   edgtf-one-column  edgtf-responsive-mode-768 ">
                        <div className="edgtf-eh-item" data-item-class="edgtf-eh-custom-8567" data-1024-1280="60px 0 148px 0">
                            <div className="edgtf-eh-item-inner">
                                <div className="edgtf-eh-item-content edgtf-eh-custom-8567">
                                    <div className={f['large-6'] + " " + f['columns']}>
                                        <div className={f['row']}>
                                            {/*{tabs}*/}
                                            <SvgAnimate click={this.props.click}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
    }

}


TabHeader.propTypes = {
    intl: intlShape.isRequired
}


export default injectIntl(
    compose(
        withStyles(s, services, f),
        connect(
            state => ({
                modalIsActive: state.modalReducer.modalIsActive,
                data: state.dataRu.DATASERVICES,
                modalIsActive: state.modalReducer.modalIsActive
        }),
        dispatch => ({
            openModal: () =>{
            return dispatch(showModal());
             },
            closeModal: () =>{
                return dispatch(hideModal());
            }
        })
      )
    )(TabHeader));