import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { showModal, hideModal } from '../../../../../actions/modal';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import services from "../../Services.css";
import portfolio from '../../../Portfolio/Portfolio.css';
import Tabs from '../Tabs/Tabs';
import TabsMobile from '../Tabs/TabsMobile';
import Close from '../../../../../components/Button/Button-close/Button-close';
import { LgScreenHidden , LgScreen } from 'react-responsive-redux';


const messages = defineMessages({
      websiteDevelopmentLandingTitle: {
          id: 'services.websiteDevelopmentLandingTitle',
          defaultMessage: 'Landing Page'
      },
      corporateWebsiteTitle: {
          id: 'services.corporateWebsiteTitle',
          defaultMessage: 'Corporate website'
      },
      onlineShopTitle: {
          id: 'services.onlineShopTitle',
          defaultMessage: 'Online shop'
      },
      individualProjectTitle: {
          id: 'services.individualProjectTitle',
          defaultMessage: 'Individual project'
      }
  });

class FoolServiceList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
            activeItem: 0,
            data: this.props.dataServices.WEBSITEDEVELOPMENT
        }

        this.changeTabOnClick = this.changeTabOnClick.bind(this);
    }
    changeTabOnClick(index) {
        this.setState({
            activeTab: index
        });
        if ($(window).width() <= 1100) {
            if (this.props.modalIsActive)
                this.props.closeModal();
            else
                this.props.openModal();
        }
    }
    render() {
        return (
            <div>
                <div className={['edgtf-parallax-holder']} data-parallax="{&quot;y&quot;: -35, &quot;smoothness&quot;: 20}">
                    <div className="edgtf-elements-holder   edgtf-one-column  edgtf-responsive-mode-768 ">
                        <div className="edgtf-eh-item" data-item-class="edgtf-eh-custom-8567" data-1024-1280="60px 0 148px 0">
                            <div className="edgtf-eh-item-inner">
                                <div className="edgtf-eh-item-content edgtf-eh-custom-8567">
                                    <FoolService data={this.state.data}
                                                 click={this.changeTabOnClick}
                                                 activeId={this.state.activeTab}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <LgScreen>
                        <div>
                            <FoolDialogDesktop
                                data={this.state.data}
                                dataTo={this.props.dataServices}
                                activeId={this.state.activeTab}
                                isOpen={this.state.isOpen}
                            />
                        </div>
                    </LgScreen>
                </div>
                <div>
                    <LgScreenHidden>
                        <div>{this.props.modalIsActive &&
                        <FoolDialogMobile
                            closeModal={this.props.closeModal}
                            data={this.state.data}
                            dataTo={this.props.dataServices}
                            activeId={this.state.activeTab}
                            isOpen={this.state.isOpen}
                        />}</div>
                    </LgScreenHidden>
                </div>
            </div>
        )
    }
}

class FoolService extends React.Component {

    doClick(index, event) {
        this.props.click(index);
    }

    render() {
        let activeClass = this.props.activeId;
        let tabs = this.props.data.map((item, index) => {
            return (
            <div
                key={index}
                onClick={this.doClick.bind(this, index)}
                id={item.modalId}
                className={services['menu__item'] + " " + ['menu__item'] + " " + services['animate'] + " " + services['slideInLeft'] + " " + (activeClass === index ? [services['active']] : '')}>
                <span className={services['menu__item-name'] + " " + ['menu__item-name']}>
                    <FormattedMessage {...messages[item.title]} />
                </span>
            </div>
            )
        });

        return (
            <div>
                {tabs}
            </div>
        )
    }

}

class FoolDialogDesktop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTo: this.props.dataTo.WEBSITEDEVELOPMENT
        }
    }
    render() {
        let activeClass = this.props.activeId;
        let contentTo = this.state.dataTo.map((item, index) => {
            return (
                <div key={index}>
                    {(activeClass === index ? <Tabs
                        parrent={item.title}
                        services={item.modalContent}
                    /> : '')}
                </div>
            )});
        let content = this.props.data.map((item, index) => {
            return (
                <div key={index} id={item.dialog} className={portfolio.dialog + " " + ['dialog'] + " " + ['pageModal']}>
                    <div className={portfolio['dialog__overlay'] + " " + ['dialog__overlay']}></div>

                    <div className={portfolio['dialog__content'] + " " + services['dialog__content'] + " " + ['dialog__content']}>
                        <Close/>
                        <div>{contentTo}</div>
                    </div>
                </div>
            )
        });
        return (
            <div id="devModalId" className={['container']}>
                <div className={['content']}>
                    {content}
                </div>
            </div>
        );
    }
}

class FoolDialogMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTo: this.props.dataTo.WEBSITEDEVELOPMENT
        }
    }
    onOverlayClose(e){
        if(e.target.id === 'modal')
            this.props.closeModal();
    }
    render() {
        let activeClass = this.props.activeId;
        let contentToMobile = this.state.dataTo.map((item, index) => {
            return (
                <div key={index}>
                    {(activeClass === index ? <TabsMobile
                        parrent={item.title}
                        services={item.modalContent}
                    /> : '')}
                </div>
            )});
        let content = this.props.data.map((item, index) => {
            return (
            <div key={index} className={services.modal} id={item.dialog} onClick={(e)=>this.onOverlayClose(e)}>
                <div className={services['modal-content']} name="modal-content">
                    <div className={services['modal-body']}>
                        <Close onClick={this.props.closeModal}/>
                        <div>{contentToMobile}</div>
                    </div>
                </div>
            </div>
            )
        });
        return (
            <div id="devModalId" className={['container']}>
                <div className={['content']}>
                    {content}
                </div>
            </div>
        );
    }
}

export default injectIntl(
  compose(
      withStyles(services, portfolio),
      connect(
          state => ({
              dataServices: state.dataRu.SERVICES,
              modalIsActive: state.modalReducer.modalIsActive,
          }),
          dispatch => ({
              openModal: () =>{
                  return dispatch(showModal());
              },
              closeModal: () =>{
                  return dispatch(hideModal());
              }
          })
      )
  )(FoolServiceList));
