import React, { Component } from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "../../../../style/page.css";
import portfolio from './../Portfolio.css';
import LazyLoad from 'react-lazy-load';


const messages = defineMessages({
    portfolioText: {
        id: 'portfolio.portfolioText',
        defaultMessage: 'Our'
    },
    portfolioTextBr: {
        id: 'portfolio.portfolioTextBr',
        defaultMessage: 'works'
    }
});


class Desktop extends Component {
    render() {
        let DATA = this.props.DATAPORTFOLIO;
        return (
            <div className={['edgtf-parallax-holder'] + " " + s.marginBlock} data-parallax="{&quot;y&quot;: -100, &quot;smoothness&quot;: 20}">
                <div className="edgtf-elements-holder   edgtf-one-column  edgtf-responsive-mode-768 ">
                    <div className="edgtf-eh-item" data-item-class="edgtf-eh-custom-8567" data-1024-1280="60px 0 148px 0">
                        <div className="edgtf-eh-item-inner">
                            <div className="edgtf-eh-item-content edgtf-eh-custom-8567">
                                <div className={['content-centered'] + " " + ['c-columns'] + " " + s['content-centered'] + " " + s['c-columns']}>
                                    <div className={portfolio['demo-3'] + " " + ['demo-3']}>
                                        <svg className={portfolio['hidden'] + " " + ['hidden']}>
                                            <symbol id="icon-arrow" viewBox="0 0 24 24">
                                                <title>arrow</title>
                                                <polygon points="6.3,12.8 20.9,12.8 20.9,11.2 6.3,11.2 10.2,7.2 9,6 3.1,12 9,18 10.2,16.8 "/>
                                            </symbol>
                                            <symbol id="icon-drop" viewBox="0 0 24 24">
                                                <title>drop</title>
                                                <path d="M12,21c-3.6,0-6.6-3-6.6-6.6C5.4,11,10.8,4,11.4,3.2C11.6,3.1,11.8,3,12,3s0.4,0.1,0.6,0.3c0.6,0.8,6.1,7.8,6.1,11.2C18.6,18.1,15.6,21,12,21zM12,4.8c-1.8,2.4-5.2,7.4-5.2,9.6c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2C17.2,12.2,13.8,7.3,12,4.8z"/><path d="M12,18.2c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7c1.3,0,2.4-1.1,2.4-2.4c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C15.8,16.5,14.1,18.2,12,18.2z"/>
                                            </symbol>
                                            <symbol id="icon-nav-arrow" viewBox="0 0 24 17">
                                                <title>nav arrow</title>
                                                <polygon points="14.1333333 2.61666667 18.9333333 7.41666667 0 7.41666667 0 10.0833333 18.9333333 10.0833333 14.1333333 14.8833333 16 16.75 24 8.75 16 0.75"></polygon>
                                            </symbol>
                                        </svg>
                                        <LazyLoad offsetVertical={0}>
                                            <div className={portfolio['grid'] + " " + ['gridList'] + " " + portfolio['grid--layout-1'] + " " +  ['grid'] + " " + ['grid--layout-1'] + " " + ['grid--current']}>
                                            <div id="vobuhoveModal" onClick={this.props.handleClickVobuhove}  className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div onClick={this.props.handleClickVeronikapolishchuk} id="veronikaModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div onClick={this.props.handleClickBfl} id="bflModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div onClick={this.props.handleClickSticker} id="stickerModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div onClick={this.props.handleClickTtsumy} id="ttsumyModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div onClick={this.props.handleClickYurKons} id="yurKonsModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div onClick={this.props.handleClickMnogoshin} id="mnogoshinModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div  onClick={this.props.handleClickSpectrprava} id="spectrModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div onClick={this.props.handleClickSalondecor} id="salondecorModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div onClick={this.props.handleClickOkClub} id="okClubModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div onClick={this.props.handleClickMyvoyages} id="myvoyagesModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div onClick={this.props.handleClickCircusfest} id="circusfestModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div onClick={this.props.handleClickAcademicstom} id="academicstomModal" className={portfolio['grid__item'] + " " +  ['grid__item']} > </div>
                                            <div className={portfolio['grid__item'] + " " +  ['grid__item'] + " " + portfolio['grid__item--nav'] + " " +  ['grid__item--nav'] + " " + portfolio['grid__item--nav-prev'] + " " +  ['grid__item--nav-prev']} data-direction="ttb">
                                                <em className={portfolio.prev} href="/item3">
                                                    <span className={portfolio['icon-wrap']}></span>
                                                </em>
                                            </div>
                                            <div className={portfolio['grid__item'] + " " +  ['grid__item'] + " " + portfolio['grid__item--nav'] + " " +  ['grid__item--nav'] + " " + portfolio['grid__item--nav-next'] + " " +  ['grid__item--nav-next']} data-direction="ttb">
                                                <em className={portfolio.next} href="/item3">
                                                    <span className={portfolio['icon-wrap']}></span>
                                                </em>
                                            </div>
                                            <h2 className={portfolio['grid__item'] + " " +  ['grid__item'] + " " + portfolio['grid__item--name'] + " " +  ['grid__item--name'] + " " + ['content__title']}><FormattedMessage {...messages[DATA.text]} /><br/><FormattedMessage {...messages[DATA.textBr]} /></h2>
                                            <h3 className={portfolio['grid__item'] + " " +  ['grid__item'] + " " + portfolio['grid__item--title'] + " " +  ['grid__item--title']}>White Code</h3>
                                            <p className={portfolio['grid__item'] + " " +  ['grid__item'] + " " + portfolio['grid__item--text'] + " " +  ['grid__item--text']}></p>
                                        </div>
                                        </LazyLoad>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default injectIntl(
    compose(
        withStyles(s, portfolio),
        connect(
            state => ({
                DATAPORTFOLIO: state.dataRu.DATAPORTFOLIO,
                modalIsActive: state.modalReducer.modalIsActive,
            }),
            dispatch => ({})
)
)(Desktop));

