export const Odometer = () => {
    window.odometerOptions = {
        format: 'd'
    };
    setTimeout(function() {
        $('#countEars').html(5);
    }, 500);
    setTimeout(function() {
        $('#countClients').html(95);
    }, 1000);
    setTimeout(function() {
        $('#countFb.odometer').html(20);
    }, 1500);
    setTimeout(function() {
        $('#countProjects.odometer').html(128);
    }, 2000);
};