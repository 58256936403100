import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import animationTab from  './styles.css';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import withStyles from "isomorphic-style-loader/lib/withStyles";

const messages = defineMessages({
    developmentTitleAcardionDevelopment: {
        id: 'services.developmentTitleAcardionDevelopment',
        defaultMessage: 'Website development',
    },
    developmentTitleAcardionDesing: {
        id: 'services.developmentTitleAcardionDesing',
        defaultMessage: 'Desing and Branding',
    },
    developmentTitleAcardionMarketing: {
        id: 'services.developmentTitleAcardionMarketing',
        defaultMessage: 'Internet marketing',
    },
    developmentTitleAcardionSupport: {
        id: 'services.developmentTitleAcardionSupport',
        defaultMessage: 'Website support',
    }
});

class Panel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 200
        };
    }

    render () {
        const { label, content, activeTab, index, activateTab } = this.props;
        const { height } = this.state;
        const { titleItem } = this.props;
        const isActive = activeTab === index;
        const innerStyle = {
            height:  `${isActive ? height : 0}px`
        }

        return (
            <div className={animationTab['panel']}
                 role='tabpanel'
                 aria-expanded={ isActive }>
                <button className={animationTab['panel__label']}
                        role='tab'
                        onClick={ activateTab }>
                    { titleItem }
                </button>
                <div className={animationTab['panel__inner']}
                     style={ innerStyle }
                     aria-hidden={ !isActive }>
                    <div className={animationTab['panel__content']}>
                        { content }
                    </div>
                </div>

            </div>
        );
    }
}

export default injectIntl(
    compose(
        withStyles(animationTab),
        connect(
            state => ({
                modalIsActive: state.modalReducer.modalIsActive,
                data: state.dataRu.DATASERVICESACARDION,
                modalIsActive: state.modalReducer.modalIsActive
            }),
            dispatch => ({
                openModal: () =>{
                    return dispatch(showModal());
                },
                closeModal: () =>{
                    return dispatch(hideModal());
                }
            })
        )
    )(Panel));
