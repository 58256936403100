import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import {compose} from 'redux';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Tabs.css";
import icon from '../../../../../fonts/ionicons.min.css';
import f from '../../../../../style/foundation.min.css';
import Input from '../../../../Form/Input/Input';
import Textarea from '../../../../Form/Textarea/Textarea';
import form from '../Form/Form.css';
import axios from 'axios';


const messages = defineMessages({
    headerSuccess1: {
        id: 'header.headerSuccess1',
        defaultMessage: 'Thank you',
    },
    headerSuccess2: {
        id: 'header.headerSuccess2',
        defaultMessage: 'Your message has been sent',
    },
    headerErr: {
        id: 'header.headerErr',
        defaultMessage: 'There was an error sending the message',
    },
  //++++++++++++++++++++++++++++++++++
  // WEBSITEDEVELOPMENT
  //++++++++++++++++++++++++++++++++++
  websiteDevelopmentLandingTitle: {
    id: 'services.websiteDevelopmentLandingTitle',
    defaultMessage: 'Landing Page'
  },
  corporateWebsiteTitle: {
    id: 'services.corporateWebsiteTitle',
    defaultMessage: 'Corporate website'
  },
  onlineShopTitle: {
    id: 'services.onlineShopTitle',
    defaultMessage: 'Online shop'
  },
  individualProjectTitle: {
    id: 'services.individualProjectTitle',
    defaultMessage: 'Individual project'
  },
  // WEBSITEDEVELOPMENT CONTENT MODAL
    //FORM
    orderText: {
        id: 'services.orderText',
        defaultMessage: 'order'
    },
    nameText: {
        id: 'services.nameText',
        defaultMessage: 'Name'
    },
    phoneText: {
        id: 'services.phoneText',
        defaultMessage: 'Phone'
    },
    emailText: {
        id: 'services.emailText',
        defaultMessage: 'Email'
    },
    textareaText: {
        id: 'services.textareaText',
        defaultMessage: 'Enter text'
    },
    addFileText: {
        id: 'services.addFileText',
        defaultMessage: 'Attach file'
    },
    buttonText: {
        id: 'services.buttonText',
        defaultMessage: 'Send'
    },
    thankYouText: {
        id: 'services.thankYouText',
        defaultMessage: 'Thank you'
    },
    messageSentText: {
        id: 'services.messageSentText',
        defaultMessage: 'Your message has been sent'
    },
    errText: {
        id: 'services.errText',
        defaultMessage: 'There was an error sending the message'
    },
  //LANDING PAGE
  //LITE
  developmentLandingLiteLi1: {
    id: 'services.developmentLandingLiteLi1',
    defaultMessage: 'from 1000$'
  },
  developmentLandingLiteLi2: {
    id: 'services.developmentLandingLiteLi2',
    defaultMessage: 'Premium Template'
  },
  developmentLandingLiteLi3: {
    id: 'services.developmentLandingLiteLi3',
    defaultMessage: 'Adaptive layout'
  },
  developmentLandingLiteLi4: {
    id: 'services.developmentLandingLiteLi4',
    defaultMessage: 'Modern Effects'
  },
  developmentLandingLiteLi5: {
    id: 'services.developmentLandingLiteLi5',
    defaultMessage: 'Standard Functionality'
  },
  //STANDART
  developmentLandingStandartLi1: {
    id: 'services.developmentLandingStandartLi1',
    defaultMessage: 'from 2000$'
  },
  developmentLandingStandartLi2: {
    id: 'services.developmentLandingStandartLi2',
    defaultMessage: 'Unique design'
  },
  developmentLandingStandartLi3: {
    id: 'services.developmentLandingStandartLi3',
    defaultMessage: 'Improved mobile layout'
  },
  developmentLandingStandartLi4: {
    id: 'services.developmentLandingStandartLi4',
    defaultMessage: 'Advanced Effects'
  },
  developmentLandingStandartLi5: {
    id: 'services.developmentLandingStandartLi5',
    defaultMessage: 'Advanced Functionality'
  },
  //PRO
  developmentLandingProLi1: {
    id: 'services.developmentLandingProLi1',
    defaultMessage: 'from 4000$'
  },
  developmentLandingProLi2: {
    id: 'services.developmentLandingProLi2',
    defaultMessage: 'Creative design'
  },
  developmentLandingProLi3: {
    id: 'services.developmentLandingProLi3',
    defaultMessage: 'Mobile version'
  },
  developmentLandingProLi4: {
    id: 'services.developmentLandingProLi4',
    defaultMessage: 'Creative WOW Effects'
  },
  developmentLandingProLi5: {
    id: 'services.developmentLandingProLi5',
    defaultMessage: 'Individual functional'
  },
  //CORPORATE SITE
  //LITE
  developmentCorporateLiteLi1: {
    id: 'services.developmentCorporateLiteLi1',
    defaultMessage: 'from 1500$'
  },
  developmentCorporateLiteLi2: {
    id: 'services.developmentCorporateLiteLi2',
    defaultMessage: 'Premium Template'
  },
  developmentCorporateLiteLi3: {
    id: 'services.developmentCorporateLiteLi3',
    defaultMessage: 'Adaptive layout'
  },
  developmentCorporateLiteLi4: {
    id: 'services.developmentCorporateLiteLi4',
    defaultMessage: 'Modern Effects'
  },
  developmentCorporateLiteLi5: {
    id: 'services.developmentCorporateLiteLi5',
    defaultMessage: 'Standard Functionality'
  },
  //STANDART
  developmentCorporateStandartLi1: {
    id: 'services.developmentCorporateStandartLi1',
    defaultMessage: 'from 3000$'
  },
  developmentCorporateStandartLi2: {
    id: 'services.developmentCorporateStandartLi2',
    defaultMessage: 'Unique design'
  },
  developmentCorporateStandartLi3: {
    id: 'services.developmentCorporateStandartLi3',
    defaultMessage: 'Improved mobile layout'
  },
  developmentCorporateStandartLi4: {
    id: 'services.developmentCorporateStandartLi4',
    defaultMessage: 'Advanced Effects'
  },
  developmentCorporateStandartLi5: {
    id: 'services.developmentCorporateStandartLi5',
    defaultMessage: 'Advanced Functionality'
  },
  //PRO
  developmentCorporateProLi1: {
    id: 'services.developmentCorporateProLi1',
    defaultMessage: 'from 7000$'
  },
  developmentCorporateProLi2: {
    id: 'services.developmentCorporateProLi2',
    defaultMessage: 'Creative design'
  },
  developmentCorporateProLi3: {
    id: 'services.developmentCorporateProLi3',
    defaultMessage: 'Mobile version'
  },
  developmentCorporateProLi4: {
    id: 'services.developmentCorporateProLi4',
    defaultMessage: 'Creative WOW Effects'
  },
  developmentCorporateProLi5: {
    id: 'services.developmentCorporateProLi5',
    defaultMessage: 'Individual functional'
  },
  //ONLINE SHOP
  //LITE
  developmentOnlineShopLiteLi1: {
    id: 'services.developmentOnlineShopLiteLi1',
    defaultMessage: 'from 2000$'
  },
  developmentOnlineShopLiteLi2: {
    id: 'services.developmentOnlineShopLiteLi2',
    defaultMessage: 'Premium Template'
  },
  developmentOnlineShopLiteLi3: {
    id: 'services.developmentOnlineShopLiteLi3',
    defaultMessage: 'Adaptive layout'
  },
  developmentOnlineShopLiteLi4: {
    id: 'services.developmentOnlineShopLiteLi4',
    defaultMessage: 'Modern Effects'
  },
  developmentOnlineShopLiteLi5: {
    id: 'services.developmentOnlineShopLiteLi5',
    defaultMessage: 'Standard Functionality'
  },
  //STANDART
  developmentOnlineShopStandartLi1: {
    id: 'services.developmentOnlineShopStandartLi1',
    defaultMessage: 'from 5000$'
  },
  developmentOnlineShopStandartLi2: {
    id: 'services.developmentOnlineShopStandartLi2',
    defaultMessage: 'Unique design'
  },
  developmentOnlineShopStandartLi3: {
    id: 'services.developmentOnlineShopStandartLi3',
    defaultMessage: 'Improved mobile layout'
  },
  developmentOnlineShopStandartLi4: {
    id: 'services.developmentOnlineShopStandartLi4',
    defaultMessage: 'Advanced Effects'
  },
  developmentOnlineShopStandartLi5: {
    id: 'services.developmentOnlineShopStandartLi5',
    defaultMessage: 'Advanced Functionality'
  },
  //PRO
  developmentOnlineShopProLi1: {
    id: 'services.developmentOnlineShopProLi1',
    defaultMessage: 'from 10000$'
  },
  developmentOnlineShopProLi2: {
    id: 'services.developmentOnlineShopProLi2',
    defaultMessage: 'Creative design'
  },
  developmentOnlineShopProLi3: {
    id: 'services.developmentOnlineShopProLi3',
    defaultMessage: 'Mobile version'
  },
  developmentOnlineShopProLi4: {
    id: 'services.developmentOnlineShopProLi4',
    defaultMessage: 'Creative WOW Effects'
  },
  developmentOnlineShopProLi5: {
    id: 'services.developmentOnlineShopProLi5',
    defaultMessage: 'Individual functional'
  },
  //INDIVIDUAL PROJECT
  //LITE
  developmentIndvidualProjectLiteLi1: {
    id: 'services.developmentIndvidualProjectLiteLi1',
    defaultMessage: 'from 5000$'
  },
  developmentIndvidualProjectLiteLi2: {
    id: 'services.developmentIndvidualProjectLiteLi2',
    defaultMessage: 'Premium Template'
  },
  developmentIndvidualProjectLiteLi3: {
    id: 'services.developmentIndvidualProjectLiteLi3',
    defaultMessage: 'Adaptive layout'
  },
  developmentIndvidualProjectLiteLi4: {
    id: 'services.developmentIndvidualProjectLiteLi4',
    defaultMessage: 'Modern Effects'
  },
  developmentIndvidualProjectLiteLi5: {
    id: 'services.developmentIndvidualProjectLiteLi5',
    defaultMessage: 'Standard Functionality'
  },
  //STANDART
  developmentIndvidualProjectStandartLi1: {
    id: 'services.developmentIndvidualProjectStandartLi1',
    defaultMessage: 'from 10000$'
  },
  developmentIndvidualProjectStandartLi2: {
    id: 'services.developmentIndvidualProjectStandartLi2',
    defaultMessage: 'Unique design'
  },
  developmentIndvidualProjectStandartLi3: {
    id: 'services.developmentIndvidualProjectStandartLi3',
    defaultMessage: 'Improved mobile layout'
  },
  developmentIndvidualProjectStandartLi4: {
    id: 'services.developmentIndvidualProjectStandartLi4',
    defaultMessage: 'Advanced Effects'
  },
  developmentIndvidualProjectStandartLi5: {
    id: 'services.developmentIndvidualProjectStandartLi5',
    defaultMessage: 'Advanced Functionality'
  },
  //PRO
  developmentIndvidualProjectProLi1: {
    id: 'services.developmentIndvidualProjectProLi1',
    defaultMessage: 'from 20000$'
  },
  developmentIndvidualProjectProLi2: {
    id: 'services.developmentIndvidualProjectProLi2',
    defaultMessage: 'Creative design'
  },
  developmentIndvidualProjectProLi3: {
    id: 'services.developmentIndvidualProjectProLi3',
    defaultMessage: 'Mobile version'
  },
  developmentIndvidualProjectProLi4: {
    id: 'services.developmentIndvidualProjectProLi4',
    defaultMessage: 'Creative WOW Effects'
  },
  developmentIndvidualProjectProLi5: {
    id: 'services.developmentIndvidualProjectProLi5',
    defaultMessage: 'Individual functional'
  },

  //++++++++++++++++++++++++++++++++++
  // WEBSITEDEVELOPMENT END
  //++++++++++++++++++++++++++++++++++
  // INTERNETMARCETING
  searchEnginePromotionTitle: {
    id: 'services.searchEnginePromotionTitle',
    defaultMessage: 'Search engine promotion'
  },
  contextualAdvertisingTitle: {
    id: 'services.contextualAdvertisingTitle',
    defaultMessage: 'Contextual advertising'
  },
  websiteOptimizationTitle: {
    id: 'services.websiteOptimizationTitle',
    defaultMessage: 'Website optimization'
  },
  smmPromotionTitle: {
    id: 'services.smmPromotionTitle',
    defaultMessage: 'SMM Promotion'
  },
  // INTERNETMARCETING CONTENT MODAL
  //SEARCH ENGINE PROMOUTION
  //LITE
  searchEnginePromotionLiteLi1: {
    id: 'services.searchEnginePromotionLiteLi1',
    defaultMessage: 'from 500$'
  },
  searchEnginePromotionLiteLi2: {
    id: 'services.searchEnginePromotionLiteLi2',
    defaultMessage: 'Basic audit and analysis of competitors'
  },
  searchEnginePromotionLiteLi3: {
    id: 'services.searchEnginePromotionLiteLi3',
    defaultMessage: 'Standard Functionality'
  },
  searchEnginePromotionLiteLi4: {
    id: 'services.searchEnginePromotionLiteLi4',
    defaultMessage: 'Basic Search Engine Optimization'
  },
  searchEnginePromotionLiteLi5: {
    id: 'services.searchEnginePromotionLiteLi5',
    defaultMessage: 'Basic external promotion'
  },
  //STANDART
  searchEnginePromotionStandartLi1: {
    id: 'services.searchEnginePromotionStandartLi1',
    defaultMessage: 'from 500$'
  },
  searchEnginePromotionStandartLi2: {
    id: 'services.searchEnginePromotionStandartLi2',
    defaultMessage: 'Basic audit and analysis of competitors'
  },
  searchEnginePromotionStandartLi3: {
    id: 'services.searchEnginePromotionStandartLi3',
    defaultMessage: 'Standard Functionality'
  },
  searchEnginePromotionStandartLi4: {
    id: 'services.searchEnginePromotionStandartLi4',
    defaultMessage: 'Basic Search Engine Optimization'
  },
  searchEnginePromotionStandartLi5: {
    id: 'services.searchEnginePromotionStandartLi5',
    defaultMessage: 'Basic external promotion'
  },
  //PRO
  searchEnginePromotionProLi1: {
    id: 'services.searchEnginePromotionProLi1',
    defaultMessage: 'from 500$'
  },
  searchEnginePromotionProLi2: {
    id: 'services.searchEnginePromotionProLi2',
    defaultMessage: 'Basic audit and analysis of competitors'
  },
  searchEnginePromotionProLi3: {
    id: 'services.searchEnginePromotionProLi3',
    defaultMessage: 'Standard Functionality'
  },
  searchEnginePromotionProLi4: {
    id: 'services.searchEnginePromotionProLi4',
    defaultMessage: 'Basic Search Engine Optimization'
  },
  searchEnginePromotionProLi5: {
    id: 'services.searchEnginePromotionProLi5',
    defaultMessage: 'Basic external promotion'
  },
    //CONTEXTURAL ADVERTISING
    //LITE
    contextualAdvertisingLiteLi1: {
        id: 'services.contextualAdvertisingLiteLi1',
        defaultMessage: 'from 500$'
    },
    contextualAdvertisingLiteLi2: {
        id: 'services.contextualAdvertisingLiteLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    contextualAdvertisingLiteLi3: {
        id: 'services.contextualAdvertisingLiteLi3',
        defaultMessage: 'Standard Functionality'
    },
    contextualAdvertisingLiteLi4: {
        id: 'services.contextualAdvertisingLiteLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    contextualAdvertisingLiteLi5: {
        id: 'services.contextualAdvertisingLiteLi5',
        defaultMessage: 'Basic external promotion'
    },
    //STANDART
    contextualAdvertisingStandartLi1: {
        id: 'services.contextualAdvertisingStandartLi1',
        defaultMessage: 'from 500$'
    },
    contextualAdvertisingStandartLi2: {
        id: 'services.contextualAdvertisingStandartLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    contextualAdvertisingStandartLi3: {
        id: 'services.contextualAdvertisingStandartLi3',
        defaultMessage: 'Standard Functionality'
    },
    contextualAdvertisingStandartLi4: {
        id: 'services.contextualAdvertisingStandartLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    contextualAdvertisingStandartLi5: {
        id: 'services.contextualAdvertisingStandartLi5',
        defaultMessage: 'Basic external promotion'
    },
    //PRO
    contextualAdvertisingProLi1: {
        id: 'services.contextualAdvertisingProLi1',
        defaultMessage: 'from 500$'
    },
    contextualAdvertisingProLi2: {
        id: 'services.contextualAdvertisingProLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    contextualAdvertisingProLi3: {
        id: 'services.contextualAdvertisingProLi3',
        defaultMessage: 'Standard Functionality'
    },
    contextualAdvertisingProLi4: {
        id: 'services.contextualAdvertisingProLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    contextualAdvertisingProLi5: {
        id: 'services.contextualAdvertisingProLi5',
        defaultMessage: 'Basic external promotion'
    },
    //WEBSITE OPTIMIZATION
    //LITE
    websiteOptimizationLiteLi1: {
        id: 'services.websiteOptimizationLiteLi1',
        defaultMessage: 'from 500$'
    },
    websiteOptimizationLiteLi2: {
        id: 'services.websiteOptimizationLiteLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    websiteOptimizationLiteLi3: {
        id: 'services.websiteOptimizationLiteLi3',
        defaultMessage: 'Standard Functionality'
    },
    websiteOptimizationLiteLi4: {
        id: 'services.websiteOptimizationLiteLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    websiteOptimizationLiteLi5: {
        id: 'services.websiteOptimizationLiteLi5',
        defaultMessage: 'Basic external promotion'
    },
    //STANDART
    websiteOptimizationStandartLi1: {
        id: 'services.websiteOptimizationStandartLi1',
        defaultMessage: 'from 500$'
    },
    websiteOptimizationStandartLi2: {
        id: 'services.websiteOptimizationStandartLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    websiteOptimizationStandartLi3: {
        id: 'services.websiteOptimizationStandartLi3',
        defaultMessage: 'Standard Functionality'
    },
    websiteOptimizationStandartLi4: {
        id: 'services.websiteOptimizationStandartLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    websiteOptimizationStandartLi5: {
        id: 'services.websiteOptimizationStandartLi5',
        defaultMessage: 'Basic external promotion'
    },
    //PRO
    websiteOptimizationProLi1: {
        id: 'services.websiteOptimizationProLi1',
        defaultMessage: 'from 500$'
    },
    websiteOptimizationProLi2: {
        id: 'services.websiteOptimizationProLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    websiteOptimizationProLi3: {
        id: 'services.websiteOptimizationProLi3',
        defaultMessage: 'Standard Functionality'
    },
    websiteOptimizationProLi4: {
        id: 'services.websiteOptimizationProLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    websiteOptimizationProLi5: {
        id: 'services.websiteOptimizationProLi5',
        defaultMessage: 'Basic external promotion'
    },
    //SMM PROMOTION
    //LITE
    smmPromotionLiteLi1: {
        id: 'services.smmPromotionLiteLi1',
        defaultMessage: 'from 500$'
    },
    smmPromotionLiteLi2: {
        id: 'services.smmPromotionLiteLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    smmPromotionLiteLi3: {
        id: 'services.smmPromotionLiteLi3',
        defaultMessage: 'Standard Functionality'
    },
    smmPromotionLiteLi4: {
        id: 'services.smmPromotionLiteLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    smmPromotionLiteLi5: {
        id: 'services.smmPromotionLiteLi5',
        defaultMessage: 'Basic external promotion'
    },
    //STANDART
    smmPromotionStandartLi1: {
        id: 'services.smmPromotionStandartLi1',
        defaultMessage: 'from 500$'
    },
    smmPromotionStandartLi2: {
        id: 'services.smmPromotionStandartLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    smmPromotionStandartLi3: {
        id: 'services.smmPromotionStandartLi3',
        defaultMessage: 'Standard Functionality'
    },
    smmPromotionStandartLi4: {
        id: 'services.smmPromotionStandartLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    smmPromotionStandartLi5: {
        id: 'services.smmPromotionStandartLi5',
        defaultMessage: 'Basic external promotion'
    },
    //PRO
    smmPromotionProLi1: {
        id: 'services.smmPromotionProLi1',
        defaultMessage: 'from 500$'
    },
    smmPromotionProLi2: {
        id: 'services.smmPromotionProLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    smmPromotionProLi3: {
        id: 'services.smmPromotionProLi3',
        defaultMessage: 'Standard Functionality'
    },
    smmPromotionProLi4: {
        id: 'services.smmPromotionProLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    smmPromotionProLi5: {
        id: 'services.smmPromotionProLi5',
        defaultMessage: 'Basic external promotion'
    },
  //++++++++++++++++++++++++++++++++++
  // INTERNETMARCETING END
  //++++++++++++++++++++++++++++++++++
  // DESIGN
  logodevelopmentTitle: {
    id: 'services.logodevelopmentTitle',
    defaultMessage: 'Logo development'
  },
  corporateStyleTitle: {
    id: 'services.corporateStyleTitle',
    defaultMessage: 'Corporate style'
  },
  webDesignTitle: {
    id: 'services.webDesignTitle',
    defaultMessage: 'Web Design'
  },
  motionDesignTitle: {
    id: 'services.motionDesignTitle',
    defaultMessage: 'Motion Design'
  },
  // DESIGN CONTENT MODAL
    //LOGO DEVELOPMENT
    //LITE
    logoDevelopmentLiteLi1: {
        id: 'services.logoDevelopmentLiteLi1',
        defaultMessage: 'from 500$'
    },
    logoDevelopmentLiteLi2: {
        id: 'services.logoDevelopmentLiteLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    logoDevelopmentLiteLi3: {
        id: 'services.logoDevelopmentLiteLi3',
        defaultMessage: 'Standard Functionality'
    },
    logoDevelopmentLiteLi4: {
        id: 'services.logoDevelopmentLiteLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    logoDevelopmentLiteLi5: {
        id: 'services.logoDevelopmentLiteLi5',
        defaultMessage: 'Basic external promotion'
    },
    //STANDART
    logoDevelopmentStandartLi1: {
        id: 'services.logoDevelopmentStandartLi1',
        defaultMessage: 'from 500$'
    },
    logoDevelopmentStandartLi2: {
        id: 'services.logoDevelopmentStandartLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    logoDevelopmentStandartLi3: {
        id: 'services.logoDevelopmentStandartLi3',
        defaultMessage: 'Standard Functionality'
    },
    logoDevelopmentStandartLi4: {
        id: 'services.logoDevelopmentStandartLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    logoDevelopmentStandartLi5: {
        id: 'services.logoDevelopmentStandartLi5',
        defaultMessage: 'Basic external promotion'
    },
    //PRO
    logoDevelopmentProLi1: {
        id: 'services.logoDevelopmentProLi1',
        defaultMessage: 'from 500$'
    },
    logoDevelopmentProLi2: {
        id: 'services.logoDevelopmentProLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    logoDevelopmentProLi3: {
        id: 'services.logoDevelopmentProLi3',
        defaultMessage: 'Standard Functionality'
    },
    logoDevelopmentProLi4: {
        id: 'services.logoDevelopmentProLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    logoDevelopmentProLi5: {
        id: 'services.logoDevelopmentProLi5',
        defaultMessage: 'Basic external promotion'
    },
    //CORPORATE STYLE
    //LITE
    corporateStyleLiteLi1: {
        id: 'services.corporateStyleLiteLi1',
        defaultMessage: 'from 500$'
    },
    corporateStyleLiteLi2: {
        id: 'services.corporateStyleLiteLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    corporateStyleLiteLi3: {
        id: 'services.corporateStyleLiteLi3',
        defaultMessage: 'Standard Functionality'
    },
    corporateStyleLiteLi4: {
        id: 'services.corporateStyleLiteLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    corporateStyleLiteLi5: {
        id: 'services.corporateStyleLiteLi5',
        defaultMessage: 'Basic external promotion'
    },
    //STANDART
    corporateStyleStandartLi1: {
        id: 'services.corporateStyleStandartLi1',
        defaultMessage: 'from 500$'
    },
    corporateStyleStandartLi2: {
        id: 'services.corporateStyleStandartLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    corporateStyleStandartLi3: {
        id: 'services.corporateStyleStandartLi3',
        defaultMessage: 'Standard Functionality'
    },
    corporateStyleStandartLi4: {
        id: 'services.corporateStyleStandartLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    corporateStyleStandartLi5: {
        id: 'services.corporateStyleStandartLi5',
        defaultMessage: 'Basic external promotion'
    },
    //PRO
    corporateStyleProLi1: {
        id: 'services.corporateStyleProLi1',
        defaultMessage: 'from 500$'
    },
    corporateStyleProLi2: {
        id: 'services.corporateStyleProLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    corporateStyleProLi3: {
        id: 'services.corporateStyleProLi3',
        defaultMessage: 'Standard Functionality'
    },
    corporateStyleProLi4: {
        id: 'services.corporateStyleProLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    corporateStyleProLi5: {
        id: 'services.corporateStyleProLi5',
        defaultMessage: 'Basic external promotion'
    },
    //WEB DESIGN
    //LITE
    webDesignLiteLi1: {
        id: 'services.webDesignLiteLi1',
        defaultMessage: 'from 500$'
    },
    webDesignLiteLi2: {
        id: 'services.webDesignLiteLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    webDesignLiteLi3: {
        id: 'services.webDesignLiteLi3',
        defaultMessage: 'Standard Functionality'
    },
    webDesignLiteLi4: {
        id: 'services.webDesignLiteLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    webDesignLiteLi5: {
        id: 'services.webDesignLiteLi5',
        defaultMessage: 'Basic external promotion'
    },
    //STANDART
    webDesignStandartLi1: {
        id: 'services.webDesignStandartLi1',
        defaultMessage: 'from 500$'
    },
    webDesignStandartLi2: {
        id: 'services.webDesignStandartLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    webDesignStandartLi3: {
        id: 'services.webDesignStandartLi3',
        defaultMessage: 'Standard Functionality'
    },
    webDesignStandartLi4: {
        id: 'services.webDesignStandartLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    webDesignStandartLi5: {
        id: 'services.webDesignStandartLi5',
        defaultMessage: 'Basic external promotion'
    },
    //PRO
    webDesignProLi1: {
        id: 'services.webDesignProLi1',
        defaultMessage: 'from 500$'
    },
    webDesignProLi2: {
        id: 'services.webDesignProLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    webDesignProLi3: {
        id: 'services.webDesignProLi3',
        defaultMessage: 'Standard Functionality'
    },
    webDesignProLi4: {
        id: 'services.webDesignProLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    webDesignProLi5: {
        id: 'services.webDesignProLi5',
        defaultMessage: 'Basic external promotion'
    },
    //MOTION DESIGN
    //LITE
    motionDesignLiteLi1: {
        id: 'services.motionDesignLiteLi1',
        defaultMessage: 'from 500$'
    },
    motionDesignLiteLi2: {
        id: 'services.motionDesignLiteLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    motionDesignLiteLi3: {
        id: 'services.motionDesignLiteLi3',
        defaultMessage: 'Standard Functionality'
    },
    motionDesignLiteLi4: {
        id: 'services.motionDesignLiteLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    motionDesignLiteLi5: {
        id: 'services.motionDesignLiteLi5',
        defaultMessage: 'Basic external promotion'
    },
    //STANDART
    motionDesignStandartLi1: {
        id: 'services.motionDesignStandartLi1',
        defaultMessage: 'from 500$'
    },
    motionDesignStandartLi2: {
        id: 'services.motionDesignStandartLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    motionDesignStandartLi3: {
        id: 'services.motionDesignStandartLi3',
        defaultMessage: 'Standard Functionality'
    },
    motionDesignStandartLi4: {
        id: 'services.motionDesignStandartLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    motionDesignStandartLi5: {
        id: 'services.motionDesignStandartLi5',
        defaultMessage: 'Basic external promotion'
    },
    //PRO
    motionDesignProLi1: {
        id: 'services.motionDesignProLi1',
        defaultMessage: 'from 500$'
    },
    motionDesignProLi2: {
        id: 'services.motionDesignProLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    motionDesignProLi3: {
        id: 'services.motionDesignProLi3',
        defaultMessage: 'Standard Functionality'
    },
    motionDesignProLi4: {
        id: 'services.motionDesignProLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    motionDesignProLi5: {
        id: 'services.motionDesignProLi5',
        defaultMessage: 'Basic external promotion'
    },

  //++++++++++++++++++++++++++++++++++
  // DESIGN END
  //++++++++++++++++++++++++++++++++++
  // SUPPORT
  contentManagementTitle: {
    id: 'services.contentManagementTitle',
    defaultMessage: 'Content Management'
  },
  copywritingTitle: {
    id: 'services.copywritingTitle',
    defaultMessage: 'Copywriting'
  },
  administrationTitle: {
    id: 'services.administrationTitle',
    defaultMessage: 'Administration'
  },
  websiteHostingTitle: {
    id: 'services.websiteHostingTitle',
    defaultMessage: 'Website Hosting'
  },
  // SUPPORT CONTENT MODAL
    //CONTENT MANAGEMENT
    //LITE
    contentManagementLiteLi1: {
        id: 'services.contentManagementLiteLi1',
        defaultMessage: 'from 500$'
    },
    contentManagementLiteLi2: {
        id: 'services.contentManagementLiteLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    contentManagementLiteLi3: {
        id: 'services.contentManagementLiteLi3',
        defaultMessage: 'Standard Functionality'
    },
    contentManagementLiteLi4: {
        id: 'services.contentManagementLiteLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    contentManagementLiteLi5: {
        id: 'services.contentManagementLiteLi5',
        defaultMessage: 'Basic external promotion'
    },
    //STANDART
    contentManagementStandartLi1: {
        id: 'services.contentManagementStandartLi1',
        defaultMessage: 'from 500$'
    },
    contentManagementStandartLi2: {
        id: 'services.contentManagementStandartLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    contentManagementStandartLi3: {
        id: 'services.contentManagementStandartLi3',
        defaultMessage: 'Standard Functionality'
    },
    contentManagementStandartLi4: {
        id: 'services.contentManagementStandartLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    contentManagementStandartLi5: {
        id: 'services.contentManagementStandartLi5',
        defaultMessage: 'Basic external promotion'
    },
    //PRO
    contentManagementProLi1: {
        id: 'services.contentManagementProLi1',
        defaultMessage: 'from 500$'
    },
    contentManagementProLi2: {
        id: 'services.contentManagementProLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    contentManagementProLi3: {
        id: 'services.contentManagementProLi3',
        defaultMessage: 'Standard Functionality'
    },
    contentManagementProLi4: {
        id: 'services.contentManagementProLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    contentManagementProLi5: {
        id: 'services.contentManagementProLi5',
        defaultMessage: 'Basic external promotion'
    },
    //COPYWRITING
    //LITE
    copywritingLiteLi1: {
        id: 'services.copywritingLiteLi1',
        defaultMessage: 'from 500$'
    },
    copywritingLiteLi2: {
        id: 'services.copywritingLiteLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    copywritingLiteLi3: {
        id: 'services.copywritingLiteLi3',
        defaultMessage: 'Standard Functionality'
    },
    copywritingLiteLi4: {
        id: 'services.copywritingLiteLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    copywritingLiteLi5: {
        id: 'services.copywritingLiteLi5',
        defaultMessage: 'Basic external promotion'
    },
    //STANDART
    copywritingStandartLi1: {
        id: 'services.copywritingStandartLi1',
        defaultMessage: 'from 500$'
    },
    copywritingStandartLi2: {
        id: 'services.copywritingStandartLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    copywritingStandartLi3: {
        id: 'services.copywritingStandartLi3',
        defaultMessage: 'Standard Functionality'
    },
    copywritingStandartLi4: {
        id: 'services.copywritingStandartLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    copywritingStandartLi5: {
        id: 'services.copywritingStandartLi5',
        defaultMessage: 'Basic external promotion'
    },
    //PRO
    copywritingProLi1: {
        id: 'services.copywritingProLi1',
        defaultMessage: 'from 500$'
    },
    copywritingProLi2: {
        id: 'services.copywritingProLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    copywritingProLi3: {
        id: 'services.copywritingProLi3',
        defaultMessage: 'Standard Functionality'
    },
    copywritingProLi4: {
        id: 'services.copywritingProLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    copywritingProLi5: {
        id: 'services.copywritingProLi5',
        defaultMessage: 'Basic external promotion'
    },
    //ADMINISTRATION
    //LITE
    administrationLiteLi1: {
        id: 'services.administrationLiteLi1',
        defaultMessage: 'from 500$'
    },
    administrationLiteLi2: {
        id: 'services.administrationLiteLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    administrationLiteLi3: {
        id: 'services.administrationLiteLi3',
        defaultMessage: 'Standard Functionality'
    },
    administrationLiteLi4: {
        id: 'services.administrationLiteLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    administrationLiteLi5: {
        id: 'services.administrationLiteLi5',
        defaultMessage: 'Basic external promotion'
    },
    //STANDART
    administrationStandartLi1: {
        id: 'services.administrationStandartLi1',
        defaultMessage: 'from 500$'
    },
    administrationStandartLi2: {
        id: 'services.administrationStandartLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    administrationStandartLi3: {
        id: 'services.administrationStandartLi3',
        defaultMessage: 'Standard Functionality'
    },
    administrationStandartLi4: {
        id: 'services.administrationStandartLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    administrationStandartLi5: {
        id: 'services.administrationStandartLi5',
        defaultMessage: 'Basic external promotion'
    },
    //PRO
    administrationProLi1: {
        id: 'services.administrationProLi1',
        defaultMessage: 'from 500$'
    },
    administrationProLi2: {
        id: 'services.administrationProLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    administrationProLi3: {
        id: 'services.administrationProLi3',
        defaultMessage: 'Standard Functionality'
    },
    administrationProLi4: {
        id: 'services.administrationProLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    administrationProLi5: {
        id: 'services.administrationProLi5',
        defaultMessage: 'Basic external promotion'
    },
    //WEBSITE HOSTING
    //LITE
    websiteHostingLiteLi1: {
        id: 'services.websiteHostingLiteLi1',
        defaultMessage: 'from 500$'
    },
    websiteHostingLiteLi2: {
        id: 'services.websiteHostingLiteLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    websiteHostingLiteLi3: {
        id: 'services.websiteHostingLiteLi3',
        defaultMessage: 'Standard Functionality'
    },
    websiteHostingLiteLi4: {
        id: 'services.websiteHostingLiteLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    websiteHostingLiteLi5: {
        id: 'services.websiteHostingLiteLi5',
        defaultMessage: 'Basic external promotion'
    },
    //STANDART
    websiteHostingStandartLi1: {
        id: 'services.websiteHostingStandartLi1',
        defaultMessage: 'from 500$'
    },
    websiteHostingStandartLi2: {
        id: 'services.websiteHostingStandartLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    websiteHostingStandartLi3: {
        id: 'services.websiteHostingStandartLi3',
        defaultMessage: 'Standard Functionality'
    },
    websiteHostingStandartLi4: {
        id: 'services.websiteHostingStandartLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    websiteHostingStandartLi5: {
        id: 'services.websiteHostingStandartLi5',
        defaultMessage: 'Basic external promotion'
    },
    //PRO
    websiteHostingProLi1: {
        id: 'services.websiteHostingProLi1',
        defaultMessage: 'from 500$'
    },
    websiteHostingProLi2: {
        id: 'services.websiteHostingProLi2',
        defaultMessage: 'Basic audit and analysis of competitors'
    },
    websiteHostingProLi3: {
        id: 'services.websiteHostingProLi3',
        defaultMessage: 'Standard Functionality'
    },
    websiteHostingProLi4: {
        id: 'services.websiteHostingProLi4',
        defaultMessage: 'Basic Search Engine Optimization'
    },
    websiteHostingProLi5: {
        id: 'services.websiteHostingProLi5',
        defaultMessage: 'Basic external promotion'
    },

  //++++++++++++++++++++++++++++++++++
  // SUPPORT END
  //++++++++++++++++++++++++++++++++++
});


class Tabs extends React.Component {
  constructor() {
    super();

    this.state = {
      activeTab: 1,
      // data: this.props.data
    }
    this.changeTabOnClick = this.changeTabOnClick.bind(this);
  }

  changeTabOnClick(index) {
    this.setState({
      activeTab: index
    });
  }

  render() {
    return (
      <div className={s['tabs-body']}>
        <TabHeader data={this.props.services}
                   click={this.changeTabOnClick}
                   activeId={this.state.activeTab}/>
        <div>
          <TabContent
            data={this.props.services}
            FORM={this.props.FORM}
            activeId={this.state.activeTab}
            parrent={this.props.parrent}
          />
        </div>
      </div>
    )
  }
}

class TabHeader extends React.Component {

  doClick(index, event) {
    this.props.click(index);
  }

  render() {
    let activeClass = this.props.activeId;

    let tabs = this.props.data.map((item, index) => {
      return (
        <a key={index} id={item.id} className={s['nav__item'] + " " + (activeClass === index ? [s['active']] : '')}
           onClick={this.doClick.bind(this, index)}><span
          className={s['nav__item-title'] + " " + ['nav__item-title']}><span>{item.name}</span></span></a>
      )
    });

    return (
      <nav className={s['nav'] + " " + s['nav--meklit'] + " " + ['nav--meklit'] + " " + ['nav']}>{tabs}</nav>
    )
  }

}

class TabContent extends React.Component {
  constructor(props) {
    super(props);
    this.handelCheckbox = this.handelCheckbox.bind(this);
    this.handelThemChang = this.handelThemChang.bind(this);
    this.handelNameChang = this.handelNameChang.bind(this);
    this.handelPhoneChang = this.handelPhoneChang.bind(this);
    this.handelEmailChang = this.handelEmailChang.bind(this);
    this.handelTextChang = this.handelTextChang.bind(this);
    this.handelFileChang = this.handelFileChang.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      checkboxGroup: {
        vebSiteDevelopment: false,
        design: false,
        marketing: false,
        support: false
      },
      them: <FormattedMessage {...messages[this.props.parrent]} />,
      name: '',
      phone: '',
      email: '',
      text: '',
      file: '',
      result: ''
    };

  }

  handelCheckbox(event) {
    let object = Object.assign(this.state.checkboxGroup)
    object[event.target.value] = event.target.value
    this.setState({checkboxGroup: object})
  };

  handleClickVebSiteDevelopment = event => {
    this.setState({vebSiteDevelopment: event.target.checked})
    let object = Object.assign(this.state.checkboxGroup)
    object[event.target.value] = event.target.checked
    this.setState({checkboxGroup: object})
  };
  handleClickDesign = event => {
    this.setState({design: event.target.checked})
    let object = Object.assign(this.state.checkboxGroup)
    object[event.target.value] = event.target.checked
    this.setState({checkboxGroup: object})
  };
  handleClickMarketing = event => {
    this.setState({marketing: event.target.checked})
    let object = Object.assign(this.state.checkboxGroup)
    object[event.target.value] = event.target.checked
    this.setState({checkboxGroup: object})
  };
  handleClickSupport = event => {
    this.setState({support: event.target.checked})
    let object = Object.assign(this.state.checkboxGroup)
    object[event.target.value] = event.target.checked
    this.setState({checkboxGroup: object})
  };

  handelThemChang() {
    this.setState({them: this.themInput.value})
  }

  handelNameChang(event) {
    this.setState({name: event.target.value})
  }

  handelPhoneChang(event) {
    this.setState({phone: event.target.value})
  }

  handelEmailChang(event) {
    this.setState({email: event.target.value})
  }

  handelTextChang(event) {
    this.setState({text: event.target.value})
  }

  handelFileChang(event) {
    this.setState({file: event.target.value})
  }


    handleSubmit(e){
        e.preventDefault();
        this.setState({
            them: '',
            name: '',
            phone: '',
            email: '',
            text: '',
            file: '',
            result: ''
        });
        axios({
            method: "POST",
            url:"/form-services",
            data: {
                them: this.state.them,
                name: this.state.name,
                phone: this.state.phone,
                email: this.state.email,
                text: this.state.text,
                file: this.state.file
            }
        }).then((response)=>{
            if (response.data.msg === 'success'){
                this.setState({result: <FormattedMessage {...messages.headerSuccess2} />})
            }else if(response.data.msg === 'fail'){
                console.log("success");
                this.setState({result: <FormattedMessage {...messages.headerErr} />})
            }
        })
    }

  render() {
    let activeClass = this.props.activeId;
    let FORM = this.props.FORM;
    let content = this.props.data.map((item, index) => {
      return (
        <div key={index}>
          {(activeClass === index ?
            <div className={s['tabs-textItem'] + " " + s[(activeClass === index ? 'show' : '')]}>
              <div className={s['description']}>
                <h2 className={s['titleTab']}>{item.name}</h2>
                <div className={form.content}>
                  <ul>
                    <li><span><FormattedMessage {...messages[item.li1]} /></span></li>
                    <li><FormattedMessage {...messages[item.li2]} /></li>
                    <li><FormattedMessage {...messages[item.li3]} /></li>
                    <li><FormattedMessage {...messages[item.li4]} /></li>
                    <li><FormattedMessage {...messages[item.li5]} /></li>
                  </ul>
                </div>
              </div>
              <div className={s['formModal']}>
                <h3 style={{textAlign: 'center'}}>
                  <FormattedMessage {...messages.orderText} />&nbsp;
                  <FormattedMessage {...messages[this.props.parrent]} />&nbsp;
                  <span style={{textTransform: 'uppercase'}}>
                                {item.name}
                              </span>
                </h3>
                <div className={s['formBlock']}>
                  <form onSubmit={this.handleSubmit} method="POST" className={s.form + " " + f.row} id={['messageForm']}>
                    <div className={s['columns']}>
                      <Input
                        type={'text'}
                        inputId={'hiddenInput'}
                        inputClass={'hiddenInput'}
                        value={<FormattedMessage {...messages[this.props.parrent]} /> + " " + item.name}
                        // onChange={this.handelThemChang}
                        inputRef={(ref) => {this.themInput = ref}}
                      />
                      <Input
                        type={'text'}
                        inputId={'userNameFooter'}
                        inputText={<FormattedMessage {...messages[FORM.name]} />}
                        value={this.state.name}
                        onChange={this.handelNameChang}
                      />
                      <Input
                        type={'text'}
                        inputId={'userPhoneFooter'}
                        inputText={<FormattedMessage {...messages[FORM.phone]} />}
                        value={this.state.phone}
                        onChange={this.handelPhoneChang}
                      />
                      <Input
                        type={'text'}
                        inputId={'userMailFooter'}
                        inputText={<FormattedMessage {...messages[FORM.email]} />}
                        value={this.state.email}
                        onChange={this.handelEmailChang}
                      />
                      <Textarea
                        type={'text'}
                        textareaId={'userMessageFooter'}
                        inputText={<FormattedMessage {...messages[FORM.textarea]} />}
                        viewBox={'0 7 1200 54.4'}
                        textareaClass={s.inputTextarea}
                        value={this.state.text}
                        onChange={this.handelTextChang}
                      />
                    </div>
                    <div className={f['large-7'] + " " + f['columns'] + " " + s['columns']}>
                    </div>
                    <div className={s['form-group'] + " " + f['large-8'] + " " + f['columns'] + " " + s['columns']}>
                      <label className={s.label}>
                                                        <span className={s.add}>
                                                            <em className={s.left}>
                                                                <span className={s.line}></span>
                                                            </em>
                                                            <em className={s.right}>
                                                                <span className={s.line}></span>
                                                            </em>
                                                        </span>
                        <p className={s.title}>
                          <span className={s.titleHov}>{<FormattedMessage {...messages[FORM.addFile]} />} {(this.state.file == '' ? "" : <em
                              className={icon['ion-document-text'] + " " + s.fileTrue}><span>{this.state.file.split("/").pop().split(".").pop()}</span></em>)}</span>
                        </p>
                        <p>{this.state.file.replace(/^.*[\\\/]/, '')}</p>
                        <input
                          type='file'
                          value={this.state.file}
                          onChange={this.handelFileChang}
                        />
                      </label>
                    </div>
                    <div className={s['form-group'] + " " + f['large-4'] + " " + f['columns'] + " " + s['columns']}>
                      <button onClick={this.handelThemChang} className={s.buttonSubscribe} id={'sendFooter'}
                              type="submit" value="Submit">
                        <span className={s.title}>{<FormattedMessage {...messages[FORM.button]} />}</span>
                      </button>
                    </div>
                    <div id={s.loadBarFooter}>{this.state.result}</div>
                  </form>
                </div>
              </div>
            </div> : '')
          }
        </div>
      )
    });

    return (
      <div className={s['tabs-content']}>{content}</div>
    );
  }
}

export default injectIntl(
  compose(
    withStyles(s, icon, f, form),
    connect(
      state => ({
        FORM: state.dataRu.TABSSERVICES
      }),
      dispatch => ({})
    )
  )(Tabs));
