import React, { Component } from 'react';
import anim from './animate.css';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

class WebsiteDevelopment extends React.Component {
    render() {
        return (
                <div className={anim["svg-width"]}>
                <svg className={anim["icon-develop"]} viewBox="0 0 359.4 338.2">
                    <linearGradient id="XMLID_143_x" gradientUnits="userSpaceOnUse" x1="215.598"
                                    y1="126.746" x2="110.526" y2="333.738">
                        <stop offset="0.19" stopColor="#a184f9" />
                        <stop offset="1" stopColor="#fa5da3" />
                    </linearGradient>
                    <path id="XMLID_108_" fill="url(#XMLID_143_x)" d="M357.3,182.3c0,120.4-102.9,157.6-193.3,153.5C101.6,333-15.5,259.1,4.5,172.1 c8.9-38.7,41.1-54.6,71-70.1c50.7-26.3,103.6-81.2,147.9-95.1C303.5-18.1,357.3,64.4,357.3,182.3z"
                    />
                    <linearGradient id="XMLID_144_x" gradientUnits="userSpaceOnUse" x1="239.817"
                                    y1="61.896" x2="235.817" y2="95.229">
                        <stop offset="0.06" stopColor="#db6b86" />
                        <stop offset="0.086" stopColor="#dc6d87" />
                        <stop offset="1" stopColor="#f7a6a5" />
                    </linearGradient>
                    <path id="XMLID_105_" className={anim["gear-b"] + " " + anim["hover"] + " " + "origin-center"} fill="url(#XMLID_144_x)"
                          d="M274.7,102.6l3-11.6l-8.1-2.1c0.1-1.4,0.1-2.8,0-4.2l7.9-2.4L274,70.9l-7.5,2.3 c-0.7-1.6-1.6-3.1-2.6-4.6l5.4-5.4l-8.5-8.5l-5.4,5.4c-1.5-1-3-1.9-4.6-2.6l2.3-7.5l-11.5-3.5l-2.4,7.9c-1.4-0.1-2.8-0.1-4.2,0 l-2.1-8.1l-11.6,3l2,8c-1.8,0.8-3.5,1.8-5.2,2.9l-5.6-5.6l-8.5,8.5l5.6,5.6c-1.1,1.7-2.1,3.4-2.9,5.2l-7.9-2l-3,11.6l8.1,2.1 c-0.1,1.4-0.1,2.8,0,4.2l-7.9,2.4l3.5,11.5l7.5-2.3c0.7,1.6,1.6,3.1,2.6,4.6l-5.4,5.4l8.5,8.5l5.4-5.4c1.5,1,3,1.9,4.6,2.6l-2.3,7.5 l11.5,3.5l2.4-7.9c1.4,0.1,2.8,0.1,4.2,0l2.1,8.1l11.6-3l-2-8c1.8-0.8,3.5-1.8,5.2-2.9l5.6,5.6l8.5-8.5l-5.6-5.6 c1.1-1.7,2.1-3.4,2.9-5.2L274.7,102.6z M228.3,95.7c-4.7-4.7-4.7-12.3,0-17c4.7-4.7,12.3-4.7,17,0c4.7,4.7,4.7,12.3,0,17 C240.6,100.4,233,100.4,228.3,95.7z"
                    />
                    <polygon id="XMLID_104_" fill="#95235B" points="131.1,150.3 143.8,128.2 179.3,146.7 166.8,169.3"
                    />
                    <g id="XMLID_102_">
                        <polygon id="XMLID_103_" fill="#FFF" points="204.4,273.3 133.8,273.3 138.6,251.8 199.5,251.8"
                        />
                    </g>
                    <polygon id="XMLID_101_" fill="#D3D5DB" points="209.1,279.3 129.1,279.3 133.8,273.3 204.4,273.3"
                    />
                    <linearGradient id="XMLID_145_x" gradientUnits="userSpaceOnUse" x1="129.064"
                                    y1="276.319" x2="209.093" y2="276.319">
                        <stop offset="0.06" stopColor="#db6b86" />
                        <stop offset="0.086" stopColor="#dc6d87" />
                        <stop offset="1" stopColor="#f7a6a5" />
                    </linearGradient>
                    <polygon id="XMLID_100_" fill="url(#XMLID_145_x)" points="209.1,279.3 129.1,279.3 133.8,273.3 204.4,273.3"
                    />
                    <rect id="XMLID_99_" x="128.7" y="279.3" fill="#FFF" width="80" height="1.5"
                    />
                    <g id="XMLID_96_">
                        <path id="XMLID_10_" fill="#582768" d="M274.9,234.3V108.6c0-5.2-4.3-9.5-9.5-9.5H71.9c-5.2,0-9.5,4.3-9.5,9.5v125.7H274.9z"
                        />
                    </g>
                    <rect id="XMLID_95_" x="72.1" y="107.5" fill="#F7EDF5" width="193.7" height="116.3"
                    />
                    <path id="XMLID_94_" fill="#582768" d="M62.4,232.4v10.5c0,5.2,4.3,9.5,9.5,9.5h193.5c5.2,0,9.5-4.3,9.5-9.5v-10.5H62.4z"
                    />
                    <rect id="XMLID_93_" x="62.4" y="232.4" fill="#FFF" width="212.5" height="1.1"
                    />
                    <g id="XMLID_91_">
                        <rect id="XMLID_92_" x="76.6" y="118.1" fill="#95235B" width="181.3" height="73.3"
                        />
                    </g>
                    <g id="XMLID_89_">
                        <linearGradient id="XMLID_146_x" gradientUnits="userSpaceOnUse" x1="219.106"
                                        y1="165.827" x2="122.106" y2="183.827">
                            <stop offset="0.09" stopColor="#a62257" />
                            <stop offset="0.454" stopColor="#86245e" />
                            <stop offset="1" stopColor="#582768" />
                        </linearGradient>
                        <polygon id="XMLID_90_" fill="url(#XMLID_146_x)" points="257.8,146.9 232.8,153.6 206.8,140.8 180.7,162.4 154.7,173.4 128.7,150.3 102.6,159.6 76.6,133.7 76.6,217.2 257.8,217.2"
                        />
                    </g>
                    <g id="XMLID_87_" className={anim["hover"] + " " + "origin-center"}>
                        <path id="XMLID_88_" fill="#FFF" d="M105.7,159.6c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2 C104.3,156.4,105.7,157.9,105.7,159.6z"
                        />
                        <path id="XMLID_76_" fill="#EE8C97" d="M102.5,163.5c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9 C106.4,161.8,104.7,163.5,102.5,163.5z M102.5,157.2c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5 C105,158.3,103.9,157.2,102.5,157.2z"
                        />
                    </g>
                    <g id="XMLID_85_" className={anim["hover"] + " " + "origin-center"}>
                        <path id="XMLID_86_" fill="#FFF" d="M131.5,150.6c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2 C130.1,147.4,131.5,148.8,131.5,150.6z"
                        />
                        <path id="XMLID_73_" fill="#EE8C97" d="M128.4,154.5c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9 C132.2,152.7,130.5,154.5,128.4,154.5z M128.4,148.1c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5 S129.7,148.1,128.4,148.1z"
                        />
                    </g>
                    <g id="XMLID_83_" className={anim["hover"] + "" + "origin-center"}>
                        <circle id="XMLID_84_" fill="#FFF" cx="154.6" cy="173.6" r="3.2" />
                        <path id="XMLID_70_" fill="#EE8C97" d="M154.6,177.5c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9 C158.4,175.7,156.7,177.5,154.6,177.5z M154.6,171.1c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5 S155.9,171.1,154.6,171.1z"
                        />
                    </g>
                    <g id="XMLID_81_">
                        <circle id="XMLID_82_" fill="#FFF" cx="180.9" cy="162.3" r="3.2" />
                    </g>
                    <path id="XMLID_80_" fill="#FFF" d="M210,141.1c0,1.7-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2 C208.6,137.9,210,139.3,210,141.1z"
                    />
                    <path id="XMLID_79_" fill="#FFF" d="M236.1,153.7c0,1.8-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2 C234.7,150.6,236.1,152,236.1,153.7z"
                    />
                    <g id="XMLID_60_" fill="#EE8C97">
                        <path id="XMLID_67_" d="M180.9,166.2c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9 C184.8,164.5,183,166.2,180.9,166.2z M180.9,159.9c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5 C183.3,161,182.2,159.9,180.9,159.9z"
                        />
                        <path id="XMLID_64_" d="M206.8,144.9c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9 C210.7,143.2,209,144.9,206.8,144.9z M206.8,138.6c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5 C209.3,139.7,208.2,138.6,206.8,138.6z"
                        />
                        <path id="XMLID_61_" d="M232.9,157.6c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9 C236.8,155.9,235,157.6,232.9,157.6z M232.9,151.3c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5 C235.4,152.4,234.3,151.3,232.9,151.3z"
                        />
                    </g>
                    <path id="XMLID_59_" className={anim["star"] + " " + anim["star-1"] + " " + "origin-center"} fill="#FFF"
                          d="M156.5,64.7v3h-3c-1,0-1.9,0.8-1.9,1.9v0.1c0,1,0.8,1.9,1.9,1.9h3v3c0,1,0.8,1.9,1.9,1.9h0.1 c1,0,1.9-0.8,1.9-1.9v-3h3c1,0,1.9-0.8,1.9-1.9v-0.1c0-1-0.8-1.9-1.9-1.9h-3v-3c0-1-0.8-1.9-1.9-1.9h-0.1 C157.4,62.8,156.5,63.6,156.5,64.7z"
                    />
                    <path id="XMLID_58_" className={anim["star"] + " " + anim["star-2"] + " " + "origin-center"} fill="#FFF"
                          d="M96.6,271.8v2.8h-2.8c-1,0-1.7,0.8-1.7,1.7v0.1c0,1,0.8,1.7,1.7,1.7h2.8v2.8 c0,1,0.8,1.7,1.7,1.7h0.1c1,0,1.7-0.8,1.7-1.7v-2.8h2.8c1,0,1.7-0.8,1.7-1.7v-0.1c0-1-0.8-1.7-1.7-1.7h-2.8v-2.8 c0-1-0.8-1.7-1.7-1.7h-0.1C97.4,270,96.6,270.8,96.6,271.8z"
                    />
                    <circle id="XMLID_57_" fill="#FFF" cx="277.7" cy="267.9" r="3.4" />
                    <g id="XMLID_52_">
                        <path id="XMLID_56_" fill="#FFF" d="M185.3,108.2c0,1.8-1.5,3.3-3.3,3.3l0,0c-1.8,0-3.3-1.5-3.3-3.3l0,0c0-1.8,1.5-3.3,3.3-3.3l0,0 C183.8,104.9,185.3,106.3,185.3,108.2L185.3,108.2z"
                        />
                        <path id="XMLID_53_" fill="#B3BAC2" d="M182,105.8c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4 C179.6,106.8,180.6,105.8,182,105.8 M182,104.9c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3 C185.3,106.3,183.8,104.9,182,104.9L182,104.9z"
                        />
                    </g>
                    <linearGradient id="XMLID_147_x" gradientUnits="userSpaceOnUse" x1="195.864"
                                    y1="180.548" x2="240.427" y2="180.548">
                        <stop offset="0.06" stopColor="#db6b86" />
                        <stop offset="0.086" stopColor="#dc6d87" />
                        <stop offset="1" stopColor="#f7a6a5" />
                    </linearGradient>
                    <path id="XMLID_49_" className={anim["gear-s"] + " " + "origin-center"} fill="url(#XMLID_147_x)"
                          d="M196.5,187.5l3.7-1c0.8,2.3,2,4.6,3.7,6.5l-2.7,2.7l0,0l1.7,1.7l0,0l2.7-2.7 c2,1.7,4.2,3,6.5,3.7l-1,3.7l2.4,0.6l1-3.7c2.4,0.5,4.8,0.5,7.2,0l1,3.7l2.4-0.6l-1-3.7c2.3-0.8,4.6-2,6.5-3.7l2.7,2.7l1.7-1.7 l-2.7-2.7c1.7-1.9,3-4.2,3.7-6.5l3.7,1l0.6-2.4l-3.7-1c0.5-2.4,0.5-4.8,0-7.2l3.7-1l-0.6-2.4l-3.7,1c-0.8-2.4-2-4.6-3.7-6.5l2.7-2.7 l0,0l-1.7-1.7l0,0l-2.7,2.7c-2-1.7-4.2-3-6.5-3.7l1-3.7l-2.3-0.6l-1,3.7c-2.4-0.5-4.8-0.5-7.2,0l-1-3.7l-2.3,0.6l1,3.7 c-2.3,0.8-4.6,2-6.5,3.8l-2.7-2.7l-1.7,1.7l2.7,2.7c-1.7,2-3,4.2-3.8,6.5l-3.7-1l-0.6,2.3l3.7,1c-0.5,2.4-0.5,4.8,0,7.2l-3.7,1 L196.5,187.5z M209.2,171.6c4.9-5,13-5,17.9,0c4.9,4.9,4.9,13,0,17.9c-5,4.9-13,4.9-17.9,0C204.2,184.6,204.2,176.5,209.2,171.6z"
                    />
                    <linearGradient id="XMLID_148_x" gradientUnits="userSpaceOnUse" x1="280.25"
                                    y1="206.694" x2="318.287" y2="206.694">
                        <stop offset="0.09" stopColor="#a62257" />
                        <stop offset="0.454" stopColor="#86245e" />
                        <stop offset="1" stopColor="#582768" />
                    </linearGradient>
                    <path id="XMLID_48_" fill="url(#XMLID_148_x)" d="M314.7,205.8c0.2-0.8,0.4-1.7,0.4-2.6c0-5.5-4.5-10-10-10c-3.9,0-7.3,2.2-8.9,5.5 c-0.9-0.6-2-0.9-3.2-0.9c-3.4,0-6.2,2.8-6.2,6.2c0,0.3,0,0.5,0,0.8c-3.6,0.7-6.4,3.8-6.4,7.7c0,1.4,0.4,2.8,1,3.9 c1.3,2.3,3.9,3.9,6.8,3.9h9.1v-7h-1.7c-1.3,0-1.9-0.6-1.2-1.3l1.1-1.1c0.7-0.7,1.7-1.9,2.4-2.6l1.1-1.1c0.7-0.7,1.7-0.7,2.4,0l1,1.1 c0.7,0.7,1.7,1.9,2.4,2.6l1,1.1c0.7,0.7,0.1,1.3-1.2,1.3h-1.7v7h7.6l0,0c2.9,0,5.4-1.6,6.8-3.9c0.7-1.2,1.1-2.5,1.1-3.9 C318.3,209.6,316.8,207.2,314.7,205.8z"
                    />
                    <linearGradient id="XMLID_149_x" gradientUnits="userSpaceOnUse" x1="23.98"
                                    y1="205.087" x2="115.436" y2="205.087">
                        <stop offset="0.06" stopColor="#db6b86" />
                        <stop offset="0.086" stopColor="#dc6d87" />
                        <stop offset="1" stopColor="#f7a6a5" />
                    </linearGradient>
                    <path id="XMLID_47_" fill="url(#XMLID_149_x)" d="M24,170v66.4c0,2.1,1.7,3.8,3.8,3.8h83.9c2.1,0,3.8-1.7,3.8-3.8V170H24z"
                    />
                    <path id="XMLID_46_" fill="#582768" d="M111.7,160.4H27.8c-2.1,0-3.8,1.7-3.8,3.8v5.8h91.5v-5.8C115.4,162.1,113.7,160.4,111.7,160.4z"
                    />
                    <path id="XMLID_45_" fill="#FFF" d="M41.7,166.9L41.7,166.9c-1,0-1.7-0.8-1.7-1.8l0,0c0-1,0.8-1.7,1.7-1.7l0,0c1,0,1.7,0.8,1.7,1.7 l0,0C43.5,166.2,42.7,166.9,41.7,166.9z"
                    />
                    <path id="XMLID_44_" fill="#FFF" d="M36.2,166.9L36.2,166.9c-1,0-1.8-0.8-1.8-1.8l0,0c0-1,0.8-1.7,1.8-1.7l0,0c1,0,1.7,0.8,1.7,1.7 l0,0C37.9,166.2,37.1,166.9,36.2,166.9z"
                    />
                    <path id="XMLID_43_" fill="#FFF" d="M30.2,166.9L30.2,166.9c-1,0-1.8-0.8-1.8-1.8l0,0c0-1,0.8-1.7,1.8-1.7l0,0c1,0,1.7,0.8,1.7,1.7 l0,0C32,166.2,31.2,166.9,30.2,166.9z"
                    />
                    <g id="XMLID_27_">
                        <path id="XMLID_41_" fill="#FFF" d="M40,209.6c-1.1,0-2.2-0.9-2.2-2.4v-3.7c0-0.7-0.3-1.2-0.9-1.2v-0.9c0.6,0,0.9-0.6,0.9-1.2v-3.7 c0-1.5,1.1-2.4,2.2-2.4h1.1v1H40c-0.6,0-1.2,0.6-1.2,1.4v3.8c0,0.8-0.2,1.3-0.8,1.6c0.6,0.3,0.8,0.8,0.8,1.6v3.8 c0,0.8,0.5,1.4,1.2,1.4h1.1v1H40z"
                        />
                        <path className={anim["code_bracket"]} fill="#FFF" d="M98.4,208.6h1.1c0.6,0,1.2-0.6,1.2-1.4v-3.8c0-0.8,0.2-1.3,0.8-1.6c-0.6-0.2-0.8-0.8-0.8-1.6 v-3.8c0-0.8-0.5-1.4-1.2-1.4h-1.1v-1h1.1c1.1,0,2.2,0.9,2.2,2.4v3.7c0,0.7,0.3,1.2,0.9,1.2v0.9c-0.6,0-0.9,0.6-0.9,1.2v3.7 c0,1.5-1.1,2.4-2.2,2.4h-1.1V208.6z"
                        />
                        <path className={anim["code-letter"] + " " + anim["code-letter_c"]} fill="#FFF" d="M43.4,201.8c0-3.8,2.8-6.5,6.4-6.5c2.2,0,3.7,1.1,4.7,2.5l-1.3,0.7c-0.7-1-2-1.8-3.4-1.8 c-2.7,0-4.8,2.1-4.8,5.1c0,3,2.1,5.1,4.8,5.1c1.4,0,2.7-0.8,3.4-1.8l1.3,0.7c-1,1.4-2.5,2.5-4.7,2.5 C46.2,208.3,43.4,205.6,43.4,201.8z"
                        />
                        <path className={anim["code-letter"] + " " + anim["hover"] + " " + anim["code-letter_o"]} fill="#FFF" d="M56,201.8c0-3.7,2.5-6.5,6.2-6.5c3.7,0,6.2,2.8,6.2,6.5c0,3.7-2.5,6.5-6.2,6.5 C58.5,208.3,56,205.5,56,201.8z M66.8,201.8c0-2.9-1.8-5.1-4.6-5.1c-2.8,0-4.6,2.2-4.6,5.1c0,2.9,1.8,5.1,4.6,5.1 C65,206.9,66.8,204.7,66.8,201.8z"
                        />
                        <path className={anim["code-letter"] + " " + anim["hover"] + " " + anim["code-letter_d"]} fill="#FFF" d="M70.8,208.1v-12.5h4.3c3.9,0,6.4,2.7,6.4,6.3c0,3.6-2.5,6.2-6.4,6.2H70.8z M79.9,201.8 c0-2.7-1.7-4.9-4.8-4.9h-2.7v9.7h2.7C78.1,206.7,79.9,204.5,79.9,201.8z"
                        />
                        <path className={anim["code-letter"] + " " + anim["hover"] + " " + anim["code-letter_e"]} fill="#FFF" d="M83.9,208.1v-12.5h8.2v1.4h-6.6v4H92v1.4h-6.5v4.3h6.6v1.4H83.9z"
                        />
                    </g>
                    <g id="XMLID_14_">
                        <g id="XMLID_24_">
                            <path id="XMLID_8_" fill="#FFF" d="M332.7,174.1c0,1.9-1.5,3.4-3.4,3.4H170.5c-1.9,0-3.4-1.5-3.4-3.4V95.8h165.6V174.1z"
                            />
                        </g>
                        <linearGradient id="XMLID_150_x" gradientUnits="userSpaceOnUse" x1="167.097"
                                        y1="91.491" x2="332.669" y2="91.491">
                            <stop offset="0.06" stopColor="#db6b86" />
                            <stop offset="0.086" stopColor="#dc6d87" />
                            <stop offset="1" stopColor="#f7a6a5" />
                        </linearGradient>
                        <path id="XMLID_23_" fill="url(#XMLID_150_x)" d="M329.3,87.2H170.5c-1.9,0-3.4,1.5-3.4,3.4v5.2h165.6v-5.2C332.7,88.7,331.1,87.2,329.3,87.2z"
                        />
                        <path id="XMLID_22_" fill="#FFF" d="M181.3,93.4L181.3,93.4c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6l0,0 c0.9,0,1.6,0.7,1.6,1.6l0,0C182.9,92.7,182.2,93.4,181.3,93.4z"
                        />
                        <path id="XMLID_21_" fill="#FFF" d="M176.3,93.4L176.3,93.4c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6l0,0 c0.9,0,1.6,0.7,1.6,1.6l0,0C177.9,92.7,177.2,93.4,176.3,93.4z"
                        />
                        <path id="XMLID_20_" fill="#FFF" d="M171,93.4L171,93.4c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6l0,0 c0.9,0,1.6,0.7,1.6,1.6l0,0C172.6,92.7,171.9,93.4,171,93.4z"
                        />
                        <g id="XMLID_15_">
                            <g id="XMLID_17_">
                                <rect id="XMLID_19_" className={anim["line-b"] + " " + anim["hover"] + " " + "origin-left"} x="185.3" y="143.4"
                                      fill="#F6A0A2" width="38.3" height="3.3" />
                                <rect id="XMLID_18_" className={anim["line-m"] + " " + anim["hover"] + " " + "origin-left"} x="185.3" y="133.1"
                                      fill="#F6A0A2" width="72.1" height="3.2" />
                            </g>
                            <rect id="XMLID_16_" className={anim["line-t"] + " " + anim["hover"] + " " + "origin-left"} x="185.3" y="122.6"
                                  fill="#F6A0A2" width="72.1" height="3.2" />
                        </g>
                    </g>
                    <g id="XMLID_3_" fill="#A52257">
                        <path id="XMLID_11_" d="M305,118.4c-0.1-0.2-0.4-0.2-0.5,0l-6.8,11.8h4.3c0.2,0,0.3,0.1,0.3,0.3v23.6 c0,0.2,0.1,0.3,0.3,0.3h4.3c0.2,0,0.3-0.2,0.3-0.3v-23.6c0-0.2,0.1-0.3,0.3-0.3h4.3L305,118.4z"
                        />
                        <path id="XMLID_4_" d="M287.2,154.3c0.1,0.2,0.4,0.2,0.5,0l6.8-11.8h-4.3c-0.2,0-0.3-0.1-0.3-0.3v-23.6 c0-0.2-0.2-0.3-0.3-0.3h-4.3c-0.2,0-0.3,0.1-0.3,0.3v23.6c0,0.2-0.1,0.3-0.3,0.3h-4.3L287.2,154.3z"
                        />
                    </g>
                </svg>
            </div>
        );
    }
}

export default injectIntl(compose(withStyles(anim))(WebsiteDevelopment));