import React, { Component } from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "../Button.css";



class ButtonFill extends Component {
    render() {
        return (
            <a href={this.props.href} type={this.props.type} value={this.props.value} id={[this.props.buttonId]} className={s['button'] + " " + [this.props.buttonClass]}>
                <span className={s['button__text'] + " " + [this.props.buttonTextClass]}>{this.props.buttonText}</span>
            </a>
        );
    }
}

export default withStyles(s)(ButtonFill);