import React, { Component } from 'react';
import { connect } from 'react-redux';
import anim from './animate.css';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

class WebSupport extends React.Component {
    render() {
        return (
            <div className={anim["svg-width"]}>
                <svg className={anim["icon-support"]} viewBox="0 0 297.8 338.3">
                    <g id="XMLID_2_">
                        <linearGradient id="XMLID_152_ccc" gradientUnits="userSpaceOnUse" x1="306.8558" y1="121.1685" x2="473.9469" y2="288.2596" gradientTransform="matrix(1.2556 -8.690000e-002 -8.690000e-002 1.3971 -298.7608 -66.8389)">
                            <stop offset="0.1902" style={{stopColor: '#C58EBB'}} />
                            <stop offset={1} style={{stopColor: '#FB6610'}} />
                        </linearGradient>
                        <path id="XMLID_103_" fill="url(#XMLID_152_ccc)" d="M101.1,289.7c-34.4-24.1-93.1-10-98.9-63.8C-3.6,171.5,43.5,110,79,72.7
              c82.9-87,156.4-99.9,206.4,1.3c9.7,19.6,18,53.7,1.4,82.8c-9.7,16.9-36.5,21.1-40.2,41.2c-6.8,36.3,34.5,60.9,11.2,107.9
              C223.2,375.4,129.7,309.7,101.1,289.7z" />
                        <linearGradient id="XMLID_153_cc" gradientUnits="userSpaceOnUse" x1="49.8059" y1="126.9058" x2="168.7503" y2="126.9058">
                            <stop offset="6.016400e-002" style={{stopColor: '#DB6B86'}} />
                            <stop offset="8.596202e-002" style={{stopColor: '#DC6D87'}} />
                            <stop offset={1} style={{stopColor: '#F7A6A5'}} />
                        </linearGradient>
                        <path id="XMLID_100_" className={anim["gear-m"] + " " + anim["hover"] + " " + "origin-center"} fill="url(#XMLID_153_cc)" d="M168.7,119.5l-1-5.4c-0.1-0.4-0.4-0.6-0.8-0.6l-4.4,0.8c-0.9-4-2.3-7.8-4.1-11.4l4-2.3
              c0.3-0.2,0.4-0.6,0.3-0.9l-2.8-4.8c-0.2-0.3-0.6-0.4-0.9-0.3l-3.9,2.3c-2.2-3.4-4.8-6.5-7.7-9.3l2.9-3.5c0.2-0.3,0.2-0.7-0.1-1
              l-4.3-3.5c-0.3-0.2-0.7-0.2-1,0.1l-2.9,3.5c-3.2-2.4-6.7-4.5-10.5-6.1l1.5-4.3c0.1-0.4-0.1-0.8-0.4-0.9l-5.2-1.9
              c-0.4-0.1-0.8,0.1-0.9,0.4l-1.5,4.2c-3.8-1.1-7.8-1.8-11.9-2.1l0-4.6c0-0.4-0.3-0.7-0.7-0.7l-5.5,0c-0.4,0-0.7,0.3-0.7,0.7l0,4.6
              c-4.1,0.2-8.1,0.9-12,2L92.6,70c-0.1-0.4-0.5-0.5-0.9-0.4l-5.2,1.9c-0.4,0.1-0.5,0.5-0.4,0.9l1.6,4.2c-3.8,1.6-7.2,3.7-10.5,6.1
              l-3-3.5c-0.2-0.3-0.7-0.3-1-0.1l-4.2,3.6c-0.3,0.2-0.3,0.7-0.1,1l2.9,3.5c-2.9,2.8-5.6,5.8-7.9,9.2l-4-2.3
              c-0.3-0.2-0.8-0.1-0.9,0.3l-2.7,4.8c-0.2,0.3-0.1,0.8,0.3,0.9l3.9,2.2c-1.8,3.6-3.2,7.4-4.2,11.4l-4.6-0.8
              c-0.4-0.1-0.7,0.2-0.8,0.6L50,119c-0.1,0.4,0.2,0.7,0.6,0.8l4.5,0.8c-0.3,2.2-0.4,4.5-0.4,6.8c0,1.8,0.1,3.6,0.3,5.3l-4.5,0.8
              c-0.4,0.1-0.6,0.4-0.6,0.8l1,5.4c0.1,0.4,0.4,0.6,0.8,0.6l4.4-0.8c0.9,4,2.3,7.8,4.1,11.4l-4,2.3c-0.3,0.2-0.4,0.6-0.2,1l2.8,4.8
              c0.2,0.3,0.6,0.4,0.9,0.2l3.9-2.3c2.2,3.4,4.8,6.5,7.7,9.3l-2.9,3.5c-0.2,0.3-0.2,0.7,0.1,1l4.3,3.5c0.3,0.2,0.7,0.2,1-0.1l2.9-3.5
              c3.2,2.4,6.7,4.5,10.4,6.1l-1.5,4.3c-0.1,0.4,0.1,0.8,0.4,0.9l5.2,1.9c0.4,0.1,0.8-0.1,0.9-0.4l1.5-4.2c3.8,1.1,7.8,1.8,11.9,2.1
              l0,4.6c0,0.4,0.3,0.7,0.7,0.7l5.5-0.1c0.4,0,0.7-0.3,0.7-0.7l0-4.6c4.1-0.2,8.1-0.9,11.9-2l1.6,4.3c0.1,0.4,0.5,0.5,0.9,0.4
              l5.2-1.9c0.4-0.1,0.5-0.5,0.4-0.9l-1.6-4.2c3.7-1.6,7.3-3.7,10.5-6.1l3,3.5c0.2,0.3,0.7,0.3,1,0.1l4.2-3.6c0.3-0.3,0.3-0.7,0.1-1
              l-2.9-3.5c2.9-2.8,5.6-5.8,7.9-9.2l4,2.3c0.3,0.2,0.8,0.1,1-0.3l2.7-4.8c0.2-0.3,0.1-0.8-0.3-0.9l-3.9-2.2
              c1.8-3.6,3.2-7.4,4.2-11.4l4.6,0.8c0.4,0.1,0.7-0.2,0.8-0.6l0.9-5.5c0.1-0.4-0.2-0.7-0.6-0.8l-4.5-0.8c0.3-2.2,0.4-4.5,0.4-6.8
              c0-1.8-0.1-3.6-0.3-5.3l4.5-0.8C168.6,120.3,168.8,119.9,168.7,119.5z M109.4,140.5c-7.5,0.1-13.6-6-13.7-13.5
              c-0.1-7.5,6-13.6,13.5-13.7c7.5-0.1,13.6,6,13.7,13.5C122.9,134.3,116.9,140.4,109.4,140.5z" />
                        <g id="XMLID_96_">
                            <path id="XMLID_97_" fill="#7D2360" d="M109.3,157.5c-14.2,0-26.4-9.7-29.7-23.5c-1.9-7.9-0.6-16.1,3.7-23.1
                c4.3-6.9,11-11.8,18.9-13.7c2.3-0.6,4.7-0.8,7.1-0.8c14.2,0,26.4,9.7,29.7,23.5c1.9,7.9,0.6,16.1-3.7,23.1
                c-4.3,7-11,11.8-18.9,13.7C114,157.2,111.6,157.5,109.3,157.5L109.3,157.5z M109.3,98.1c-2.2,0-4.5,0.3-6.7,0.8
                c-7.5,1.8-13.8,6.4-17.8,12.9c-4,6.5-5.3,14.3-3.5,21.7c3.1,13,14.6,22.1,28,22.1c2.2,0,4.5-0.3,6.7-0.8
                c7.5-1.8,13.8-6.4,17.8-12.9c4-6.5,5.3-14.3,3.5-21.7C134.2,107.2,122.7,98.1,109.3,98.1L109.3,98.1z" />
                        </g>
                        <g id="XMLID_92_">
                            <path id="XMLID_93_" fill="#7D2360" d="M109.3,164.3c-17.4,0-32.3-11.8-36.3-28.7c-2.3-9.7-0.7-19.7,4.5-28.2
                c5.2-8.5,13.5-14.5,23.2-16.8c2.9-0.7,5.8-1,8.7-1c17.4,0,32.3,11.8,36.3,28.7c4.8,20-7.6,40.2-27.7,45
                C115.1,164,112.2,164.3,109.3,164.3L109.3,164.3z M109.3,93c-2.6,0-5.3,0.3-7.9,0.9c-8.8,2.1-16.3,7.5-21,15.2
                c-4.7,7.7-6.2,16.8-4.1,25.6c3.6,15.3,17.2,26,32.9,26c2.6,0,5.3-0.3,7.9-0.9c18.2-4.3,29.4-22.6,25.1-40.8
                C138.6,103.7,125,93,109.3,93L109.3,93z" />
                        </g>
                        <path id="XMLID_89_" className={anim["gear-s"] + " " + anim["hover"] + " " + "origin-center"} fill="#5F2566" d="M257,35.1l-3.9,0.9c-0.6-1.3-1.3-2.5-2.1-3.7l2.8-2.6c0.7-0.6,0.7-1.7,0.1-2.3l-4.6-4.8
              c-0.6-0.7-1.7-0.7-2.3-0.1l-2.9,2.7c-1.1-0.8-2.4-1.5-3.6-2.2l1.1-3.7c0.3-0.9-0.2-1.8-1.1-2.1l-6.4-1.9c-0.9-0.3-1.8,0.2-2.1,1.1
              l-1.1,3.8c-1.4-0.1-2.8-0.2-4.2-0.1l-0.9-3.7c-0.2-0.9-1.1-1.4-2-1.2l-6.5,1.5c-0.9,0.2-1.4,1.1-1.2,2l0.9,3.9
              c-1.3,0.6-2.5,1.3-3.7,2.1l-2.6-2.8c-0.6-0.7-1.7-0.7-2.4-0.1l-4.8,4.6c-0.7,0.6-0.7,1.7-0.1,2.3l2.7,2.9c-0.8,1.1-1.5,2.4-2.2,3.6
              l-3.7-1.1c-0.9-0.3-1.8,0.2-2.1,1.1l-1.9,6.4c-0.3,0.9,0.2,1.8,1.1,2.1l3.8,1.1c-0.1,1.4-0.1,2.8-0.1,4.2l-3.7,0.9
              c-0.9,0.2-1.5,1.1-1.2,2l1.5,6.5c0.2,0.9,1.1,1.5,2,1.2l3.9-0.9c0.6,1.3,1.3,2.5,2.1,3.7l-2.8,2.6c-0.7,0.6-0.7,1.7-0.1,2.3
              l4.6,4.8c0.6,0.7,1.7,0.7,2.3,0.1l2.9-2.7c1.2,0.8,2.4,1.5,3.6,2.2l-1.1,3.7c-0.3,0.9,0.2,1.8,1.1,2.1l6.4,1.9
              c0.9,0.3,1.8-0.2,2.1-1.1l1.1-3.8c1.4,0.1,2.8,0.2,4.2,0.1l0.9,3.7c0.2,0.9,1.1,1.4,2,1.2l6.5-1.5c0.9-0.2,1.4-1.1,1.2-2l-0.9-3.9
              c1.3-0.6,2.5-1.3,3.7-2l2.6,2.8c0.6,0.7,1.7,0.7,2.3,0.1l4.8-4.6c0.7-0.6,0.7-1.7,0.1-2.3l-2.7-2.9c0.8-1.1,1.5-2.4,2.2-3.6
              l3.7,1.1c0.9,0.3,1.8-0.2,2.1-1.1l1.9-6.4c0.3-0.9-0.2-1.8-1.1-2.1l-3.8-1.1c0.1-1.4,0.1-2.8,0.1-4.2l3.7-0.9
              c0.9-0.2,1.4-1.1,1.2-2l-1.5-6.5C258.8,35.4,257.9,34.9,257,35.1z M231.1,58.8c-6.3,1.5-12.6-2.4-14.1-8.7
              c-1.5-6.3,2.4-12.6,8.7-14.1c6.3-1.5,12.6,2.4,14.1,8.7C241.3,51,237.4,57.3,231.1,58.8z" />
                        <g id="XMLID_82_">
                            <linearGradient id="XMLID_154_c" gradientUnits="userSpaceOnUse" x1="121.1049" y1="291.4615" x2="185.4546" y2="179.1774">
                                <stop offset="9.016400e-002" style={{stopColor: '#A62257'}} />
                                <stop offset="0.4541" style={{stopColor: '#86245E'}} />
                                <stop offset={1} style={{stopColor: '#582768'}} />
                            </linearGradient>
                            <path id="XMLID_18_" className={anim["gear-b"] + " " + anim["hover"] + " " + "origin-center"} fill="url(#XMLID_154_c)" d="M227.6,235.6L219,233c0.3-3.1,0.3-6.3,0.1-9.5l8.4-2c2-0.5,3.2-2.5,2.8-4.5l-3.5-14.6
                c-0.5-2-2.5-3.3-4.5-2.8l-8.7,2.1c-1.4-2.9-2.9-5.7-4.6-8.3l6.3-5.9c1.5-1.4,1.6-3.8,0.1-5.3l-10.3-10.9c-1.4-1.5-3.8-1.6-5.3-0.1
                l-6.5,6.2c-2.6-1.8-5.3-3.5-8.2-4.9l2.5-8.3c0.6-2-0.5-4.1-2.5-4.7l-14.4-4.3c-2-0.6-4.1,0.5-4.7,2.5l-2.6,8.6
                c-3.1-0.3-6.3-0.3-9.5-0.1l-2-8.4c-0.5-2-2.5-3.3-4.5-2.8l-7.8,1.9l0,0l-6.8,1.6c-2,0.5-3.3,2.5-2.8,4.5l2.1,8.7
                c-2.9,1.3-5.7,2.9-8.3,4.6l-5.9-6.3c-1.4-1.5-3.8-1.6-5.3-0.1l-10.9,10.3c-1.5,1.4-1.6,3.8-0.1,5.3l6.2,6.5
                c-1.8,2.6-3.5,5.3-4.9,8.2l-8.3-2.5c-2-0.6-4.1,0.6-4.7,2.5l-4.3,14.4c-0.6,2,0.5,4.1,2.5,4.7l8.6,2.6c-0.3,3.1-0.3,6.3-0.1,9.5
                l-8.4,2c-2,0.5-3.3,2.5-2.8,4.5l3.5,14.6c0.5,2,2.5,3.3,4.5,2.8l8.7-2.1c1.3,2.9,2.9,5.7,4.6,8.3l-6.3,5.9
                c-1.5,1.4-1.6,3.8-0.1,5.3l10.3,10.9c1.4,1.5,3.8,1.6,5.3,0.1l6.5-6.2c2.6,1.8,5.3,3.5,8.2,4.9l-2.5,8.3c-0.6,2,0.5,4.1,2.5,4.7
                l14.4,4.3c2,0.6,4.1-0.5,4.7-2.5l2.6-8.6c3.1,0.3,6.3,0.3,9.5,0.1l2,8.4c0.5,2,2.5,3.3,4.5,2.8l5.2-1.2v0l9.4-2.2
                c2-0.5,3.3-2.5,2.8-4.5l-2.1-8.7c2.9-1.3,5.7-2.9,8.3-4.6l5.9,6.3c1.4,1.5,3.8,1.6,5.3,0.1l10.9-10.3c1.5-1.4,1.6-3.8,0.1-5.3
                l-6.2-6.5c1.8-2.6,3.5-5.3,4.9-8.2l8.3,2.5c2,0.6,4.1-0.5,4.7-2.5l4.3-14.4C230.7,238.3,229.6,236.2,227.6,235.6z M163.9,253.1
                c-14.2,3.4-28.4-5.4-31.8-19.6c-3.4-14.2,5.4-28.4,19.6-31.8c14.2-3.4,28.4,5.4,31.8,19.6C186.9,235.5,178.1,249.7,163.9,253.1z" />
                        </g>
                        <g id="XMLID_78_">
                            <path id="XMLID_79_" fill="#A52257" d="M72.2,192.8l-1.8,0.4c-0.4,0.1-0.9-0.1-1.2-0.5l-0.5-0.7c-0.3-0.3-0.3-0.9-0.1-1.3l1-1.6
                c0.2-0.4,0.1-0.8-0.2-1.1l-2.4-1.5c-0.4-0.2-0.9-0.1-1.1,0.3l-1,1.6c-0.2,0.4-0.7,0.6-1.2,0.5l-0.9-0.1c-0.4,0-0.9-0.4-1-0.8
                l-0.4-1.8c-0.1-0.4-0.5-0.7-0.9-0.6l-2.8,0.7c-0.4,0.1-0.7,0.5-0.6,0.9l0.4,1.8c0.1,0.4-0.1,0.9-0.5,1.2l-0.7,0.5
                c-0.3,0.3-0.9,0.3-1.3,0.1l-1.6-1c-0.4-0.2-0.8-0.1-1.1,0.3l-1.5,2.4c-0.2,0.4-0.1,0.8,0.3,1.1l1.6,1c0.4,0.2,0.6,0.8,0.5,1.2
                l-0.1,0.9c0,0.4-0.4,0.9-0.8,1l-1.8,0.4c-0.4,0.1-0.7,0.5-0.6,0.9l0.7,2.8c0.1,0.4,0.5,0.7,0.9,0.6l1.8-0.4
                c0.4-0.1,0.9,0.1,1.2,0.5l0.5,0.7c0.3,0.3,0.3,0.9,0.1,1.3l-1,1.6c-0.2,0.4-0.1,0.8,0.3,1.1l2.4,1.5c0.4,0.2,0.8,0.1,1.1-0.3
                l1-1.6c0.2-0.4,0.8-0.6,1.2-0.5l0.9,0.1c0.4,0,0.9,0.4,1,0.8l0.4,1.8c0.1,0.4,0.5,0.7,0.9,0.6l2.8-0.7c0.4-0.1,0.7-0.5,0.6-0.9
                l-0.4-1.8c-0.1-0.4,0.1-0.9,0.5-1.2l0.7-0.5c0.3-0.3,0.9-0.3,1.3-0.1l1.6,1c0.4,0.2,0.8,0.1,1.1-0.3l1.5-2.4
                c0.2-0.4,0.1-0.8-0.3-1.1l-1.6-1c-0.4-0.2-0.6-0.8-0.5-1.2l0.1-0.9c0-0.4,0.4-0.9,0.8-1l1.8-0.4c0.4-0.1,0.7-0.5,0.6-0.9l-0.7-2.8
                C73,193,72.6,192.7,72.2,192.8z M62.7,201.2c-2,0.5-4.1-0.8-4.5-2.8c-0.5-2,0.8-4.1,2.8-4.5c2-0.5,4.1,0.8,4.5,2.8
                C65.9,198.7,64.7,200.7,62.7,201.2z" />
                        </g>
                        <g id="XMLID_74_">
                            <path id="XMLID_75_" fill="#FFFFFF" d="M157.8,276.2c-22.7,0-42.1-15.4-47.4-37.5c-6.2-26.2,10-52.5,36.1-58.8
                c3.7-0.9,7.5-1.3,11.3-1.3c22.7,0,42.1,15.4,47.4,37.5c6.2,26.2-10,52.5-36.1,58.7C165.4,275.7,161.6,276.2,157.8,276.2
                L157.8,276.2z M157.8,180.4c-3.7,0-7.3,0.4-10.9,1.3c-25.2,6-40.8,31.4-34.8,56.6c5.1,21.3,23.8,36.1,45.7,36.1
                c3.7,0,7.3-0.4,10.9-1.3c25.2-6,40.8-31.4,34.8-56.6C198.4,195.3,179.7,180.4,157.8,180.4L157.8,180.4z" />
                        </g>
                        <g id="XMLID_72_">
                            <path id="XMLID_73_" fill="#FFFFFF" d="M169.1,274.8l-0.4-1.7c25.2-6,40.8-31.4,34.8-56.6c-6-25.2-31.4-40.8-56.6-34.8l-0.4-1.7
                c26.2-6.2,52.5,10,58.8,36.1C211.5,242.3,195.3,268.6,169.1,274.8L169.1,274.8z" />
                        </g>
                        <path id="XMLID_71_" fill="#5E2667" d="M111.5,136c-5,1.2-10.1-1.9-11.3-7c-1.2-5,1.9-10.1,7-11.3c5-1.2,10.1,1.9,11.3,7
              C119.6,129.8,116.5,134.8,111.5,136z" />
                        <path id="XMLID_70_" fill="#582768" d="M149,190.4l2.7,11.3c14.2-3.4,28.4,5.4,31.8,19.6c3.4,14.2-5.4,28.4-19.6,31.8l2.7,11.3
              c20.4-4.9,33.1-25.4,28.2-45.8C189.9,198.2,169.4,185.5,149,190.4z" />
                        <path id="XMLID_69_" fill="#582768" d="M149.5,190.3l2.5,11.3c-14,4-22.7,18.9-18.7,32.9c4,14,18.6,22.2,32.7,18.2l3.2,11.2
              c-20.2,5.8-42-6.6-47.8-26.8C115.7,216.8,129.3,196.1,149.5,190.3z" />
                        <g id="XMLID_65_">
                            <path id="XMLID_66_" fill="#FFFFFF" d="M228.4,64.5c-7.9,0-14.7-5.4-16.6-13.1c-2.2-9.2,3.5-18.4,12.6-20.5c1.3-0.3,2.6-0.5,4-0.5
                c7.9,0,14.7,5.4,16.6,13.1c2.2,9.1-3.5,18.4-12.6,20.5C231.1,64.3,229.8,64.5,228.4,64.5L228.4,64.5z M228.4,32.1
                c-1.2,0-2.4,0.1-3.5,0.4c-8.2,2-13.3,10.2-11.3,18.4c1.6,6.9,7.7,11.7,14.8,11.7c1.2,0,2.4-0.1,3.6-0.4c8.2-2,13.3-10.2,11.3-18.4
                C241.6,37,235.5,32.1,228.4,32.1L228.4,32.1z" />
                        </g>
                        <g id="XMLID_63_">
                            <path id="XMLID_64_" fill="#FFFFFF" d="M97.6,109c-0.3,0-0.6-0.2-0.8-0.4c-0.3-0.4-0.1-1,0.3-1.2l121.8-74.1c0.4-0.3,1-0.1,1.2,0.3
                c0.3,0.4,0.1,1-0.3,1.2L98.1,108.9C97.9,108.9,97.7,109,97.6,109L97.6,109z" />
                        </g>
                        <g id="XMLID_61_">
                            <path id="XMLID_62_" fill="#FFFFFF" d="M120.1,146.8c-0.3,0-0.5-0.1-0.7-0.4c-0.3-0.4-0.2-1,0.2-1.2l120-87c0.4-0.3,1-0.2,1.3,0.2
                c0.3,0.4,0.2,1-0.2,1.2l-120,87C120.5,146.8,120.3,146.8,120.1,146.8L120.1,146.8z" />
                        </g>
                        <linearGradient id="XMLID_155_cc" gradientUnits="userSpaceOnUse" x1="99.2893" y1="101.8832" x2="174.2109" y2="262.5436">
                            <stop offset="6.016400e-002" style={{stopColor: '#DB6B86'}} />
                            <stop offset="8.596202e-002" style={{stopColor: '#DC6D87'}} />
                            <stop offset={1} style={{stopColor: '#F7A6A5'}} />
                        </linearGradient>
                        <path id="XMLID_56_" fill="url(#XMLID_155_cc)" d="M195.7,218.4c-1.4-5.8-4-11-7.5-15.4l-57.6-84.8c-3.5-8.6-11.8-14.3-21.3-14.3
              c-1.8,0-3.6,0.2-5.4,0.6c-6,1.4-11.1,5.1-14.3,10.3c-3.2,5.2-4.2,11.4-2.8,17.4c0.1,0.4,0.2,0.7,0.3,1.1c0,0.1-0.1,0.2,0,0.3
              l33.4,105.2c0.1,0.4,0.3,0.9,0.4,1.3l0.3,0.8c0,0.1,0.1,0.1,0.1,0.1c5.6,15,20,25.2,36.4,25.2c3,0,6.1-0.4,9.1-1.1
              C187.7,260.3,200.7,239.3,195.7,218.4z M91.6,116c2.9-4.7,7.5-8,12.9-9.3c1.6-0.4,3.2-0.6,4.8-0.6c9.7,0,18,6.6,20.2,16
              c1.3,5.4,0.4,11-2.5,15.7c-2.9,4.7-7.5,8-12.9,9.3c-1.6,0.4-3.2,0.6-4.8,0.6c-9.7,0-18-6.6-20.2-16C87.8,126.3,88.7,120.7,91.6,116
              z M109.3,149.9c1.8,0,3.6-0.2,5.4-0.6c6-1.4,11.1-5.1,14.3-10.3c2.9-4.7,4-10,3.2-15.4l49.6,73c-6.6-5.1-14.9-8.1-23.8-8.1
              c-3,0-6.1,0.4-9.1,1.1c-17.9,4.3-29.9,20.4-29.9,38l-27.3-85.9C95.9,146.8,102.2,149.9,109.3,149.9z M166.4,263.5
              c-2.8,0.7-5.8,1-8.6,1c-16,0-29.9-10.1-35.1-25l-0.4-1.2c-0.2-0.8-0.5-1.5-0.7-2.3c-4.7-19.9,7.6-40,27.5-44.8c2.8-0.7,5.7-1,8.6-1
              c11.6,0,22,5.3,28.9,13.8l2.3,3.4c0.1,0.1,0.2,0.2,0.3,0.2c2.1,3.3,3.7,7,4.6,11.1C198.7,238.7,186.3,258.8,166.4,263.5z" />
                        <linearGradient id="XMLID_156_c" gradientUnits="userSpaceOnUse" x1="193.4497" y1="132.6568" x2="254.0015" y2="132.6568">
                            <stop offset="6.016400e-002" style={{stopColor: '#DB6B86'}} />
                            <stop offset="8.596202e-002" style={{stopColor: '#DC6D87'}} />
                            <stop offset={1} style={{stopColor: '#F7A6A5'}} />
                        </linearGradient>
                        <path id="XMLID_55_" fill="url(#XMLID_156_c)" d="M253.4,138.3l-3.5-1.1c0.2-1.5,0.4-3,0.4-4.5c0-1.6-0.2-3.2-0.4-4.7l3.5-1.1
              c0.4-0.1,0.7-0.6,0.5-1.1l-1.8-5.5c-0.1-0.4-0.6-0.7-1.1-0.5l-3.5,1.1c-1.4-2.8-3.2-5.3-5.4-7.4l2.2-3c0.3-0.4,0.2-0.9-0.2-1.2
              l-4.6-3.4c-0.4-0.3-0.9-0.2-1.2,0.2l-2.2,3c-2.7-1.4-5.6-2.4-8.7-2.8v-3.7c0-0.5-0.4-0.8-0.8-0.8h-5.7c-0.5,0-0.8,0.4-0.8,0.8v3.7
              c-3.1,0.5-6.1,1.4-8.7,2.9l-2.1-3c-0.3-0.4-0.8-0.5-1.2-0.2l-4.6,3.4c-0.4,0.3-0.5,0.8-0.2,1.2l2.2,3c-2.2,2.1-4,4.7-5.4,7.4
              l-3.5-1.1c-0.4-0.1-0.9,0.1-1.1,0.5l-1.8,5.5c-0.1,0.4,0.1,0.9,0.5,1.1l3.5,1.1c-0.2,1.5-0.4,3-0.4,4.5c0,1.6,0.2,3.2,0.4,4.7
              l-3.5,1.1c-0.4,0.1-0.7,0.6-0.5,1.1l1.8,5.5c0.1,0.4,0.6,0.7,1.1,0.5l3.5-1.1c1.4,2.8,3.2,5.3,5.4,7.4l-2.2,3
              c-0.3,0.4-0.2,0.9,0.2,1.2l4.6,3.4c0.4,0.3,0.9,0.2,1.2-0.2l2.2-3c2.7,1.4,5.6,2.4,8.7,2.8v3.7c0,0.5,0.4,0.8,0.8,0.8h5.7
              c0.5,0,0.8-0.4,0.8-0.8v-3.7c3.1-0.5,6.1-1.4,8.7-2.9l2.2,3c0.3,0.4,0.8,0.5,1.2,0.2l4.6-3.4c0.4-0.3,0.5-0.8,0.2-1.2l-2.2-3
              c2.2-2.1,4-4.7,5.4-7.4l3.5,1.1c0.4,0.1,0.9-0.1,1.1-0.5l1.8-5.5C254.1,138.9,253.9,138.4,253.4,138.3z" />
                        <circle id="XMLID_54_" fill="#FFFFFF" cx="223.7" cy="132.7" r="19.4" />
                        <path id="XMLID_53_" opacity="0.4" fill="#582768" d="M206.4,134c0-10.7,8.7-19.4,19.4-19.4c3.5,0,6.8,0.9,9.7,2.6c-3.3-2.5-7.3-4-11.8-4
              c-10.7,0-19.4,8.7-19.4,19.4c0,7.2,3.9,13.4,9.7,16.8C209.4,145.9,206.4,140.3,206.4,134z" />
                        <circle id="XMLID_52_" fill="#8C235D" cx="223.7" cy="132.7" r="3.5" />
                        <g id="XMLID_50_">
                            <path id="XMLID_51_" fill="#8C235D" d="M223.7,133.6c-0.5,0-0.9-0.4-0.9-0.9v-13.7c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9
                v13.7C224.7,133.2,224.2,133.6,223.7,133.6L223.7,133.6z" />
                        </g>
                        <g id="XMLID_22_">
                            <g id="XMLID_41_">
                                <g id="XMLID_48_">
                                    <path id="XMLID_49_" fill="#582768" d="M232,118.9c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.3-0.4-0.2-0.6l0.5-0.9c0.1-0.2,0.4-0.3,0.6-0.2
                    c0.2,0.1,0.3,0.4,0.2,0.6l-0.5,0.9C232.3,118.8,232.1,118.9,232,118.9L232,118.9z" />
                                </g>
                                <g id="XMLID_46_">
                                    <path id="XMLID_47_" fill="#582768" d="M215,148.3c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.3-0.4-0.2-0.6l0.5-0.9c0.1-0.2,0.4-0.3,0.6-0.2
                    c0.2,0.1,0.3,0.4,0.2,0.6l-0.5,0.9C215.3,148.2,215.1,148.3,215,148.3L215,148.3z" />
                                </g>
                                <g id="XMLID_44_">
                                    <path id="XMLID_45_" fill="#582768" d="M238.9,141.9c-0.1,0-0.2,0-0.2-0.1l-0.9-0.5c-0.2-0.1-0.3-0.4-0.2-0.6
                    c0.1-0.2,0.4-0.3,0.6-0.2l0.9,0.5c0.2,0.1,0.3,0.4,0.2,0.6C239.2,141.8,239.1,141.9,238.9,141.9L238.9,141.9z" />
                                </g>
                                <g id="XMLID_42_">
                                    <path id="XMLID_43_" fill="#582768" d="M209.5,124.9c-0.1,0-0.2,0-0.2-0.1l-0.9-0.5c-0.2-0.1-0.3-0.4-0.2-0.6
                    c0.1-0.2,0.4-0.3,0.6-0.2l0.9,0.5c0.2,0.1,0.3,0.4,0.2,0.6C209.8,124.8,209.6,124.9,209.5,124.9L209.5,124.9z" />
                                </g>
                            </g>
                            <g id="XMLID_32_">
                                <g id="XMLID_39_">
                                    <path id="XMLID_40_" fill="#582768" d="M215.5,118.9c-0.2,0-0.3-0.1-0.4-0.2l-0.5-0.9c-0.1-0.2-0.1-0.5,0.2-0.6
                    c0.2-0.1,0.5,0,0.6,0.2l0.5,0.9c0.1,0.2,0,0.5-0.2,0.6C215.6,118.8,215.6,118.9,215.5,118.9L215.5,118.9z" />
                                </g>
                                <g id="XMLID_37_">
                                    <path id="XMLID_38_" fill="#582768" d="M232.5,148.3c-0.2,0-0.3-0.1-0.4-0.2l-0.5-0.9c-0.1-0.2,0-0.5,0.2-0.6
                    c0.2-0.1,0.5-0.1,0.6,0.2l0.5,0.9c0.1,0.2,0,0.5-0.2,0.6C232.7,148.3,232.6,148.3,232.5,148.3L232.5,148.3z" />
                                </g>
                                <g id="XMLID_35_">
                                    <path id="XMLID_36_" fill="#582768" d="M208.5,141.9c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.1-0.5,0.2-0.6l0.9-0.5
                    c0.2-0.1,0.5-0.1,0.6,0.2c0.1,0.2,0.1,0.5-0.2,0.6l-0.9,0.5C208.7,141.9,208.6,141.9,208.5,141.9L208.5,141.9z" />
                                </g>
                                <g id="XMLID_33_">
                                    <path id="XMLID_34_" fill="#582768" d="M238,124.9c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.1-0.5,0.2-0.6l0.9-0.5
                    c0.2-0.1,0.5-0.1,0.6,0.2c0.1,0.2,0,0.5-0.2,0.6l-0.9,0.5C238.2,124.9,238.1,124.9,238,124.9L238,124.9z" />
                                </g>
                            </g>
                            <g id="XMLID_23_">
                                <g id="XMLID_30_">
                                    <path id="XMLID_31_" fill="#582768" d="M207.3,133.1L207.3,133.1h-1.1c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5l0,0l1.1,0
                    c0.3,0,0.5,0.2,0.5,0.5S207.5,133.1,207.3,133.1L207.3,133.1z" />
                                </g>
                                <g id="XMLID_28_">
                                    <path id="XMLID_29_" fill="#582768" d="M240.2,133.1c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5l1.1,0l0,0
                    c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5L240.2,133.1L240.2,133.1L240.2,133.1z" />
                                </g>
                                <g id="XMLID_26_">
                                    <path id="XMLID_27_" fill="#582768" d="M223.7,150.7C223.7,150.7,223.7,150.7,223.7,150.7c-0.3,0-0.5-0.2-0.5-0.5l0-1.1
                    c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0,0c0.3,0,0.5,0.2,0.5,0.5v1.1C224.2,150.4,224,150.7,223.7,150.7L223.7,150.7z" />
                                </g>
                                <g id="XMLID_24_">
                                    <path id="XMLID_25_" fill="#582768" d="M223.7,116.6c-0.3,0-0.5-0.2-0.5-0.5v-1.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v1.1
                    C224.2,116.4,224,116.6,223.7,116.6L223.7,116.6z" />
                                </g>
                            </g>
                        </g>
                        <g id="XMLID_17_">
                            <path id="XMLID_21_" fill="#8C235D" d="M217.7,139.6c-0.2,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-0.9,0-1.3l5.8-5.8c0.4-0.4,0.9-0.4,1.3,0
                c0.4,0.4,0.4,0.9,0,1.3l-5.8,5.8C218.2,139.5,218,139.6,217.7,139.6L217.7,139.6z" />
                        </g>
                        <circle id="XMLID_16_" fill="#FFFFFF" cx="223.7" cy="132.7" r="2.1" />
                        <path id="XMLID_15_" fill="#602666" d="M224.8,132.7c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
              C224.3,131.6,224.8,132.1,224.8,132.7z" />
                        <g id="XMLID_12_">
                            <path id="XMLID_13_" className={anim["clock"]} fill="#F1939B" d="M223.7,133.1c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.2,0.1-0.5,0.3-0.6l12-3.6
                c0.2-0.1,0.5,0.1,0.6,0.3c0.1,0.2-0.1,0.5-0.3,0.6l-12,3.6C223.8,133.1,223.8,133.1,223.7,133.1L223.7,133.1z" />
                        </g>
                        <g id="XMLID_6_">
                            <defs>
                                <rect id="XMLID_7_" x="62.2" y="165.5" transform="matrix(0.9653 -0.261 0.261 0.9653 -48.2874 26.8127)" width="29.2" height="59.4" />
                            </defs>
                            <clipPath id="XMLID_157_c">
                                <use xlinkHref="#XMLID_7_" style={{overflow: 'visible'}} />
                            </clipPath>
                            <g id="XMLID_8_" clipPath="url(#XMLID_157_c)">
                                <path id="XMLID_9_" fill="#7F245F" d="M72.2,192.8l-1.8,0.4c-0.4,0.1-0.9-0.1-1.2-0.5l-0.5-0.7c-0.3-0.3-0.3-0.9-0.1-1.3l1-1.6
                  c0.2-0.4,0.1-0.8-0.2-1.1l-2.4-1.5c-0.4-0.2-0.9-0.1-1.1,0.3l-1,1.6c-0.2,0.4-0.7,0.6-1.2,0.5l-0.9-0.1c-0.4,0-0.9-0.4-1-0.8
                  l-0.4-1.8c-0.1-0.4-0.5-0.7-0.9-0.6l-2.8,0.7c-0.4,0.1-0.7,0.5-0.6,0.9l0.4,1.8c0.1,0.4-0.1,0.9-0.5,1.2l-0.7,0.5
                  c-0.3,0.3-0.9,0.3-1.3,0.1l-1.6-1c-0.4-0.2-0.8-0.1-1.1,0.3l-1.5,2.4c-0.2,0.4-0.1,0.8,0.3,1.1l1.6,1c0.4,0.2,0.6,0.8,0.5,1.2
                  l-0.1,0.9c0,0.4-0.4,0.9-0.8,1l-1.8,0.4c-0.4,0.1-0.7,0.5-0.6,0.9l0.7,2.8c0.1,0.4,0.5,0.7,0.9,0.6l1.8-0.4
                  c0.4-0.1,0.9,0.1,1.2,0.5l0.5,0.7c0.3,0.3,0.3,0.9,0.1,1.3l-1,1.6c-0.2,0.4-0.1,0.8,0.3,1.1l2.4,1.5c0.4,0.2,0.8,0.1,1.1-0.3
                  l1-1.6c0.2-0.4,0.8-0.6,1.2-0.5l0.9,0.1c0.4,0,0.9,0.4,1,0.8l0.4,1.8c0.1,0.4,0.5,0.7,0.9,0.6l2.8-0.7c0.4-0.1,0.7-0.5,0.6-0.9
                  l-0.4-1.8c-0.1-0.4,0.1-0.9,0.5-1.2l0.7-0.5c0.3-0.3,0.9-0.3,1.3-0.1l1.6,1c0.4,0.2,0.8,0.1,1.1-0.3l1.5-2.4
                  c0.2-0.4,0.1-0.8-0.3-1.1l-1.6-1c-0.4-0.2-0.6-0.8-0.5-1.2l0.1-0.9c0-0.4,0.4-0.9,0.8-1l1.8-0.4c0.4-0.1,0.7-0.5,0.6-0.9
                  l-0.7-2.8C73,193,72.6,192.7,72.2,192.8z M62.7,201.2c-2,0.5-4.1-0.8-4.5-2.8c-0.5-2,0.8-4.1,2.8-4.5c2-0.5,4.1,0.8,4.5,2.8
                  C65.9,198.7,64.7,200.7,62.7,201.2z" />
                            </g>
                        </g>
                        <path id="XMLID_5_" className={anim["star"] + " " + anim["star-2"] + " " + "origin-center"} fill="#FFFFFF" d="M219.8,296.8v2.7h-2.7c-0.9,0-1.7,0.8-1.7,1.7v0.1c0,0.9,0.8,1.7,1.7,1.7h2.7v2.7
              c0,0.9,0.8,1.7,1.7,1.7h0.1c0.9,0,1.7-0.8,1.7-1.7v-2.7h2.7c0.9,0,1.7-0.8,1.7-1.7v-0.1c0-0.9-0.8-1.7-1.7-1.7h-2.7v-2.7
              c0-0.9-0.8-1.7-1.7-1.7h-0.1C220.6,295.1,219.8,295.9,219.8,296.8z" />
                        <path id="XMLID_4_" className={anim["star"] + " " + anim["star-1"] + " " + "origin-center"} fill="#FFFFFF" d="M160.2,32.1v3h-3c-1,0-1.8,0.8-1.8,1.8V37c0,1,0.8,1.8,1.8,1.8h3v3c0,1,0.8,1.8,1.8,1.8h0.1
              c1,0,1.8-0.8,1.8-1.8v-3h3c1,0,1.8-0.8,1.8-1.8v-0.1c0-1-0.8-1.8-1.8-1.8h-3v-3c0-1-0.8-1.8-1.8-1.8H162
              C161,30.2,160.2,31.1,160.2,32.1z" />
                        <circle id="XMLID_3_" fill="#FFFFFF" cx="39.7" cy="231.5" r="3.4" />
                    </g>
                </svg>
            </div>
        );
    }
}

export default injectIntl(compose(withStyles(anim))(WebSupport));