import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Slide.css";
import f from '../../../../../style/foundation.min.css';
import ButtonFill from '../../../../Button/Button-fill-a/Button';
import Slider from 'react-slick';
import { LgScreenHidden , LgScreen } from 'react-responsive-redux';


const messages = defineMessages({
    slide1title1: {
        id: 'slider.slide1title1',
        defaultMessage: 'Landing Page'
    },
    slide1title2: {
        id: 'slider.slide1title2',
        defaultMessage: 'Corporate website'
    },
    slide1text1: {
        id: 'slider.slide1text1',
        defaultMessage: 'Online shop'
    },
    slide1text2: {
        id: 'slider.slide1text2',
        defaultMessage: 'Individual project'
    },
    slide2title1: {
        id: 'slider.slide2title1',
        defaultMessage: 'Landing Page'
    },
    slide2title2: {
        id: 'slider.slide2title2',
        defaultMessage: 'Corporate website'
    },
    slide2text1: {
        id: 'slider.slide2text1',
        defaultMessage: 'Online shop'
    },
    slide2text2: {
        id: 'slider.slide2text2',
        defaultMessage: 'Individual project'
    },

    slide3title1: {
        id: 'slider.slide3title1',
        defaultMessage: 'Landing Page'
    },
    slide3title2: {
        id: 'slider.slide3title2',
        defaultMessage: 'Corporate website'
    },
    slide3text1: {
        id: 'slider.slide3text1',
        defaultMessage: 'Online shop'
    },
    slide3text2: {
        id: 'slider.slide3text2',
        defaultMessage: 'Individual project'
    },
    slide4title1: {
        id: 'slider.slide4title1',
        defaultMessage: 'Landing Page'
    },
    slide4title2: {
        id: 'slider.slide4title2',
        defaultMessage: 'Corporate website'
    },
    slide4text1: {
        id: 'slider.slide4text1',
        defaultMessage: 'Online shop'
    },
    slide4text2: {
        id: 'slider.slide4text2',
        defaultMessage: 'Individual project'
    },
    sliderButton: {
        id: 'slider.sliderButton',
        defaultMessage: 'Learn More'
    },
})


class SlideTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.DATASLIDER
        }
    }

    render () {
        var settings = {
            dots: true,
            infinite: true,
            speed: 400,
            swipe: false,
            fade: true,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 10000,
            arrows: true,
            pauseOnHover: true,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        let slide = this.state.data.map((item, index) => {
            return (
                <div key={index}>
                <figcaption>
                <div className={f.row + " " + s.rowSlide}>
                <div className={f['small-12'] + " " + f['large-6'] + " " + f['columns'] + " " + s.rowGrid + " " + s.leftCol}>
                <div className={s.rowContent}>
                <h2 className={s['animate'] + " " + s['slideInLeft']}><FormattedMessage {...messages[item.title1]} /><br/><FormattedMessage {...messages[item.title2]} /></h2>
            <p className={s['animate'] + " " + s['slideInRight']}><FormattedMessage {...messages[item.text1]} /></p>
            <p className={s['animate'] + " " + s['slideInRight']}><FormattedMessage {...messages[item.text2]} /></p>
            <ButtonFill
            buttonText={<FormattedMessage {...messages[item.sliderButton]} />}
            buttonClass={s.buttonVideo}
            href={"#services"}
            />
            </div>
            </div>
            <LgScreen className={f['small-6'] + " " + f['columns'] + " " + s.rowGrid + " " + s.rightCol + " " + s['animate'] + " " + s['slideInRight']}>
                <div className={s['item'] + " " + s['item--style-5'] + " " + ['item'] + " " + ['item--style-5']} data-animation-path-duration="3000" data-animation-path-easing="easeInOutQuint" data-path-rotate="90" data-morph-path="M 368.1,46.42 C 461,96.69 473.7,266.2 422.3,358.4 379.1,436 259.6,484.8 175,457.5 107.5,435.7 12.65,329.8 60.93,277.7 95.18,240.8 154,379.3 194.2,348.9 250.7,306 116,204.1 148.4,140.9 184.8,70.02 298,8.455 368.1,46.42 Z" data-animation-image-duration="3000" data-animation-image-easing="easeInOutQuint" data-image-rotate="-30"  data-animation-deco-duration="3000" data-animation-deco-easing="easeOutQuint">
                <svg className={s['item__svg'] + " " + ['content__img'] + " " + ['item__svg']} width="500px" height="500px" viewBox="0 0 500 500">
                <clipPath id={item.id}>
                <path className={s['item__clippath'] + " " + ['item__clippath']} d="M 451.5,185.8 C 441.5,266.2 339.6,305 272.3,350.2 207.7,393.6 226.7,444.7 182.6,447.9 132.8,451.4 83.97,399.9 66.37,353.1 34.6,268.4 41.16,141.8 112,85.44 186.1,26.33 313.8,54.1 396,101.4 425.2,118.2 455.6,152.4 451.5,185.8 Z" />
                </clipPath>
                <g clipPath={item.clipPath}>
                <image key={index} className={s['item__img'] + " " + ['item__img']} href={item.href} x="0" y="0" height="500px" width="500px" />
                </g>
                </svg>
                </div>
                <p className={s['content__link'] + " " + s['animate'] + " " + s['slideInRight']}>{item.name}</p>
                </LgScreen>
                </div>
                </figcaption>
                </div>
        )
        });
        return (
            <div className={s.container}>
            <Slider {...settings}>
        {slide}
    </Slider>
        <div className={s.gridSlick + " " + ['gridListTop']}>
            <div className={s['shape1'] + " " +  ['grid__item']}></div>
            <div className={s['shape2'] + " " +  ['grid__item']}></div>
            <div className={s['shape3'] + " " +  ['grid__item']}></div>
            <div className={s['shape4'] + " " +  ['grid__item']}></div>
            <div className={s['shape5'] + " " +  ['grid__item']}></div>
            <div className={s['shape6'] + " " +  ['grid__item']}></div>
            </div>
            </div>
    );
    }
}

export default injectIntl(
    compose(
        withStyles(s, f),
        connect(
            state => ({
                DATASLIDER: state.dataRu.SLIDER
            }),
            dispatch => ({})
        )
    )(SlideTop));
