import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { showModal, hideModal } from '../../../../actions/modal';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "../../../../style/page.css";
import services from "./../Services.css";
import f from '../../../../style/foundation.min.css';
import sv from '../../../../js/vendor/swiper.min.css';
import jfp from '../../../../js/vendor/jquery.fullpage.min.css';
import icon from '../../../../fonts/ionicons.min.css';
import Input from '../../../Form/Input/Input';
import WebsiteDevelopment from './../components/Website-development/Website-development';
import InternetMarketing from './../components/Internet-Marketing/Internet-Marketing';
import WebsiteSupport from './../components/Website-support/Website-support';
import DesignBranding from './../components/Design-Branding/Design-Branding';
import { LgScreenHidden , LgScreen } from 'react-responsive-redux';
import axios from 'axios';
import mobile from './Mobile.css';
import  Animation from './Animation';


const messages = defineMessages({
    title: {
        id: 'whatarewedoing.title',
        defaultMessage: 'Что мы',

    },
    titleBr: {
        id: 'whatarewedoing.titleBr',
        defaultMessage: 'делаем',
    },
    titleRight: {
        id: 'whatarewedoing.titleRight',
        defaultMessage: 'делаем',
    },
    text: {
        id: 'whatarewedoing.text',
        defaultMessage: 'делаем',
    },
    formText: {
        id: 'whatarewedoing.formText',
        defaultMessage: 'Fill out the form and get an audit of the site for free',

    },
    email: {
        id: 'whatarewedoing.email',
        defaultMessage: 'Email',
    },
    site: {
        id: 'whatarewedoing.site',
        defaultMessage: 'Website address',
    },
    button: {
        id: 'whatarewedoing.button',
        defaultMessage: 'Send',
    },
    developmentTabName: {
        id: 'dataservices.developmentTabName',
        defaultMessage: 'Website'
    },
    developmentTabNameBorder: {
        id: 'dataservices.developmentTabNameBorder',
        defaultMessage: 'development'
    },
    designTabName: {
        id: 'dataservices.designTabName',
        defaultMessage: 'Design and'
    },
    designTabNameBorder: {
        id: 'dataservices.designTabNameBorder',
        defaultMessage: 'Branding'
    },
    marcetingTabName: {
        id: 'dataservices.marcetingTabName',
        defaultMessage: 'Internet'
    },
    marcetingTabNameBorder: {
        id: 'dataservices.marcetingTabNameBorder',
        defaultMessage: 'marketing'
    },
    supportTabName: {
        id: 'dataservices.supportTabName',
        defaultMessage: 'Website'
    },
    supportTabNameBorder: {
        id: 'dataservices.supportTabNameBorder',
        defaultMessage: 'support'
    },
    headerSuccess1: {
        id: 'header.headerSuccess1',
        defaultMessage: 'Thank you',
    },
    headerSuccess2: {
        id: 'header.headerSuccess2',
        defaultMessage: 'Your message has been sent',
    },
    headerErr: {
        id: 'header.headerErr',
        defaultMessage: 'There was an error sending the message',
    }
});


class Services extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            activeTab: 0,
            data: this.props.data,
            email: '',
            site: ''
        }
        this.changeTabOnClick = this.changeTabOnClick.bind(this);
        this.handelEmailChang = this.handelEmailChang.bind(this);
        this.handelSiteChang = this.handelSiteChang.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    changeTabOnClick(index ) {
        this.setState({
            activeTab: index
        });
    }
    handelEmailChang(event) {
        this.setState({email: event.target.value})
    }
    handelSiteChang(event) {
        this.setState({site: event.target.value})
    }
    handleSubmit(e){
        e.preventDefault();
        this.setState({
            email: '',
            site: '',
            result: <FormattedMessage {...messages.headerSuccess1} />
        });
        axios({
            method: "POST",
            url:"/form-site",
            data: {
                email: this.state.email,
                site: this.state.site,
            }
        }).then((response)=>{
            if (response.data.msg === 'success'){
                this.setState({result: <FormattedMessage {...messages.headerSuccess2} />})
            }else if(response.data.msg === 'fail'){
                console.log("success");
                this.setState({result: <FormattedMessage {...messages.headerErr} />})
            }
        })
    }
    render() {
        return (
            <section className={['content'] + " " + ['clearfix'] + " " + s.content + " " + s.clearfix}>
            <LgScreenHidden>
                <div className={['edgtf-parallax-holder'] + " " + s.marginBlock} data-parallax="{&quot;y&quot;: -100, &quot;smoothness&quot;: 20}">
                    <div className="edgtf-elements-holder   edgtf-one-column  edgtf-responsive-mode-768 ">
                        <div className="edgtf-eh-item" data-item-class="edgtf-eh-custom-8567" data-1024-1280="60px 0 148px 0">
                            <div className="edgtf-eh-item-inner">
                                <div className="edgtf-eh-item-content edgtf-eh-custom-8567">
                                    <div className={f['row'] + " " + services['serviceOrderBlock'] + " " + services.row}>
                                        <div className={f['large-6'] + " " + f['small-12'] + " " + f['columns']}>
                                            <div className={mobile.titleBlockFlex}>
                                                <h2 className={s.floatLeftBorder}>
                                                    <span><FormattedMessage {...messages.title} /><br /></span>
                                                    <span><FormattedMessage {...messages.titleBr} /></span>
                                                </h2>
                                                <h2 className={mobile.titleBlock}>
                                                    <span><FormattedMessage {...messages.titleRight} /></span>
                                                </h2>
                                            </div>

                                            <p>
                                                <span><FormattedMessage {...messages.text} /></span>

                                            </p>
                                        </div>
                                        <div className={f['large-6'] + " " + f['small-12'] + " " + f['columns']}>

                                            <form onSubmit={this.handleSubmit} method="POST" className={['form'] + " " + ['message'] + " " + services.subscribeForm + " " + ['send_message_form']} method="post" id={['subscribeForm']}>
                                                <div className={f['medium-12'] + " " + f['large-6'] + " " + f['columns']}>
                                                    <Input
                                                        type={'text'}
                                                        inputText={<FormattedMessage {...messages.email} />}
                                                        inputClass={services.input}
                                                        value={this.state.email}
                                                        onChange={this.handelEmailChang}
                                                    />
                                                </div>
                                                <div className={f['medium-12'] + " " + f['large-6'] + " " + f['columns']}>
                                                    <Input
                                                        type={'text'}
                                                        inputText={<FormattedMessage {...messages.site} />}
                                                        inputClass={services.input}
                                                        value={this.state.site}
                                                        onChange={this.handelSiteChang}
                                                    />
                                                </div>
                                                <div className={f['large-6'] + " " + f['columns']}>
                                                    <p>{<FormattedMessage {...messages.formText} />}</p>
                                                </div>
                                                <div className={f['large-6'] + " " + f['columns']}>
                                                    <button className={services.buttonSubscribe} type="submit" value="Submit">
                                                        <span className={services.title}>{<FormattedMessage {...messages.button} />}</span>
                                                    </button>
                                                </div>
                                                <div id={services.loadBar}>{this.state.result}</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className={['content-centered'] + " " + ['c-columns'] + " " + s['content-centered'] + " " + s['c-columns']}>
                    <div className={['anim'] + " " + services['gridBlock']}>
                        <div className={services['gridServiceList']}>
                            <div className={f['row'] + " " + services.row + " " + services['gridService']}>
                                <TabHeader data={this.state.data}
                                           click={this.changeTabOnClick}
                                           activeId={this.state.activeTab}
                                           isOpen={this.state.isOpen}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </LgScreenHidden>
            </section>
        )
    }
}

class TabHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        }
    }

    doClick(index, event) {
        this.props.click(index);
    }

    render() {
        let activeClass = this.props.activeId;
        let dataAnimation = this.props;
        return (
            <div className={['edgtf-parallax-holder']} data-parallax="{&quot;y&quot;: -100, &quot;smoothness&quot;: 20}">
                <div className="edgtf-elements-holder   edgtf-one-column  edgtf-responsive-mode-768 ">
                    <div className="edgtf-eh-item" data-item-class="edgtf-eh-custom-8567" data-1024-1280="60px 0 148px 0">
                        <div className="edgtf-eh-item-inner">
                            <div className="edgtf-eh-item-content edgtf-eh-custom-8567">
                                <div className={f['medium-12'] + " " + f['columns']}>
                                    <div className={f['row'] + " " + s.mobile} >
                                        <Animation  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default injectIntl(
    compose(
        withStyles(s, mobile, services, sv, jfp, f, icon),
        connect(
            state => ({
            modalIsActive: state.modalReducer.modalIsActive,
            data: state.dataRu.DATASERVICES,
            modalIsActive: state.modalReducer.modalIsActive
        }),
        dispatch => ({
            openModal: () =>{
                return dispatch(showModal());
            },
            closeModal: () =>{
                return dispatch(hideModal());
            }
})
)
)(Services));
