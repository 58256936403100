exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2a549{display:inline-block;background:transparent;text-transform:uppercase;font-weight:500;font-style:normal;font-size:10px;font-size:.625rem;letter-spacing:.3em;color:#fff;border-radius:0;padding:18px 80px 20px;-webkit-transition:all .7s ease-out;-o-transition:all .7s ease-out;transition:all .7s ease-out;background:-webkit-gradient(linear,right top,left top,from(#ab2dec),color-stop(#e834ca),color-stop(hsla(0,0%,98%,0)),to(rgba(34,34,34,0)));background:-webkit-linear-gradient(right,#ab2dec,#e834ca,hsla(0,0%,98%,0),rgba(34,34,34,0));background:-o-linear-gradient(right,#ab2dec,#e834ca,hsla(0,0%,98%,0),rgba(34,34,34,0));background:linear-gradient(270deg,#ab2dec,#e834ca,hsla(0,0%,98%,0),rgba(34,34,34,0));background-position:1% 50%;background-size:300% 300%;text-decoration:none;margin:10px;margin:.625rem;border:1px solid #fff}@media (max-width:767px){._2a549{padding:18px 40px 20px}}._2a549:hover{color:#fff;cursor:pointer;border:1px solid hsla(43,65%,65%,0);background-position:99% 50%}", ""]);

// exports
exports.locals = {
	"button": "_2a549"
};