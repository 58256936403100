import React, { Component } from 'react';
import { connect } from 'react-redux';
import anim from './animate.css';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

class WebDesign extends React.Component {
    render() {
        return (
            <div className={anim["svg-width"]}>
                <svg className={anim["icon-design"]} viewBox="0 0 310.4 336">
                    <linearGradient id="XMLID_130_v" gradientUnits="userSpaceOnUse" x1="263.4456" y1="-35.5969" x2="114.7688" y2="254.0336" gradientTransform="matrix(1.0458 1.400000e-003 1.400000e-003 1.0425 -7.7523 -18.7189)">
                        <stop offset="0.1902" style={{stopColor: '#00BCB7'}} />
                        <stop offset={1} style={{stopColor: '#00BFEC'}} />
                    </linearGradient>
                    <path id="XMLID_94_" fill="url(#XMLID_130_v)" d="M0.9,119.5C-3.2,65.7,107.4-40.2,185.7,17.8c44.3,32.8,33.5,62.4,70.3,82.8
                    c31.3,17.4,55,57.5,51.5,111.9c-3.7,57.6-67.8,106.6-123.4,117.1C107.9,344,56.3,305.4,39.4,280.8c-22.8-33-7.3-51-10.5-81.6
                    C24.5,158.6,3.2,149.7,0.9,119.5z" />
                    <linearGradient id="XMLID_131_v" gradientUnits="userSpaceOnUse" x1="167.3098" y1="173.2032" x2="273.9668" y2="173.2032">
                        <stop offset="9.016400e-002" style={{stopColor: '#F38099'}} />
                        <stop offset={1} style={{stopColor: '#F7A6A5'}} />
                    </linearGradient>
                    <path id="XMLID_91_" className={anim["ruler-part"] + " " + anim["hover"]} fill="url(#XMLID_131_v)" d="M190.4,225l-21.6-21.6c-2-2-2-5.2,0-7.2l74.8-74.8c2-2,5.2-2,7.2,0l21.6,21.6c2,2,2,5.2,0,7.2
                    L197.7,225C195.7,227,192.4,227,190.4,225z M256.2,137.6c-1.7-1.7-4.6-1.7-6.3,0c-1.7,1.7-1.7,4.6,0,6.3c1.7,1.7,4.6,1.7,6.3,0
                    C258,142.2,258,139.3,256.2,137.6z" />
                    <path id="XMLID_90_" className={anim["ruler-part"] + " " + anim["hover"]} fill="#7f245f" d="M173.9,208.5l-5.1-5.1c-2-2-2-5.2,0-7.2l74.8-74.8c2-2,5.2-2,7.2,0l5.1,5.1L173.9,208.5z" />
                    <g id="XMLID_65_" className={anim["ruler-part"] + " " + anim["hover"]}>
                        <g id="XMLID_88_">
                            <polygon id="XMLID_89_" fill="#F6ECF4" points="246.5,130.5 240.5,124.5 241.4,123.6 247.4,129.6 246.5,130.5 			" />
                        </g>
                        <g id="XMLID_86_">
                            <polygon id="XMLID_87_" fill="#F6ECF4" points="240.3,136.7 234.3,130.7 235.2,129.8 241.2,135.8 240.3,136.7 			" />
                        </g>
                        <g id="XMLID_84_">
                            <polygon id="XMLID_85_" fill="#F6ECF4" points="234.1,142.9 228.1,136.9 229,136 235,142 234.1,142.9 			" />
                        </g>
                        <g id="XMLID_82_">
                            <polygon id="XMLID_83_" fill="#F6ECF4" points="227.9,149.1 221.9,143.1 222.8,142.2 228.8,148.2 227.9,149.1 			" />
                        </g>
                        <g id="XMLID_80_">
                            <polygon id="XMLID_81_" fill="#F6ECF4" points="221.7,155.3 215.7,149.3 216.6,148.4 222.6,154.4 221.7,155.3 			" />
                        </g>
                        <g id="XMLID_78_">
                            <polygon id="XMLID_79_" fill="#F6ECF4" points="215.5,161.5 209.5,155.5 210.4,154.6 216.4,160.6 215.5,161.5 			" />
                        </g>
                        <g id="XMLID_76_">
                            <polygon id="XMLID_77_" fill="#F6ECF4" points="209.3,167.7 203.3,161.7 204.2,160.8 210.2,166.8 209.3,167.7 			" />
                        </g>
                        <g id="XMLID_74_">
                            <polygon id="XMLID_75_" fill="#F6ECF4" points="203.1,173.9 197.1,167.9 198,167 204,173 203.1,173.9 			" />
                        </g>
                        <g id="XMLID_72_">
                            <polygon id="XMLID_73_" fill="#F6ECF4" points="196.9,180.1 190.9,174.1 191.8,173.2 197.8,179.2 196.9,180.1 			" />
                        </g>
                        <g id="XMLID_70_">
                            <polygon id="XMLID_71_" fill="#F6ECF4" points="190.7,186.3 184.7,180.3 185.6,179.4 191.6,185.4 190.7,186.3 			" />
                        </g>
                        <g id="XMLID_68_">
                            <polygon id="XMLID_69_" fill="#F6ECF4" points="184.5,192.5 178.5,186.5 179.4,185.6 185.4,191.6 184.5,192.5 			" />
                        </g>
                        <g id="XMLID_66_">
                            <polygon id="XMLID_67_" fill="#F6ECF4" points="178.3,198.7 172.3,192.7 173.2,191.8 179.2,197.8 178.3,198.7 			" />
                        </g>
                    </g>
                    <path id="XMLID_64_" className={anim["pen-part"] + " " + anim["hover"]} fill="#582768" d="M88.9,186.5l-69.1-69.1c4-3.1,9-3.7,12.2-1.3l63.6,63.6L88.9,186.5z" />
                    <path id="XMLID_63_" className={anim["pen-part"]  + " " + anim["hover"]} fill="#662666" d="M111.7,163.7l-16.1,16.1L32,116.1l0,0c0,0,0-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1
                    c-2.6-3.6-1.6-9.4,2.5-13.5c4.1-4.1,9.9-5.1,13.5-2.5c0,0,0-0.1-0.1-0.1c0,0,0.1,0,0.1,0.1l0,0L111.7,163.7z" />
                    <path id="XMLID_62_" className={anim["pen-part"] + " " + anim["hover"]} fill="#582768" d="M48.1,100c-2.3-3.2-1.8-8.2,1.3-12.2l69.1,69.1l-6.8,6.8L48.1,100z" />
                    <path id="XMLID_61_" className={anim["pen-part"] + " " + anim["hover"]} fill="#FFFFFF" d="M49.4,87.8c-3.1,4-3.7,9-1.3,12.2l0,0c0,0-0.1,0-0.1-0.1c0,0,0.1,0.1,0.1,0.1
                    c-3.6-2.6-9.4-1.6-13.5,2.5c-4.1,4.1-5.1,9.9-2.5,13.5c0,0-0.1,0-0.1-0.1c0,0,0,0.1,0.1,0.1l0,0c-3.2-2.3-8.2-1.8-12.2,1.3L20,88
                    L49.4,87.8z" />
                    <path id="XMLID_60_" className={anim["pen-part"] + " " + anim["hover"]} fill="#662666" d="M19.9,102.4L20,88l14.5-0.1c-1.9,2.8-4.1,5.4-6.6,7.9C25.3,98.4,22.7,100.5,19.9,102.4z" />
                    <g id="XMLID_56_">
                        <rect id="XMLID_59_" x="91.1" y="181.8" transform="matrix(-0.707 0.7072 -0.7072 -0.707 295.6422 250.8741)" fill="#617591" width="9.5" height="9.8" />
                        <polygon id="XMLID_58_" fill="#91A0BA" points="95.8,179.8 111.8,163.8 118.8,170.7 102.7,186.8 		" />
                        <rect id="XMLID_57_" x="113.9" y={159} transform="matrix(-0.7072 0.707 -0.707 -0.7072 318.4447 195.8433)" fill="#617591" width="9.5" height="9.8" />
                    </g>
                    <g id="XMLID_53_">
                        <linearGradient id="XMLID_132_v" gradientUnits="userSpaceOnUse" x1="60.2631" y1="75.0575" x2="151.9815" y2="188.016">
                            <stop offset="9.016400e-002" style={{stopColor: '#A62257'}} />
                            <stop offset="0.4541" style={{stopColor: '#86245E'}} />
                            <stop offset={1} style={{stopColor: '#582768'}} />
                        </linearGradient>
                        <polygon id="XMLID_10_" fill="url(#XMLID_132_v)" points="181.7,58.4 71.1,58.4 71.1,99.4 71.1,153.4 71.1,263.8 221.4,263.8 221.4,153.4
                    221.4,137.7 221.4,98 		" />
                    </g>
                    <g id="XMLID_50_">
                        <defs>
                            <polygon id="XMLID_51_" points="221.4,263.8 221.4,153.4 221.4,98 181.7,58.4 71.1,58.4 71.1,99.4 71.1,153.4 71.1,263.8 			" />
                        </defs>
                        <clipPath id="XMLID_133_">
                            <use xlinkHref="#XMLID_51_" style={{overflow: 'visible'}} />
                        </clipPath>
                        <path id="XMLID_52_" opacity="0.7" clipPath="url(#XMLID_133_)" fill="#5A2668" d="M50.6,242.3c38.6-40,16.5-19.3,53.6-57.9l-1.7-1.7l7.3-7.3l2,2c2.5-2.5,4.9-5.2,7.1-8.3
                    c5.2-7,9.1-14.3,11.6-21.2c7.2,4.4,16.7,5.9,25.1,3.4c3.7-1.1,6.8-3,9.2-5.2l4.2,4.2l-0.1,0.1l-24.1,24.1c-1.6-1-3.5-1.2-4.5-0.1
                    c-1.3,1.3-0.9,3.8,1,5.6c1.8,1.8,4.4,2.3,5.6,1c1-1,0.9-2.9-0.1-4.6l24.2-24.1l0.1-0.1l4.2,4.2c-2.3,2.4-4.1,5.4-5.2,9.2
                    c-2.5,8.4-1,17.9,3.4,25.1c-6.9,2.4-14.3,6.3-21.2,11.6c-3,2.3-5.8,4.7-8.3,7.2l2,2l-7.3,7.3l-1.7-1.7
                    c-38.6,37.2-17.9,15.1-57.9,53.7C55.4,294.4,27,265.9,50.6,242.3z" />
                    </g>
                    <g id="XMLID_46_">
                        <path id="XMLID_47_" className={anim["circle-part"] + " " + anim["hover"]} fill="#FFFFFF" d="M139.8,218L139.8,218c-13.6,0-26.4-5.3-36-14.9c-19.8-19.8-19.8-52.1,0-72
                    c9.6-9.6,22.4-14.9,36-14.9c13.6,0,26.4,5.3,36,14.9c19.8,19.8,19.8,52.1,0,72C166.1,212.7,153.4,218,139.8,218L139.8,218z
                    M139.8,118.7c-12.9,0-25.1,5-34.2,14.2c-18.8,18.8-18.8,49.5,0,68.4c9.1,9.1,21.3,14.2,34.2,14.2c12.9,0,25-5,34.2-14.2
                    c18.8-18.8,18.8-49.5,0-68.4C164.8,123.8,152.7,118.7,139.8,118.7L139.8,118.7z" />
                    </g>
                    <g id="XMLID_44_">
                        <polygon id="XMLID_45_" fill="#FFFFFF" points="206.1,165.5 141.3,100.8 143.1,98.9 207.9,163.7 206.1,165.5 		" />
                    </g>
                    <rect id="XMLID_43_" className={anim["circle-part"] + " " + anim["hover"]} x="100.3" y="127.6" fill="#9E2158" width="9.6" height="9.6" />
                    <path id="XMLID_40_" className={anim["circle-part"] + " " + anim["hover"]} fill="#FFFFFF" d="M105.1,141l-8.6-8.6l8.6-8.6l8.6,8.6L105.1,141L105.1,141z M100.2,132.4l5,5l5-5l-5-5
                    L100.2,132.4L100.2,132.4z" />
                    <polygon id="XMLID_39_" className={anim["circle-part"] + " " + anim["hover"]} fill="#6D2563" points="181.2,201.7 174.4,194.9 167.6,201.7 174.4,208.5 	" />
                    <path id="XMLID_36_" className={anim["circle-part"] + " " + anim["hover"]} fill="#FFFFFF" d="M174.4,210.3l-8.6-8.6l8.6-8.6l8.6,8.6L174.4,210.3L174.4,210.3z M169.4,201.7l5,5l5-5l-5-5
                    L169.4,201.7L169.4,201.7z" />
                    <polygon id="XMLID_35_" className={anim["circle-part"] + " " + anim["hover"]} fill="#6D2563" points="181.4,132.2 174.6,125.4 167.8,132.2 174.6,139 	" />
                    <path id="XMLID_32_" className={anim["circle-part"] + " " + anim["hover"]} fill="#FFFFFF" d="M174.6,140.8l-8.6-8.6l8.6-8.6l8.6,8.6L174.6,140.8L174.6,140.8z M169.6,132.2l5,5l5-5l-5-5
                    L169.6,132.2L169.6,132.2z" />
                    <g id="XMLID_27_">
                        <polygon id="XMLID_31_" fill="#FFFFFF" points="111.2,202.4 104.4,195.6 97.6,202.4 104.4,209.2 		" />
                        <path id="XMLID_28_" fill="#175079" d="M104.4,211l-8.6-8.6l8.6-8.6l8.6,8.6L104.4,211L104.4,211z M99.5,202.4l5,5l5-5l-5-5
                    L99.5,202.4L99.5,202.4z" />
                    </g>
                    <polygon id="XMLID_26_" fill="#802460" points="146.8,97.6 140,90.8 133.2,97.6 140,104.4 	" />
                    <path id="XMLID_23_" className={anim["anchor-part"] + " " + anim["anchor-part--1"]} fill="#FFFFFF" d="M140,106.2l-8.6-8.6L140,89l8.6,8.6L140,106.2L140,106.2z M135,97.6l5,5l5-5l-5-5L135,97.6
                    L135,97.6z" />
                    <rect id="XMLID_22_" x="204.4" y={162} transform="matrix(0.7069 0.7073 -0.7073 0.7069 179.3371 -99.087)" fill="#622666" width="9.6" height="9.6" />
                    <path id="XMLID_19_" className={anim["anchor-part"] + " " + anim["anchor-part--2"] } fill="#FFFFFF" d="M209.2,175.5l-8.6-8.6l8.6-8.6l8.6,8.6L209.2,175.5L209.2,175.5z M204.2,166.8l5,5l5-5l-5-5
                    L204.2,166.8L204.2,166.8z" />
                    <g id="XMLID_16_">
                        <linearGradient id="XMLID_134_v" gradientUnits="userSpaceOnUse" x1="34.4768" y1="224.0676" x2="131.021" y2="224.0676">
                            <stop offset="6.016400e-002" style={{stopColor: '#DB6B86'}} />
                            <stop offset="8.596202e-002" style={{stopColor: '#DC6D87'}} />
                            <stop offset={1} style={{stopColor: '#F7A6A5'}} />
                        </linearGradient>
                        <path id="XMLID_8_" fill="url(#XMLID_134_v)" d="M71.8,263.5c-23.6,23.6-52.1-4.8-28.4-28.5c39-40.4,15.9-18.8,54.9-59.2
                    c10.9,10.9,21.8,21.8,32.7,32.7C90.6,247.6,112.2,224.4,71.8,263.5z" />
                    </g>
                    <path id="XMLID_15_" fill="#FFFFFF" d="M80,216.7c-3.7,3.4-9.3,8.9-20.7,20.1c-5.1,5.1-9.7,8.1-13.5,9.4c1.4-3.8,4.4-8.5,9.4-13.5
                    c34.5-35.1,14.5-15.9,49-51c1.6,1.6,3.2,3.2,4.7,4.7C85.8,210.1,88.4,207.9,80,216.7z" />
                    <linearGradient id="XMLID_135_v" gradientUnits="userSpaceOnUse" x1="120.7522" y1="199.1821" x2="137.1653" y2="172.1487">
                        <stop offset="9.016400e-002" style={{stopColor: '#ECDBEC'}} />
                        <stop offset={1} style={{stopColor: '#FFFFFF'}} />
                    </linearGradient>
                    <path id="XMLID_14_" fill="url(#XMLID_135_v)" d="M148.5,144.1c3.7-1.1,6.8-3,9.2-5.2l4.2,4.2l-0.1,0.1l-24.1,24.1c-1.6-1.1-3.5-1.2-4.5-0.1
                    c-1.3,1.3-0.9,3.8,1,5.6c1.8,1.8,4.4,2.3,5.6,1c1-1,0.9-2.9-0.1-4.6l24.1-24.1l0.1-0.1l4.2,4.2c-2.3,2.4-4.1,5.4-5.2,9.2
                    c-2.5,8.4-1,17.9,3.4,25.1c-6.9,2.4-14.3,6.3-21.2,11.6c-5,3.8-9.4,7.9-12.9,12.1c-4.4-5.5-9.5-11.2-15.3-17
                    c-5.8-5.8-11.5-10.9-17-15.3c4.2-3.5,8.3-7.8,12.1-12.9c5.2-7,9.1-14.3,11.6-21.2C130.6,145.1,140.1,146.6,148.5,144.1z" />
                    <polygon id="XMLID_13_" fill="#CE5C59" points="131.4,211.5 95.3,175.5 102.6,168.1 138.6,204.2 	" />
                    <polygon id="XMLID_12_" className={anim["cursor-part"] + " " + anim["hover"] + " " + "origin-center" } fill="#7f245f" points="254.1,246.9 221.7,236.3 189.2,225.8 199.8,258.2 210.3,290.7 221.7,268.5 239,285.8
                    249.3,275.6 231.9,258.3 	" />
                    <polygon id="XMLID_11_" className={anim["cursor-part"] + " " + anim["hover"] + " " + "origin-center"} fill="#A52257" points="221.7,236.3 189.2,225.8 189.6,226.9 243.8,281.1 249.3,275.6 231.9,258.3
                    254.1,246.9 	" />
                    <linearGradient id="XMLID_136_v" gradientUnits="userSpaceOnUse" x1="181.7056" y1="78.2178" x2="221.3657" y2="78.2178">
                        <stop offset="9.016400e-002" style={{stopColor: '#A62257'}} />
                        <stop offset="0.4541" style={{stopColor: '#86245E'}} />
                        <stop offset={1} style={{stopColor: '#582768'}} />
                    </linearGradient>
                    <polygon id="XMLID_9_" fill="url(#XMLID_136_v)" points="181.7,98 221.4,98 181.7,58.4 	" />
                    <path className={anim["star"] + " " + anim["star-1"] + " " + "origin-center"} id="XMLID_5_" fill="#FFFFFF" d="M152.4,21.6v3h-3c-1,0-1.9,0.8-1.9,1.9v0.1c0,1,0.8,1.9,1.9,1.9h3v3c0,1,0.8,1.9,1.9,1.9h0.1
                    c1,0,1.9-0.8,1.9-1.9v-3h3c1,0,1.9-0.8,1.9-1.9v-0.1c0-1-0.8-1.9-1.9-1.9h-3v-3c0-1-0.8-1.9-1.9-1.9h-0.1
                    C153.2,19.7,152.4,20.6,152.4,21.6z" />
                    <path className={ anim["star"] + " " +  anim["star2"] + " " + "origin-center"} id="XMLID_4_" fill="#E5D0E6" d="M139.6,290.4v2.8h-2.8c-1,0-1.7,0.8-1.7,1.7v0.1c0,1,0.8,1.7,1.7,1.7h2.8v2.8
                    c0,1,0.8,1.7,1.7,1.7h0.1c1,0,1.7-0.8,1.7-1.7v-2.8h2.8c1,0,1.7-0.8,1.7-1.7v-0.1c0-1-0.8-1.7-1.7-1.7h-2.8v-2.8
                    c0-1-0.8-1.7-1.7-1.7h-0.1C140.3,288.7,139.6,289.5,139.6,290.4z" />
                    <circle id="XMLID_3_" fill="#FFFFFF" cx="63.1" cy="42.9" r="3.4" />
                </svg>
            </div>
        );
    }
}

export default injectIntl(
    compose(
        withStyles(anim),
        connect(
            state => ({
                modalIsActive: state.modalReducer.modalIsActive,
                data: state.dataRu.DATASERVICES,
                modalIsActive: state.modalReducer.modalIsActive
            }),
            dispatch => ({
                openModal: () =>{
                    return dispatch(showModal());
                },
                closeModal: () =>{
                    return dispatch(hideModal());
                }
            })
        )
    )(WebDesign));