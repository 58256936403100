export const modalPortfolio = () => {
    var vobuhoveModal = document.getElementById( 'vobuhoveModal' ),
        veronikaModal = document.getElementById( 'veronikaModal' ),
        bflModal = document.getElementById( 'bflModal' ),
        stickerModal = document.getElementById( 'stickerModal' ),
        ttsumyModal = document.getElementById( 'ttsumyModal' ),
        yourkonsModal = document.getElementById( 'yurKonsModal' ),
        mnogoshinModal = document.getElementById( 'mnogoshinModal' ),
        spectrModal = document.getElementById( 'spectrModal' ),
        salondecorModal = document.getElementById( 'salondecorModal' ),
        okClubModal = document.getElementById( 'okClubModal' ),
        myvoyagesModal = document.getElementById( 'myvoyagesModal' ),
        circusfestModal = document.getElementById( 'circusfestModal' ),
        academicstomModal = document.getElementById( 'academicstomModal' );


    // vobuhove
    var vobuhove = document.getElementById( 'vobuhove' ),
        modVobuhove = new DialogFx( vobuhove );
    vobuhoveModal.addEventListener( 'click', modVobuhove.toggle.bind(modVobuhove) );
    // veronika
    var veronikapolishchuk = document.getElementById( 'veronikapolishchuk' ),
        modVeronika = new DialogFx( veronikapolishchuk );
    veronikaModal.addEventListener( 'click', modVeronika.toggle.bind(modVeronika) );
    // BFL
    var bfl = document.getElementById( 'bfl' ),
        modBfl = new DialogFx( bfl );
    bflModal.addEventListener( 'click', modBfl.toggle.bind(modBfl) );
    // sticker
    var sticker = document.getElementById( 'sticker' ),
        modSticker = new DialogFx( sticker );
    stickerModal.addEventListener( 'click', modSticker.toggle.bind(modSticker) );
    // ttsumy
    var ttsumy = document.getElementById( 'ttsumy' ),
        modTtsumy = new DialogFx( ttsumy );
    ttsumyModal.addEventListener( 'click', modTtsumy.toggle.bind(modTtsumy) );
    // yur-kons
    var yourkons = document.getElementById( 'yourkons' ),
        modyourkons = new DialogFx( yourkons );
    yourkonsModal.addEventListener( 'click', modyourkons.toggle.bind(modyourkons) );
    // mnogoshin
    var mnogoshin = document.getElementById( 'mnogoshin' ),
        modMnogoshin = new DialogFx( mnogoshin );
    mnogoshinModal.addEventListener( 'click', modMnogoshin.toggle.bind(modMnogoshin) );
    // spectr prava
    var spectrprava = document.getElementById( 'spectrprava' ),
        modSpectr = new DialogFx( spectrprava );
    spectrModal.addEventListener( 'click', modSpectr.toggle.bind(modSpectr) );
    // salondecor
    var salondecor = document.getElementById( 'salondecor' ),
        modSalondecor = new DialogFx( salondecor );
    salondecorModal.addEventListener( 'click', modSalondecor.toggle.bind(modSalondecor) );
    // ok-club
    var okclub = document.getElementById( 'okclub' ),
        modokclub = new DialogFx( okclub );
    okClubModal.addEventListener( 'click', modokclub.toggle.bind(modokclub) );
    // myvoyages
    var myvoyages = document.getElementById( 'myvoyages' ),
        modMyvoyages = new DialogFx( myvoyages );
    myvoyagesModal.addEventListener( 'click', modMyvoyages.toggle.bind(modMyvoyages) );
    // Circusfest
    var circusfest = document.getElementById( 'circusfest' ),
        modCircusfest = new DialogFx( circusfest );
    circusfestModal.addEventListener( 'click', modCircusfest.toggle.bind(modCircusfest) );
    // academicstom
    var academicstom = document.getElementById( 'academicstom' ),
        modAcademicstom = new DialogFx( academicstom );
    academicstomModal.addEventListener( 'click', modAcademicstom.toggle.bind(modAcademicstom) );


};
// SLIDER MODAL
export const modalPortfolioSlide = () => {
    new SliderFx( document.getElementById('slideBfl'), {
        easing : 'cubic-bezier(.8,0,.2,1)',
        interval: 200,		// Interval
    } );
    new SliderFx( document.getElementById('slideMnogoshin'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
    new SliderFx( document.getElementById('slideCircusfest'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
    new SliderFx( document.getElementById('slideSalondecor'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
    new SliderFx( document.getElementById('slideVobuhove'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
    new SliderFx( document.getElementById('slideAcademicstom'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
    new SliderFx( document.getElementById('slideSpectrprava'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
    new SliderFx( document.getElementById('slideSticker'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
    new SliderFx( document.getElementById('slideMyvoyages'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
    new SliderFx( document.getElementById('slideVeronikapolishchuk'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
    new SliderFx( document.getElementById('slideYourkons'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
    new SliderFx( document.getElementById('slideTtsumy'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
    new SliderFx( document.getElementById('slideOkclub'), {
        easing : 'cubic-bezier(.8,0,.2,1)'
    } );
};
//MODAL Z-INDEX
export const modalZ = () => {
    $("#projects .grid__item, #projects .slick-slide").click(function () {
        $("#projects").css({zIndex: "999"});
        $(".homePage").css({transform: "none"});
    });
    $("button.action").click(function () {
        $("#projects").css({zIndex: "10"});
        $(".homePage").css({transform: "translateZ(0)"});
    });
    $(document).keydown(function(e) {
        if( e.keyCode === 27 ) {
            $("#projects").css({zIndex: "10"});
            $(".homePage").css({transform: "translateZ(0)"});
            return false;
        }
    });

};
//GRID ANIMATION
export const gridAnimation = () =>  {
    window.Revealer = class Revealer {
        constructor(el, options) {
            this.CONFIG = {
                hidden: false,
                color: '#fff'
            };
            Object.assign(this.CONFIG, options);

            this.DOM = {};
            this.DOM.item = el;
            this.layout();
        }
        layout() {
            this.allClasses = ['revealer--visible','revealer--right','revealer--left','revealer--top','revealer--bottom','revealer--showX','revealer--showY','revealer--hideX','revealer--hideY'];

            this.revealerEl = document.createElement('div');
            this.revealerEl.className = 'revealer';
            this.revealerEl.style.backgroundColor = this.CONFIG.color;
            this.DOM.item.appendChild(this.revealerEl);

            if ( this.CONFIG.hidden ) {
                this.revealerEl.classList.add('revealer--visible');
            }
        }
        show(animation) {
            return this.toggle(animation, 'show');
        }
        hide(animation) {
            return this.toggle(animation, 'hide');
        }
        toggle(animationOpts, action) {
            return new Promise((resolve, reject) => {
                if ( animationOpts ) {
                    this.animate(animationOpts, action);
                    this.revealerEl.addEventListener('animationend', resolve);
                }
                else {
                    this.revealerEl.classList.remove(...this.allClasses);
                    this.revealerEl.classList.add('revealer--visible');
                    resolve();
                }
            });
        }
        showFilled(animation) {
            return new Promise((resolve, reject) => {
                this.hide();
                animation.target = this.DOM.item;
                animation.target.style.visibility = 'hidden';
                this.animate(animation, 'hide');

                let completefn = () => {
                    animation.target.removeEventListener('animationend', completefn);
                    animation.target = this.revealerEl;
                    this.animate(animation, 'show');
                    animation.target.addEventListener('animationend', (ev) => {
                        if ( ev.target === animation.target ) {
                            resolve();
                        }
                    });
                };
                animation.target.addEventListener('animationend', completefn);
            });
        }
        hideFilled(animation) {
            return new Promise((resolve, reject) => {
                this.animate(animation, 'hide');

                let completefn = () => {
                    this.revealerEl.removeEventListener('animationend', completefn);
                    animation.target = this.DOM.item;
                    this.animate(animation, 'show');
                    animation.target.addEventListener('animationend', (ev) => {
                        if ( ev.target === animation.target ) {
                            resolve();
                        }
                    });
                };
                this.revealerEl.addEventListener('animationend', completefn);
            });
        }
        animate(animationOpts, action) {
            setTimeout(() => {
                const target = animationOpts.target || this.revealerEl;
                target.style.visibility = 'visible';
                target.classList.remove(...this.allClasses);

                let dirClass = 'revealer--right';
                let orientation = 'h';

                if ( animationOpts.direction === 'rtl' ) {
                    dirClass = action === 'hide' ? 'revealer--right' : 'revealer--left';
                    orientation = 'h';
                }
                else if ( animationOpts.direction === 'ltr' ) {
                    dirClass = action === 'hide' ? 'revealer--left' : 'revealer--right';
                    orientation = 'h';
                }
                else if ( animationOpts.direction === 'ttb' ) {
                    dirClass = action === 'hide' ? 'revealer--top' : 'revealer--bottom';
                    orientation = 'v';
                }
                else if ( animationOpts.direction === 'btt' ) {
                    dirClass = action === 'hide' ? 'revealer--bottom' : 'revealer--top';
                    orientation = 'v';
                }
                target.classList.add(dirClass, orientation === 'h' ? `revealer--${action}X` : `revealer--${action}Y`);
            }, animationOpts.delay || 0);
        }
    };
    const getMousePos = (e) => {
        let posx = 0;
        let posy = 0;
        if (!e) {let e = window.event};
        if (e.pageX || e.pageY) 	{
            posx = e.pageX;
            posy = e.pageY;
        }
        else if (e.clientX || e.clientY) 	{
            posx = e.clientX + document.body.scrollLeft
                + document.documentElement.scrollLeft;
            posy = e.clientY + document.body.scrollTop
                + document.documentElement.scrollTop;
        }
        return {
            x : posx,
            y : posy
        };
    }
    const debounce = (func, wait, immediate) => {
        let timeout;
        return () => {
            let context = this, args = arguments;
            let later = () => {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };
    const distance = (x1,x2,y1,y2) => {
        const a = x1 - x2;
        const b = y1 - y2;
        return Math.sqrt(a*a + b*b);
    };
    let portfolioHeight = document.querySelectorAll('.gridList');
    let win = {width: document.documentElement.clientWidth, height: document.documentElement.clientHeight};
    let center = {x: win.width/2, y: win.height/2};
    class GridItem {
        constructor(el, options) {
            this.CONFIG = {
                filledColor: '#fff'
            };
            Object.assign(this.CONFIG, options);
            this.DOM = {};
            this.DOM.el = el;
            const bcr = this.DOM.el.getBoundingClientRect();
            this.itemCenter = {
                x: bcr.left + bcr.width/2,
                y: bcr.top + bcr.height/2
            };
            this.revealer = new Revealer(this.DOM.el, {color: this.CONFIG.filledColor || window.getComputedStyle(document.body, null).backgroundColor});
            this.initEvents();
        }
        initEvents() {
            window.addEventListener('resize', (ev) => debounce(this.onresize()));
        }
        onresize(ev) {
            const bcr = this.DOM.el.getBoundingClientRect();
            this.itemCenter = {
                x: bcr.left + bcr.width/2,
                y: bcr.top + bcr.height/2
            };
        }
        show(animation = true) {
            return animation ? this.revealer.show({direction: this.DOM.el.dataset.direction || 'rtl', delay: this.DOM.el.dataset.delay || 0}) : this.revealer.show();
        }
        hide(animation = true) {
            return animation ? this.revealer.hide({direction: this.DOM.el.dataset.direction || 'rtl', delay: this.DOM.el.dataset.delay || 0}) : this.revealer.hide();
        }
        showFilled() {
            return this.revealer.showFilled({direction: this.DOM.el.dataset.direction || 'rtl', delay: this.DOM.el.dataset.delay || 0});
        }
        hideFilled() {
            return this.revealer.hideFilled({direction: this.DOM.el.dataset.direction || 'rtl', delay: this.DOM.el.dataset.delay || 0});
        }
        setTransform(transform) {
            const dist = distance(this.itemCenter.x, this.itemCenter.y, center.x, center.y);
            const tx = transform.translateX/win.width*dist || 0;
            const ty = transform.translateY/win.height*dist || 0;
            this.DOM.el.style.transform = `translate3d(${tx}px, ${ty}px, 0)`;
        }
        isNavCtrl() {
            return this.DOM.el.classList.contains('grid__item--nav');
        }
    };
    class Grid {
        constructor(el, options) {
            this.CONFIG = {
                filledColor: '#fff'
            };
            Object.assign(this.CONFIG, options);
            this.DOM = {};
            this.DOM.el = el;
            this.DOM.items = Array.from(this.DOM.el.querySelectorAll('div.grid__item'));
            this.DOM.name = this.DOM.el.querySelector('.grid__item--name');
            this.DOM.title = this.DOM.el.querySelector('.grid__item--title');
            this.DOM.text = this.DOM.el.querySelector('.grid__item--text');
            this.textElems = [this.DOM.name, this.DOM.title, this.DOM.text];
            this.layout();
        }
        layout() {
            this.itemsTotal = this.DOM.items.length;
            this.items = [];
            this.DOM.items.forEach((item) => this.items.push(new GridItem(item, {filledColor: this.CONFIG.filledColor})));
        }
        show(filled = false, animation = true) {
            return new Promise((resolve, reject) => {
                this.DOM.el.classList.add('grid--animating');
                this.hideItems();

                this.DOM.el.classList.add('grid--current');
                const promises = [];
                for (let i = 0; i < this.itemsTotal; i++) {
                    const promise = filled ? this.items[i].showFilled() : this.items[i].show(animation);
                    promises.push(promise);
                };
                for (let i = 0, len = this.textElems.length; i < len; i++) {
                    const promise = this.animateText(this.textElems[i], 'In');
                    promises.push(promise);
                };
                Promise.all(promises).then(() => {
                    this.resetTextClasses('In');
                    this.DOM.el.classList.remove('grid--animating');
                    resolve()
                });
            });
        }
        hide(filled = false, animation = true) {
            return new Promise((resolve, reject) => {
                this.DOM.el.classList.add('grid--animating');
                const promises = [];
                for (let i = 0; i < this.itemsTotal; i++) {
                    const promise = filled ? this.items[i].hideFilled() : this.items[i].hide(animation);
                    promises.push(promise);
                };
                for (let i = 0, len = this.textElems.length; i < len; i++) {
                    const promise = this.animateText(this.textElems[i], 'Out');
                    promises.push(promise);
                };
                Promise.all(promises).then(() => {
                    this.resetTextClasses('Out');
                    this.DOM.el.classList.remove('grid--animating');
                    this.DOM.el.classList.remove('grid--current');
                    resolve();
                });
            });
        }
        animateText(el, dir) {
            return new Promise((resolve, reject) => {
                el.classList.add(`grid__item--animate${dir}`);
                el.addEventListener('animationend', resolve);
            });
        }
        resetTextClasses(dir) {
            for (let i = 0, len = this.textElems.length; i < len; i++) {
                this.textElems[i].classList.remove(`grid__item--animate${dir}`);
            };
        }
        hideItems() {
            for (let i = 0; i < this.itemsTotal; i++) {
                this.items[i].hide(false);
            };
        }
        tilt(transform) {
            for (let i = 0; i < this.itemsTotal; i++) {
                const item = this.items[i];
                if ( !item.isNavCtrl() ) {
                    item.setTransform(transform);
                }
            };
        }
    };
    class Slideshow {
        constructor(grids, options) {
            this.CONFIG = {
                filledColor: false, // false || colorvalue (e.g. '#666')
                hasTilt: false,
                tilt: {maxTranslationX: 50, maxTranslationY: 50}
            };
            Object.assign(this.CONFIG, options);
            this.DOM = {};
            this.DOM.grids = grids;
            this.init();
        }
        init() {
            this.current = 0;
            this.gridsTotal = this.DOM.grids.length;
            this.grids = [];
            this.DOM.grids.forEach((grid) => this.grids.push(new Grid(grid, {
                filledColor: this.CONFIG.filledColor
            })));
            this.initEvents();
        }
        initEvents() {
            Array.from(document.querySelectorAll('.grid__item--nav-next')).forEach((ctrl) => ctrl.addEventListener('click', (ev) => this.navigate(ev, 'next')));
            Array.from(document.querySelectorAll('.grid__item--nav-prev')).forEach((ctrl) => ctrl.addEventListener('click', (ev) => this.navigate(ev, 'prev')))
            if ( this.CONFIG.hasTilt ) {
                document.addEventListener('mousemove', (ev) => this.onmousemove(ev));
                window.addEventListener('resize', (ev) => debounce(this.onresize()));
            }
        }
        onmousemove(ev) {
            requestAnimationFrame(() => {
                const mousepos = getMousePos(ev);
                const transX = 2*this.CONFIG.tilt.maxTranslationX/win.width*mousepos.x - this.CONFIG.tilt.maxTranslationX;
                const transY = 2*this.CONFIG.tilt.maxTranslationY/win.height*mousepos.y - this.CONFIG.tilt.maxTranslationY;
                // if ($(window).width() >= 1100) {
                //     this.grids[this.current].tilt({translateX: transX, translateY: transY});
                // }
            });
        }
        onresize(ev) {
            win = {width: window.innerWidth, height: window.innerHeight};
            center = {x: win.width/2, y: win.height/2};
        }
        navigate(ev, direction) {
            if ( this.isAnimating ) {
                return false;
            }
            this.isAnimating = true;
            const currentGrid = this.grids[this.current];
            this.current = direction === 'next' ? (this.current < this.gridsTotal-1 ? this.current+1 : 0) : (this.current > 0 ? this.current-1 : this.gridsTotal-1);
            const nextGrid = this.grids[this.current];
            const filled = this.CONFIG.filledColor;
            currentGrid.hide(!!filled).then(() => {
                nextGrid.show(!!filled).then(() => this.isAnimating = false);
                if ( this.CONFIG.hasTilt ) {
                    this.onmousemove(ev);
                }
            });
        }
    };
    window.Slideshow = Slideshow;
    const DOM = {};
    DOM.body = document.body;
    DOM.gridElems = Array.from(document.querySelectorAll('.gridList'));

    imagesLoaded(DOM.body, {background: true} , () => {
        DOM.body.classList.remove('loading');
        new Slideshow(DOM.gridElems, {
            hasTilt: true,
            tilt: {maxTranslationX: 10, maxTranslationY: 0}
        });
    });
};
