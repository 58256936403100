import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import { showModal, hideModal } from '../../../../actions/modal';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import t from "./Mobile.css";
import s from '../../../../style/page.css'
import Slider from 'react-slick';
import LazyLoad from 'react-lazy-load';
const IMG = [
    {
        "imgURL": "./img/image-company/vobuhove.png",
        "handleClick": "this.props.handleClickVobuhove",
        "id": "vobuhoveModal",
        "alt": "Vobuhove"
    },
    {
        "imgURL": "./img/image-company/veronika-photo.png",
        "handleClick": "this.props.handleClickVeronikapolishchuk",
        "id": "veronikaModal",
        "alt": "Veronika Polischuk"
    },
    {
        "imgURL": "./img/image-company/bfl.png",
        "handleClick": "this.props.handleClickBfl",
        "id": "bflModal",
        "alt": "BFL"
    },
    {
        "imgURL": "./img/image-company/stickerShop.png",
        "handleClick": "this.props.handleClickSticker",
        "id": "stickerModal",
        "alt": "Sticker"
    },
    {
        "imgURL": "./img/image-company/ttsumy.png",
        "handleClick": "this.props.handleClickTtsumy",
        "id": "ttsumyModal",
        "alt": "TT Sumy "
    },
    {
        "imgURL": "./img/image-company/yur-kons.png",
        "handleClick": "this.props.handleClickYurKons",
        "id": "yurKonsModal",
        "alt": "Yur Kons"
    },
    {
        "imgURL": "./img/image-company/mnogoshin.png",
        "handleClick": "this.props.handleClickMnogoshin",
        "id": "mnogoshinModal",
        "alt": "Mnogoshin"
    },
    {
        "imgURL": "./img/image-company/spectr.png",
        "handleClick": "this.props.handleClickSpectrprava",
        "id": "spectrModal",
        "alt": "Spectr Prava"
    },
    {
        "imgURL": "./img/image-company/salondecor.png",
        "handleClick": "this.props.handleClickSalondecor",
        "id": "salondecorModal",
        "alt": "Salon Decor"
    },
    {
        "imgURL": "./img/image-company/ok-club.png",
        "handleClick": "this.props.handleClickOkClub",
        "id": "okClubModal",
        "alt": "Ok Club"
    },
    {
        "imgURL": "./img/image-company/myvoyages.png",
        "handleClick": "this.props.handleClickMyvoyages",
        "id": "myvoyagesModal",
        "alt": "My Voyages"
    },
    {
        "imgURL": "./img/image-company/circusfest.jpg",
        "handleClick": "this.props.handleClickCircusfest",
        "id": "circusfestModal",
        "alt": "Circus fest"
    },
    {
        "imgURL": "./img/image-company/academicstom.png",
        "handleClick": "this.props.handleClickAcademicstom",
        "id": "academicstomModal",
        "alt": "Academicstom"
    }
]

class Mobile extends React.Component {
    render () {
        var settings = {
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            speed: 400,
            swipe: true,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 15000,
            arrows: false,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        dots: false
                    }
                }
            ]
        };
        return (
            <div id="slickCarouselPortfolio" className={t.container + " " + s.row}>
                <LazyLoad offsetVertical={0}>
                <Slider {...settings}>
                    <img src="/img/image-company/vobuhove.png" onClick={this.props.handleClickVobuhove} id="vobuhoveModal" className="vobuhoveModal" alt="Vobuhove"/>
                    <img src="/img/image-company/veronika-photo.png" onClick={this.props.handleClickVeronikapolishchuk} id="veronikaModal" className="veronikaModal" alt="Veronika Polischuk"/>
                    <img src="/img/image-company/bfl.png" onClick={this.props.handleClickBfl} id="bflModal" className="bflModal" alt="BFL"/>
                    <img src="/img/image-company/stickerShop.png" onClick={this.props.handleClickSticker} id="stickerModal" className="stickerModal" alt="Sticker"/>
                    <img src="/img/image-company/ttsumy.png" onClick={this.props.handleClickTtsumy} id="ttsumyModal" className="ttsumyModal" alt="TT Sumy"/>
                    <img src="/img/image-company/yur-kons.png" onClick={this.props.handleClickYurKons} id="yurKonsModal" className="yurKonsModal" alt="Yur Kons"/>
                    <img src="/img/image-company/mnogoshin.png" onClick={this.props.handleClickMnogoshin} id="mnogoshinModal" className="mnogoshinModal" alt="Mnogoshin"/>
                    <img src="/img/image-company/spectr.png" onClick={this.props.handleClickSpectrprava} id="spectrModal" className="spectrModal" alt="Spectr Prava"/>
                    <img src="/img/image-company/salondecor.png" onClick={this.props.handleClickSalondecor} id="salondecorModal" className="salondecorModal" alt="Salon Decor"/>
                    <img src="/img/image-company/ok-club.png" onClick={this.props.handleClickOkClub} id="okClubModal" className="okClubModal" alt="Ok Club"/>
                    <img src="/img/image-company/myvoyages.png" onClick={this.props.handleClickMyvoyages} id="myvoyagesModal" className="myvoyagesModal" alt="My Voyages"/>
                    <img src="/img/image-company/circusfest.jpg" onClick={this.props.handleClickCircusfest} id="circusfestModal" className="circusfestModal" alt="Circus fest"/>
                    <img src="/img/image-company/academicstom.png" onClick={this.props.handleClickAcademicstom} id="academicstomModal" className="academicstomModal" alt="Academicstom"/>
                </Slider>
                </LazyLoad>
            </div>
        );
    }
}

export default compose(
    withStyles(s, t),
    connect(
        state => ({
            modalIsActive: state.modalReducer.modalIsActive,
        }),
        dispatch => ({
            openModal: () =>{
                return dispatch(showModal());
            },
            closeModal: () =>{
                return dispatch(hideModal());
            }
        })
    )
)(Mobile);