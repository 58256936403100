import React, { Component } from 'react';
import { connect } from 'react-redux';
import Accordion from './Accordion';
import animationTab from  './styles.css';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import WebsiteDevelopment from "../../components/Website-development/Website-development.js";
import DesignBranding from "../../components/Design-Branding/Design-Branding";
import InternetMarketing from "../../components/Internet-Marketing/Internet-Marketing";
import WebsiteSupport from "../../components/Website-support/Website-support";


let websiteDevelopment = <WebsiteDevelopment />;
let designBranding = <DesignBranding />;
let internetMarketing = <InternetMarketing />;
let websiteSupport = <WebsiteSupport />;


const panels = [
    {
        content: websiteDevelopment,
        intelIdName: "developmentTitleAcardionDevelopment",
    },
    {
        content: designBranding,
        intelIdName: "developmentTitleAcardionDesing",
    },
    {
        content: internetMarketing,
        intelIdName: "developmentTitleAcardionMarketing",
    },
    {
        content: websiteSupport,
        intelIdName: "developmentTitleAcardionSupport",
    },
];

class Animation extends React.Component {
    render() {
        return (
            <div className={animationTab["wrapper"]}>
                <Accordion panels={ panels }/>
            </div>
        );
    }
}


export default injectIntl(
    compose(
        withStyles(animationTab),
        connect(
            state => ({
                modalIsActive: state.modalReducer.modalIsActive,
                data: state.dataRu.DATASERVICES,
                modalIsActive: state.modalReducer.modalIsActive
            }),
            dispatch => ({
                openModal: () =>{
                    return dispatch(showModal());
                },
                closeModal: () =>{
                    return dispatch(hideModal());
                }
            })
        )
    )(Animation));
