import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import t from "./Trust-us.css";
import s from '../../../style/page.css'
import Slider from 'react-slick';


const messages = defineMessages({
    trustUsTitle: {
        id: 'trustUs.trustUsTitle',
        defaultMessage: 'We are trusted'
    }
});


class Carousel extends React.Component {
    render () {
        var settings = {
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: true,
            speed: 400,
            swipe: false,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 10000,
            arrows: true,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 799,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false

                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: false,
                        autoplaySpeed: 2000

                    }
                }
            ]
        };
        let TRUST = this.props.TRUST;
        let SLIDERBLOCK = TRUST.IMG.map(function(el, index) {
            return (
                <a key={index} className={t.trust} rel="nofollow" target="_blank" href={el.link}>
                    <img className={el.class} key={el.alt} src={el.imgURL} alt={el.alt}/>
                </a>
            )
        })
        return (
            <div id="slick-carousel" className={t.container + " " + s.row}>
                <h3 className={t.titleCenter}><FormattedMessage {...messages[TRUST.TEXT.title]} />:</h3>
                <Slider {...settings}>
                    {TRUST.IMG.map(function(el, index) {
                        return (
                            <a key={index} className={t.trust} rel="nofollow" target="_blank" href={el.link}>
                                <img className={el.class} key={el.alt} src={el.imgURL} alt={el.alt}/>
                            </a>
                        )
                    })}
                </Slider>
            </div>
        );
    }
}

export default injectIntl(
    compose(
        withStyles(s, t),
        connect(
            state => ({
            TRUST: state.dataRu.TRUSTUS,
        }),
        dispatch => ({})
)
)(Carousel));