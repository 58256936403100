import { SHOW_MODAL, HIDE_MODAL } from '../constants';

export const showModal = () =>{
    if ($(window).width() <= 1100) {
        $(".homePage").css({transform: "none"});
        $("#services").css({zIndex: "999"});
        $(".menu--adsila h2").css({display: "none"});
    }
    return {
        type: SHOW_MODAL,
    };
};

export const hideModal = () =>{
    if ($(window).width() <= 1100) {
        $("#services, #projects").css({zIndex: "10"});
        $(".homePage").css({transform: "translateZ(0)"});
        $(".menu--adsila h2").css({display: "block"});
    }
    return {
        type: HIDE_MODAL,
    };
};

export const showModalPortfolio = () =>{
    if ($(window).width() <= 1100) {
        $(".homePage").css({transform: "none"});
        $("#projects").css({zIndex: "999"});
        $(".menu--adsila h2").css({display: "none"});
    }
    return {
        type: SHOW_MODAL,
    };
};

export const hideModalPortfolio = () =>{
    if ($(window).width() <= 1100) {
        $("#services, #projects").css({zIndex: "10"});
        $(".homePage").css({transform: "translateZ(0)"});
        $(".menu--adsila h2").css({display: "block"});
    }
    return {
        type: HIDE_MODAL,
    };
};