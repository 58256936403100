import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import Mobile from './Mobile/Mobile';
import Desktop from './Desktop/Desktop';
import { LgScreenHidden , LgScreen } from 'react-responsive-redux';

class Services extends React.Component {
    render() {
        return (
            <div>
                <Desktop />
                <div>
                    <LgScreenHidden>
                        <Mobile />
                    </LgScreenHidden>
                </div>
            </div>
        )
    }
}



export default injectIntl(
    compose(
        connect(
            state => ({}),
            dispatch => ({})
    )
)
(Services));
