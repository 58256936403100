import React, { Component } from 'react';
import Panel from './Panel';
import { connect } from 'react-redux';
import animationTab from  './styles.css';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import withStyles from "isomorphic-style-loader/lib/withStyles";


const messages = defineMessages({
    developmentTitleAcardionDevelopment: {
        id: 'services.developmentTitleAcardionDevelopment',
        defaultMessage: 'Website development',
    },
    developmentTitleAcardionDesing: {
        id: 'services.developmentTitleAcardionDesing',
        defaultMessage: 'Desing and Branding',
    },
    developmentTitleAcardionMarketing: {
        id: 'services.developmentTitleAcardionMarketing',
        defaultMessage: 'Internet marketing',
    },
    developmentTitleAcardionSupport: {
        id: 'services.developmentTitleAcardionSupport',
        defaultMessage: 'Website support',
    }
});


class Accordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0
        };

        this.activateTab = this.activateTab.bind(this);
    }

    activateTab(index) {
        this.setState(prev => ({
            activeTab: prev.activeTab === index ? -1 : index
        }));
    }

    render() {
        const { panels } = this.props;
        const { activeTab } = this.state;

        return (

                <div className={animationTab['accordion']} role='tablist'>
                    {panels.map((item, index) =>
                        <Panel
                            key={ index }
                            activeTab={ activeTab }
                            index={ index }
                            { ...item }
                            activateTab={ this.activateTab.bind(null, index) }

                            titleItem={<FormattedMessage {...messages[item.intelIdName]} />}

                        />
                    )}
                </div>
      
        );
    }
}

export default injectIntl(
    compose(
        withStyles(animationTab),
        connect(
            state => ({
                modalIsActive: state.modalReducer.modalIsActive,
                data: state.dataRu.DATASERVICESACARDION,
                modalIsActive: state.modalReducer.modalIsActive
            }),
            dispatch => ({
                openModal: () =>{
                    return dispatch(showModal());
                },
                closeModal: () =>{
                    return dispatch(hideModal());
                }
            })
        )
    )(Accordion));