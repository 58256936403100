exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._11Sok{padding:40px 0;width:100%}.yB2Lk{font-size:36px;text-align:center!important;color:#fff;padding-top:30px}._13_cc{-webkit-transform:scale(1);-moz-transform:scale(1)}._13_cc,._13_cc:hover{-webkit-transition-timing-function:ease-out;-webkit-transition-duration:.55s;-moz-transition-timing-function:ease-out;-moz-transition-duration:.55s}._13_cc:hover{-webkit-transform:scale(1.1);-moz-transform:scale(1.1)}", ""]);

// exports
exports.locals = {
	"container": "_11Sok",
	"titleCenter": "yB2Lk",
	"trust": "_13_cc"
};