exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2NN8J{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.cd_1_{font-size:125px;text-transform:uppercase;line-height:1;letter-spacing:-3px;margin-bottom:25px;opacity:.1;color:#4f4f6f;text-align:center!important}@media (max-width:1024px){.cd_1_{font-size:5vw}}@media (max-width:768px){.cd_1_{font-size:10vw}}", ""]);

// exports
exports.locals = {
	"titleBlockFlex": "_2NN8J",
	"titleBlock": "cd_1_"
};