import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "../../../style/page.css";
import f from '../../../style/foundation.min.css';
import sv from '../../../js/vendor/swiper.min.css';
import jfp from '../../../js/vendor/jquery.fullpage.min.css';
import icon from '../../../fonts/ionicons.min.css';
import about from './About-us.css';
import { Odometer } from './Components/Odometer';
import LazyLoad from 'react-lazy-load';


const messages = defineMessages({
    whoAreWeTitle: {
        id: 'whoAreWe.whoAreWeTitle',
        defaultMessage: 'Who are we'
    },
    whoAreWeTitleBr: {
        id: 'whoAreWe.whoAreWeTitleBr',
        defaultMessage: 'at all'
    },
    whoAreWeText: {
        id: 'whoAreWe.whoAreWeText',
        defaultMessage: 'Our studio provides services for the development of sites and all sorts of web-based systems and their further support. We also provide a full range of services for marketing planning and promotion of sites in the online segment.'
    },



    whoAreWeProgressTitle1: {
        id: 'whoAreWe.whoAreWeProgressTitle1',
        defaultMessage: 'Years on'
    },
    whoAreWeProgressTitleBr1: {
        id: 'whoAreWe.whoAreWeProgressTitleBr1',
        defaultMessage: 'the market'
    },
    whoAreWeProgressTitle2: {
        id: 'whoAreWe.whoAreWeProgressTitle2',
        defaultMessage: 'Satisfied'
    },
    whoAreWeProgressTitleBr2: {
        id: 'whoAreWe.whoAreWeProgressTitleBr2',
        defaultMessage: 'customers'
    },
    whoAreWeProgressTitle3: {
        id: 'whoAreWe.whoAreWeProgressTitle3',
        defaultMessage: 'Full-time'
    },
    whoAreWeProgressTitleBr3: {
        id: 'whoAreWe.whoAreWeProgressTitleBr3',
        defaultMessage: 'employees'
    },
    whoAreWeProgressTitle4: {
        id: 'whoAreWe.whoAreWeProgressTitle4',
        defaultMessage: 'Successful'
    },
    whoAreWeProgressTitleBr4: {
        id: 'whoAreWe.whoAreWeProgressTitleBr4',
        defaultMessage: 'projects'
    },



    whoAreWeTitleCenter: {
        id: 'whoAreWe.whoAreWeTitleCenter',
        defaultMessage: 'Do you have a project?'
    },
    whoAreWeTitleCenterH3: {
        id: 'whoAreWe.whoAreWeTitleCenterH3',
        defaultMessage: "Let's make it the best in your niche"
    }
});

class AboutUs extends Component {
    render() {
        let DATA = this.props.DATAWHOAREWE;
        let PROGRESS = this.props.PROGRESS;
        return (
                <section className={['content'] + " " + ['clearfix'] + " " + s.content + " " + s.clearfix}>
                    <LazyLoad
                        offsetVertical={0}
                        onContentVisible={() => {
                            Odometer();
                        }}
                    >
                        <span></span>
                    </LazyLoad>
                    {/*who we are*/}
                    <div className={about['aboutUsBlock'] + " " + ['aboutUsBlock']}>
                        <div className={f['large-4'] + " " + f['columns']}>
                            <div className={about.titleBlockFlex}>
                                <h2 className={s.floatLeftBorder}>
                                    <span><FormattedMessage {...messages[DATA.title]} /></span><br />
                                    <span><FormattedMessage {...messages[DATA.titleBr]} /></span>
                                </h2>
                                <h2 className={about.titleBlock2}>{DATA.titleRight}</h2>
                            </div>

                            <p><FormattedMessage {...messages[DATA.text]} /></p>
                        </div>
                        {/*who we are*/}
                        <div className={f['large-8'] + " " + f['columns']}>
                            <h2 className={about.titleBlock}>{DATA.titleRight}</h2>
                            <div className={['accomplishments'] + " " + about.flaxBlock}>
                                {PROGRESS.map((item, index) => {
                                    return(
                                        <div key={index} className={about.flaxList}>
                                            <img src={item.src} alt="about-icon"/>
                                            <span id={item.id} className={about.count + " " + ['odometer']}> </span>
                                            <p><FormattedMessage {...messages[item.title]} /><br/> <FormattedMessage {...messages[item.titleBr]} /></p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={about.infoBlock}>
                        <h2><FormattedMessage {...messages[DATA.titleCenter]} /></h2>
                        <h3><FormattedMessage {...messages[DATA.titleCenterH3]} />!</h3>
                        <h2><a href={DATA.phoneHref}> {DATA.phone} </ a></h2>
                        <h3>{DATA.email}</h3>
                    </div>
                </section>
        );
    }
}


export default injectIntl(
    compose(
        withStyles(s, about, sv, jfp, f, icon),
        connect(
            state => ({
                DATAWHOAREWE: state.dataRu.WHOAREWE,
                PROGRESS: state.dataRu.WHOAREWE.PROGRESS,
            }),
        dispatch => ({})
)
)(AboutUs));
